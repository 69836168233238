// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file voters_leaders_sentiments.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving voter leader sentiments
 *
 * @generated from enum nail2poll.VOTER_LEADER_SENTIMENT_SORT_KEY
 */
export enum VOTER_LEADER_SENTIMENT_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: VOTER_LEADER_SENTIMENT_SORT_KEY_CREATED_AT = 1;
   */
  VOTER_LEADER_SENTIMENT_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: VOTER_LEADER_SENTIMENT_SORT_KEY_MODIFIED_AT = 2;
   */
  VOTER_LEADER_SENTIMENT_SORT_KEY_MODIFIED_AT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(VOTER_LEADER_SENTIMENT_SORT_KEY)
proto3.util.setEnumType(VOTER_LEADER_SENTIMENT_SORT_KEY, "nail2poll.VOTER_LEADER_SENTIMENT_SORT_KEY", [
  { no: 0, name: "VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "VOTER_LEADER_SENTIMENT_SORT_KEY_CREATED_AT" },
  { no: 2, name: "VOTER_LEADER_SENTIMENT_SORT_KEY_MODIFIED_AT" },
]);

/**
 *
 * Describes the data structure of each voter leader sentiment on the platform
 *
 * @generated from message nail2poll.VoterLeaderSentiment
 */
export class VoterLeaderSentiment extends Message<VoterLeaderSentiment> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 5;
   */
  voterUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 6;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 7;
   */
  leaderUuid = "";

  /**
   * The description of the voter leader sentiment
   *
   * @generated from field: string description = 10;
   */
  description = "";

  constructor(data?: PartialMessage<VoterLeaderSentiment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentiment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentiment {
    return new VoterLeaderSentiment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentiment {
    return new VoterLeaderSentiment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentiment {
    return new VoterLeaderSentiment().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentiment | PlainMessage<VoterLeaderSentiment> | undefined, b: VoterLeaderSentiment | PlainMessage<VoterLeaderSentiment> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentiment, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of voter leader sentiments
 *
 * @generated from message nail2poll.VoterLeaderSentimentsList
 */
export class VoterLeaderSentimentsList extends Message<VoterLeaderSentimentsList> {
  /**
   * List of voter leader sentiments
   *
   * @generated from field: repeated nail2poll.VoterLeaderSentiment list = 1;
   */
  list: VoterLeaderSentiment[] = [];

  constructor(data?: PartialMessage<VoterLeaderSentimentsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: VoterLeaderSentiment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsList {
    return new VoterLeaderSentimentsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsList {
    return new VoterLeaderSentimentsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsList {
    return new VoterLeaderSentimentsList().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsList | PlainMessage<VoterLeaderSentimentsList> | undefined, b: VoterLeaderSentimentsList | PlainMessage<VoterLeaderSentimentsList> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.VoterLeaderSentimentPaginationResp
 */
export class VoterLeaderSentimentPaginationResp extends Message<VoterLeaderSentimentPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.VoterLeaderSentiment payload = 3;
   */
  payload: VoterLeaderSentiment[] = [];

  constructor(data?: PartialMessage<VoterLeaderSentimentPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: VoterLeaderSentiment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentPaginationResp {
    return new VoterLeaderSentimentPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentPaginationResp {
    return new VoterLeaderSentimentPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentPaginationResp {
    return new VoterLeaderSentimentPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentPaginationResp | PlainMessage<VoterLeaderSentimentPaginationResp> | undefined, b: VoterLeaderSentimentPaginationResp | PlainMessage<VoterLeaderSentimentPaginationResp> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a voter leader sentiment
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceCreateRequest
 */
export class VoterLeaderSentimentsServiceCreateRequest extends Message<VoterLeaderSentimentsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 5;
   */
  voterUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 6;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 7;
   */
  leaderUuid = "";

  /**
   * The description of the voter leader sentiment
   *
   * @generated from field: string description = 10;
   */
  description = "";

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceCreateRequest {
    return new VoterLeaderSentimentsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceCreateRequest {
    return new VoterLeaderSentimentsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceCreateRequest {
    return new VoterLeaderSentimentsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceCreateRequest | PlainMessage<VoterLeaderSentimentsServiceCreateRequest> | undefined, b: VoterLeaderSentimentsServiceCreateRequest | PlainMessage<VoterLeaderSentimentsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a voter leader sentiment
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceUpdateRequest
 */
export class VoterLeaderSentimentsServiceUpdateRequest extends Message<VoterLeaderSentimentsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 6;
   */
  sentimentUuid = "";

  /**
   * The description of the voter leader sentiment
   *
   * @generated from field: string description = 10;
   */
  description = "";

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceUpdateRequest {
    return new VoterLeaderSentimentsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceUpdateRequest {
    return new VoterLeaderSentimentsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceUpdateRequest {
    return new VoterLeaderSentimentsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceUpdateRequest | PlainMessage<VoterLeaderSentimentsServiceUpdateRequest> | undefined, b: VoterLeaderSentimentsServiceUpdateRequest | PlainMessage<VoterLeaderSentimentsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServicePaginationReq
 */
export class VoterLeaderSentimentsServicePaginationReq extends Message<VoterLeaderSentimentsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_LEADER_SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_LEADER_SENTIMENT_SORT_KEY.VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<VoterLeaderSentimentsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_LEADER_SENTIMENT_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServicePaginationReq {
    return new VoterLeaderSentimentsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServicePaginationReq {
    return new VoterLeaderSentimentsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServicePaginationReq {
    return new VoterLeaderSentimentsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServicePaginationReq | PlainMessage<VoterLeaderSentimentsServicePaginationReq> | undefined, b: VoterLeaderSentimentsServicePaginationReq | PlainMessage<VoterLeaderSentimentsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the request payload of a count filter
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceCountRequest
 */
export class VoterLeaderSentimentsServiceCountRequest extends Message<VoterLeaderSentimentsServiceCountRequest> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 11;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 12;
   */
  leaderUuid = "";

  /**
   * The UUID of the user that added this voter leader sentiment
   *
   * @generated from field: string added_by_user_uuid = 20;
   */
  addedByUserUuid = "";

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceCountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceCountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceCountRequest {
    return new VoterLeaderSentimentsServiceCountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceCountRequest {
    return new VoterLeaderSentimentsServiceCountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceCountRequest {
    return new VoterLeaderSentimentsServiceCountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceCountRequest | PlainMessage<VoterLeaderSentimentsServiceCountRequest> | undefined, b: VoterLeaderSentimentsServiceCountRequest | PlainMessage<VoterLeaderSentimentsServiceCountRequest> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceCountRequest, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceFilterReq
 */
export class VoterLeaderSentimentsServiceFilterReq extends Message<VoterLeaderSentimentsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_LEADER_SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_LEADER_SENTIMENT_SORT_KEY.VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 11;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 12;
   */
  leaderUuid = "";

  /**
   * The UUID of the user that added this voter leader sentiment
   *
   * @generated from field: string added_by_user_uuid = 20;
   */
  addedByUserUuid = "";

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_LEADER_SENTIMENT_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceFilterReq {
    return new VoterLeaderSentimentsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceFilterReq {
    return new VoterLeaderSentimentsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceFilterReq {
    return new VoterLeaderSentimentsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceFilterReq | PlainMessage<VoterLeaderSentimentsServiceFilterReq> | undefined, b: VoterLeaderSentimentsServiceFilterReq | PlainMessage<VoterLeaderSentimentsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceSearchAllReq
 */
export class VoterLeaderSentimentsServiceSearchAllReq extends Message<VoterLeaderSentimentsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_LEADER_SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_LEADER_SENTIMENT_SORT_KEY.VOTER_LEADER_SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 11;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 12;
   */
  leaderUuid = "";

  /**
   * The UUID of the user that added this voter leader sentiment
   *
   * @generated from field: string added_by_user_uuid = 20;
   */
  addedByUserUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 30;
   */
  searchKey = "";

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_LEADER_SENTIMENT_SORT_KEY) },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceSearchAllReq {
    return new VoterLeaderSentimentsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceSearchAllReq {
    return new VoterLeaderSentimentsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceSearchAllReq {
    return new VoterLeaderSentimentsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceSearchAllReq | PlainMessage<VoterLeaderSentimentsServiceSearchAllReq> | undefined, b: VoterLeaderSentimentsServiceSearchAllReq | PlainMessage<VoterLeaderSentimentsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceSearchAllReq, a, b);
  }
}

