// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file states.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving states
 *
 * @generated from enum nail2poll.STATE_SORT_KEY
 */
export enum STATE_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: STATE_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  STATE_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: STATE_SORT_KEY_CREATED_AT = 1;
   */
  STATE_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: STATE_SORT_KEY_MODIFIED_AT = 2;
   */
  STATE_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: STATE_SORT_KEY_NAME = 10;
   */
  STATE_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: STATE_SORT_KEY_CODE = 11;
   */
  STATE_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(STATE_SORT_KEY)
proto3.util.setEnumType(STATE_SORT_KEY, "nail2poll.STATE_SORT_KEY", [
  { no: 0, name: "STATE_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "STATE_SORT_KEY_CREATED_AT" },
  { no: 2, name: "STATE_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "STATE_SORT_KEY_NAME" },
  { no: 11, name: "STATE_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each state on the platform
 *
 * @generated from message nail2poll.State
 */
export class State extends Message<State> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the state
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the state
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<State>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.State";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): State {
    return new State().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): State {
    return new State().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): State {
    return new State().fromJsonString(jsonString, options);
  }

  static equals(a: State | PlainMessage<State> | undefined, b: State | PlainMessage<State> | undefined): boolean {
    return proto3.util.equals(State, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of states
 *
 * @generated from message nail2poll.StatesList
 */
export class StatesList extends Message<StatesList> {
  /**
   * List of states
   *
   * @generated from field: repeated nail2poll.State list = 1;
   */
  list: State[] = [];

  constructor(data?: PartialMessage<StatesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: State, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesList {
    return new StatesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesList {
    return new StatesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesList {
    return new StatesList().fromJsonString(jsonString, options);
  }

  static equals(a: StatesList | PlainMessage<StatesList> | undefined, b: StatesList | PlainMessage<StatesList> | undefined): boolean {
    return proto3.util.equals(StatesList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.StatePaginationResp
 */
export class StatePaginationResp extends Message<StatePaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.State payload = 3;
   */
  payload: State[] = [];

  constructor(data?: PartialMessage<StatePaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatePaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: State, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatePaginationResp {
    return new StatePaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatePaginationResp {
    return new StatePaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatePaginationResp {
    return new StatePaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: StatePaginationResp | PlainMessage<StatePaginationResp> | undefined, b: StatePaginationResp | PlainMessage<StatePaginationResp> | undefined): boolean {
    return proto3.util.equals(StatePaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a state
 *
 * @generated from message nail2poll.StatesServiceCreateRequest
 */
export class StatesServiceCreateRequest extends Message<StatesServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the state
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the state
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<StatesServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesServiceCreateRequest {
    return new StatesServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesServiceCreateRequest {
    return new StatesServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesServiceCreateRequest {
    return new StatesServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatesServiceCreateRequest | PlainMessage<StatesServiceCreateRequest> | undefined, b: StatesServiceCreateRequest | PlainMessage<StatesServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(StatesServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a state
 *
 * @generated from message nail2poll.StatesServiceUpdateRequest
 */
export class StatesServiceUpdateRequest extends Message<StatesServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the state
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the state
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<StatesServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesServiceUpdateRequest {
    return new StatesServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesServiceUpdateRequest {
    return new StatesServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesServiceUpdateRequest {
    return new StatesServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatesServiceUpdateRequest | PlainMessage<StatesServiceUpdateRequest> | undefined, b: StatesServiceUpdateRequest | PlainMessage<StatesServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(StatesServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.StatesServicePaginationReq
 */
export class StatesServicePaginationReq extends Message<StatesServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.STATE_SORT_KEY sort_key = 5;
   */
  sortKey = STATE_SORT_KEY.STATE_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<StatesServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(STATE_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesServicePaginationReq {
    return new StatesServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesServicePaginationReq {
    return new StatesServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesServicePaginationReq {
    return new StatesServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: StatesServicePaginationReq | PlainMessage<StatesServicePaginationReq> | undefined, b: StatesServicePaginationReq | PlainMessage<StatesServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(StatesServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.StatesServiceFilterReq
 */
export class StatesServiceFilterReq extends Message<StatesServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.STATE_SORT_KEY sort_key = 5;
   */
  sortKey = STATE_SORT_KEY.STATE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the state
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the state
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<StatesServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(STATE_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesServiceFilterReq {
    return new StatesServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesServiceFilterReq {
    return new StatesServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesServiceFilterReq {
    return new StatesServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: StatesServiceFilterReq | PlainMessage<StatesServiceFilterReq> | undefined, b: StatesServiceFilterReq | PlainMessage<StatesServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(StatesServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.StatesServiceSearchAllReq
 */
export class StatesServiceSearchAllReq extends Message<StatesServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.STATE_SORT_KEY sort_key = 5;
   */
  sortKey = STATE_SORT_KEY.STATE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<StatesServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StatesServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(STATE_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatesServiceSearchAllReq {
    return new StatesServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatesServiceSearchAllReq {
    return new StatesServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatesServiceSearchAllReq {
    return new StatesServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: StatesServiceSearchAllReq | PlainMessage<StatesServiceSearchAllReq> | undefined, b: StatesServiceSearchAllReq | PlainMessage<StatesServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(StatesServiceSearchAllReq, a, b);
  }
}

