// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// @generated by protoc-gen-es v1.6.0 with parameter "bootstrap_wkt=true,ts_nocheck=false,target=ts"
// @generated from file google/protobuf/type.proto (package google.protobuf, syntax proto3)
/* eslint-disable */
import { proto3 } from "../../proto3.js";
import { Message } from "../../message.js";
import { SourceContext } from "./source_context_pb.js";
import { Any } from "./any_pb.js";
/**
 * The syntax in which a protocol buffer element is defined.
 *
 * @generated from enum google.protobuf.Syntax
 */
export var Syntax;
(function (Syntax) {
    /**
     * Syntax `proto2`.
     *
     * @generated from enum value: SYNTAX_PROTO2 = 0;
     */
    Syntax[Syntax["PROTO2"] = 0] = "PROTO2";
    /**
     * Syntax `proto3`.
     *
     * @generated from enum value: SYNTAX_PROTO3 = 1;
     */
    Syntax[Syntax["PROTO3"] = 1] = "PROTO3";
    /**
     * Syntax `editions`.
     *
     * @generated from enum value: SYNTAX_EDITIONS = 2;
     */
    Syntax[Syntax["EDITIONS"] = 2] = "EDITIONS";
})(Syntax || (Syntax = {}));
// Retrieve enum metadata with: proto3.getEnumType(Syntax)
proto3.util.setEnumType(Syntax, "google.protobuf.Syntax", [
    { no: 0, name: "SYNTAX_PROTO2" },
    { no: 1, name: "SYNTAX_PROTO3" },
    { no: 2, name: "SYNTAX_EDITIONS" },
]);
/**
 * A protocol buffer message type.
 *
 * @generated from message google.protobuf.Type
 */
export class Type extends Message {
    constructor(data) {
        super();
        /**
         * The fully qualified message name.
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        /**
         * The list of fields.
         *
         * @generated from field: repeated google.protobuf.Field fields = 2;
         */
        this.fields = [];
        /**
         * The list of types appearing in `oneof` definitions in this type.
         *
         * @generated from field: repeated string oneofs = 3;
         */
        this.oneofs = [];
        /**
         * The protocol buffer options.
         *
         * @generated from field: repeated google.protobuf.Option options = 4;
         */
        this.options = [];
        /**
         * The source syntax.
         *
         * @generated from field: google.protobuf.Syntax syntax = 6;
         */
        this.syntax = Syntax.PROTO2;
        /**
         * The source edition string, only valid when syntax is SYNTAX_EDITIONS.
         *
         * @generated from field: string edition = 7;
         */
        this.edition = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Type().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Type().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Type().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Type, a, b);
    }
}
Type.runtime = proto3;
Type.typeName = "google.protobuf.Type";
Type.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fields", kind: "message", T: Field, repeated: true },
    { no: 3, name: "oneofs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "options", kind: "message", T: Option, repeated: true },
    { no: 5, name: "source_context", kind: "message", T: SourceContext },
    { no: 6, name: "syntax", kind: "enum", T: proto3.getEnumType(Syntax) },
    { no: 7, name: "edition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * A single field of a message type.
 *
 * @generated from message google.protobuf.Field
 */
export class Field extends Message {
    constructor(data) {
        super();
        /**
         * The field type.
         *
         * @generated from field: google.protobuf.Field.Kind kind = 1;
         */
        this.kind = Field_Kind.TYPE_UNKNOWN;
        /**
         * The field cardinality.
         *
         * @generated from field: google.protobuf.Field.Cardinality cardinality = 2;
         */
        this.cardinality = Field_Cardinality.UNKNOWN;
        /**
         * The field number.
         *
         * @generated from field: int32 number = 3;
         */
        this.number = 0;
        /**
         * The field name.
         *
         * @generated from field: string name = 4;
         */
        this.name = "";
        /**
         * The field type URL, without the scheme, for message or enumeration
         * types. Example: `"type.googleapis.com/google.protobuf.Timestamp"`.
         *
         * @generated from field: string type_url = 6;
         */
        this.typeUrl = "";
        /**
         * The index of the field type in `Type.oneofs`, for message or enumeration
         * types. The first type has index 1; zero means the type is not in the list.
         *
         * @generated from field: int32 oneof_index = 7;
         */
        this.oneofIndex = 0;
        /**
         * Whether to use alternative packed wire representation.
         *
         * @generated from field: bool packed = 8;
         */
        this.packed = false;
        /**
         * The protocol buffer options.
         *
         * @generated from field: repeated google.protobuf.Option options = 9;
         */
        this.options = [];
        /**
         * The field JSON name.
         *
         * @generated from field: string json_name = 10;
         */
        this.jsonName = "";
        /**
         * The string value of the default value of this field. Proto2 syntax only.
         *
         * @generated from field: string default_value = 11;
         */
        this.defaultValue = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Field().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Field().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Field().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Field, a, b);
    }
}
Field.runtime = proto3;
Field.typeName = "google.protobuf.Field";
Field.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "enum", T: proto3.getEnumType(Field_Kind) },
    { no: 2, name: "cardinality", kind: "enum", T: proto3.getEnumType(Field_Cardinality) },
    { no: 3, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "type_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "oneof_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "packed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "options", kind: "message", T: Option, repeated: true },
    { no: 10, name: "json_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * Basic field types.
 *
 * @generated from enum google.protobuf.Field.Kind
 */
export var Field_Kind;
(function (Field_Kind) {
    /**
     * Field type unknown.
     *
     * @generated from enum value: TYPE_UNKNOWN = 0;
     */
    Field_Kind[Field_Kind["TYPE_UNKNOWN"] = 0] = "TYPE_UNKNOWN";
    /**
     * Field type double.
     *
     * @generated from enum value: TYPE_DOUBLE = 1;
     */
    Field_Kind[Field_Kind["TYPE_DOUBLE"] = 1] = "TYPE_DOUBLE";
    /**
     * Field type float.
     *
     * @generated from enum value: TYPE_FLOAT = 2;
     */
    Field_Kind[Field_Kind["TYPE_FLOAT"] = 2] = "TYPE_FLOAT";
    /**
     * Field type int64.
     *
     * @generated from enum value: TYPE_INT64 = 3;
     */
    Field_Kind[Field_Kind["TYPE_INT64"] = 3] = "TYPE_INT64";
    /**
     * Field type uint64.
     *
     * @generated from enum value: TYPE_UINT64 = 4;
     */
    Field_Kind[Field_Kind["TYPE_UINT64"] = 4] = "TYPE_UINT64";
    /**
     * Field type int32.
     *
     * @generated from enum value: TYPE_INT32 = 5;
     */
    Field_Kind[Field_Kind["TYPE_INT32"] = 5] = "TYPE_INT32";
    /**
     * Field type fixed64.
     *
     * @generated from enum value: TYPE_FIXED64 = 6;
     */
    Field_Kind[Field_Kind["TYPE_FIXED64"] = 6] = "TYPE_FIXED64";
    /**
     * Field type fixed32.
     *
     * @generated from enum value: TYPE_FIXED32 = 7;
     */
    Field_Kind[Field_Kind["TYPE_FIXED32"] = 7] = "TYPE_FIXED32";
    /**
     * Field type bool.
     *
     * @generated from enum value: TYPE_BOOL = 8;
     */
    Field_Kind[Field_Kind["TYPE_BOOL"] = 8] = "TYPE_BOOL";
    /**
     * Field type string.
     *
     * @generated from enum value: TYPE_STRING = 9;
     */
    Field_Kind[Field_Kind["TYPE_STRING"] = 9] = "TYPE_STRING";
    /**
     * Field type group. Proto2 syntax only, and deprecated.
     *
     * @generated from enum value: TYPE_GROUP = 10;
     */
    Field_Kind[Field_Kind["TYPE_GROUP"] = 10] = "TYPE_GROUP";
    /**
     * Field type message.
     *
     * @generated from enum value: TYPE_MESSAGE = 11;
     */
    Field_Kind[Field_Kind["TYPE_MESSAGE"] = 11] = "TYPE_MESSAGE";
    /**
     * Field type bytes.
     *
     * @generated from enum value: TYPE_BYTES = 12;
     */
    Field_Kind[Field_Kind["TYPE_BYTES"] = 12] = "TYPE_BYTES";
    /**
     * Field type uint32.
     *
     * @generated from enum value: TYPE_UINT32 = 13;
     */
    Field_Kind[Field_Kind["TYPE_UINT32"] = 13] = "TYPE_UINT32";
    /**
     * Field type enum.
     *
     * @generated from enum value: TYPE_ENUM = 14;
     */
    Field_Kind[Field_Kind["TYPE_ENUM"] = 14] = "TYPE_ENUM";
    /**
     * Field type sfixed32.
     *
     * @generated from enum value: TYPE_SFIXED32 = 15;
     */
    Field_Kind[Field_Kind["TYPE_SFIXED32"] = 15] = "TYPE_SFIXED32";
    /**
     * Field type sfixed64.
     *
     * @generated from enum value: TYPE_SFIXED64 = 16;
     */
    Field_Kind[Field_Kind["TYPE_SFIXED64"] = 16] = "TYPE_SFIXED64";
    /**
     * Field type sint32.
     *
     * @generated from enum value: TYPE_SINT32 = 17;
     */
    Field_Kind[Field_Kind["TYPE_SINT32"] = 17] = "TYPE_SINT32";
    /**
     * Field type sint64.
     *
     * @generated from enum value: TYPE_SINT64 = 18;
     */
    Field_Kind[Field_Kind["TYPE_SINT64"] = 18] = "TYPE_SINT64";
})(Field_Kind || (Field_Kind = {}));
// Retrieve enum metadata with: proto3.getEnumType(Field_Kind)
proto3.util.setEnumType(Field_Kind, "google.protobuf.Field.Kind", [
    { no: 0, name: "TYPE_UNKNOWN" },
    { no: 1, name: "TYPE_DOUBLE" },
    { no: 2, name: "TYPE_FLOAT" },
    { no: 3, name: "TYPE_INT64" },
    { no: 4, name: "TYPE_UINT64" },
    { no: 5, name: "TYPE_INT32" },
    { no: 6, name: "TYPE_FIXED64" },
    { no: 7, name: "TYPE_FIXED32" },
    { no: 8, name: "TYPE_BOOL" },
    { no: 9, name: "TYPE_STRING" },
    { no: 10, name: "TYPE_GROUP" },
    { no: 11, name: "TYPE_MESSAGE" },
    { no: 12, name: "TYPE_BYTES" },
    { no: 13, name: "TYPE_UINT32" },
    { no: 14, name: "TYPE_ENUM" },
    { no: 15, name: "TYPE_SFIXED32" },
    { no: 16, name: "TYPE_SFIXED64" },
    { no: 17, name: "TYPE_SINT32" },
    { no: 18, name: "TYPE_SINT64" },
]);
/**
 * Whether a field is optional, required, or repeated.
 *
 * @generated from enum google.protobuf.Field.Cardinality
 */
export var Field_Cardinality;
(function (Field_Cardinality) {
    /**
     * For fields with unknown cardinality.
     *
     * @generated from enum value: CARDINALITY_UNKNOWN = 0;
     */
    Field_Cardinality[Field_Cardinality["UNKNOWN"] = 0] = "UNKNOWN";
    /**
     * For optional fields.
     *
     * @generated from enum value: CARDINALITY_OPTIONAL = 1;
     */
    Field_Cardinality[Field_Cardinality["OPTIONAL"] = 1] = "OPTIONAL";
    /**
     * For required fields. Proto2 syntax only.
     *
     * @generated from enum value: CARDINALITY_REQUIRED = 2;
     */
    Field_Cardinality[Field_Cardinality["REQUIRED"] = 2] = "REQUIRED";
    /**
     * For repeated fields.
     *
     * @generated from enum value: CARDINALITY_REPEATED = 3;
     */
    Field_Cardinality[Field_Cardinality["REPEATED"] = 3] = "REPEATED";
})(Field_Cardinality || (Field_Cardinality = {}));
// Retrieve enum metadata with: proto3.getEnumType(Field_Cardinality)
proto3.util.setEnumType(Field_Cardinality, "google.protobuf.Field.Cardinality", [
    { no: 0, name: "CARDINALITY_UNKNOWN" },
    { no: 1, name: "CARDINALITY_OPTIONAL" },
    { no: 2, name: "CARDINALITY_REQUIRED" },
    { no: 3, name: "CARDINALITY_REPEATED" },
]);
/**
 * Enum type definition.
 *
 * @generated from message google.protobuf.Enum
 */
export class Enum extends Message {
    constructor(data) {
        super();
        /**
         * Enum type name.
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        /**
         * Enum value definitions.
         *
         * @generated from field: repeated google.protobuf.EnumValue enumvalue = 2;
         */
        this.enumvalue = [];
        /**
         * Protocol buffer options.
         *
         * @generated from field: repeated google.protobuf.Option options = 3;
         */
        this.options = [];
        /**
         * The source syntax.
         *
         * @generated from field: google.protobuf.Syntax syntax = 5;
         */
        this.syntax = Syntax.PROTO2;
        /**
         * The source edition string, only valid when syntax is SYNTAX_EDITIONS.
         *
         * @generated from field: string edition = 6;
         */
        this.edition = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Enum().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Enum().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Enum().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Enum, a, b);
    }
}
Enum.runtime = proto3;
Enum.typeName = "google.protobuf.Enum";
Enum.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enumvalue", kind: "message", T: EnumValue, repeated: true },
    { no: 3, name: "options", kind: "message", T: Option, repeated: true },
    { no: 4, name: "source_context", kind: "message", T: SourceContext },
    { no: 5, name: "syntax", kind: "enum", T: proto3.getEnumType(Syntax) },
    { no: 6, name: "edition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * Enum value definition.
 *
 * @generated from message google.protobuf.EnumValue
 */
export class EnumValue extends Message {
    constructor(data) {
        super();
        /**
         * Enum value name.
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        /**
         * Enum value number.
         *
         * @generated from field: int32 number = 2;
         */
        this.number = 0;
        /**
         * Protocol buffer options.
         *
         * @generated from field: repeated google.protobuf.Option options = 3;
         */
        this.options = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumValue().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumValue().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumValue().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(EnumValue, a, b);
    }
}
EnumValue.runtime = proto3;
EnumValue.typeName = "google.protobuf.EnumValue";
EnumValue.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "options", kind: "message", T: Option, repeated: true },
]);
/**
 * A protocol buffer option, which can be attached to a message, field,
 * enumeration, etc.
 *
 * @generated from message google.protobuf.Option
 */
export class Option extends Message {
    constructor(data) {
        super();
        /**
         * The option's name. For protobuf built-in options (options defined in
         * descriptor.proto), this is the short name. For example, `"map_entry"`.
         * For custom options, it should be the fully-qualified name. For example,
         * `"google.api.http"`.
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Option().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Option().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Option().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Option, a, b);
    }
}
Option.runtime = proto3;
Option.typeName = "google.protobuf.Option";
Option.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "message", T: Any },
]);
