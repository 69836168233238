// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file users.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { User, UserPaginationResp, UsersList, UsersServiceCreateRequest, UsersServiceFilterReq, UsersServicePaginationReq, UsersServiceRegisterUserDeviceRequest, UsersServiceResetPasswordRequest, UsersServiceSearchAllReq, UsersServiceUpdatePasswordRequest, UsersServiceUpdateRequest } from "./users_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierUsername, IdentifierWithUserComment, ImageAssignRequest, ImageResponse, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each user
 *
 * @generated from service nail2poll.UsersService
 */
export const UsersService = {
  typeName: "nail2poll.UsersService",
  methods: {
    /**
     * Create a user
     *
     * @generated from rpc nail2poll.UsersService.Create
     */
    create: {
      name: "Create",
      I: UsersServiceCreateRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * Update a user
     *
     * @generated from rpc nail2poll.UsersService.Update
     */
    update: {
      name: "Update",
      I: UsersServiceUpdateRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * Update a user's password
     *
     * @generated from rpc nail2poll.UsersService.UpdatePassword
     */
    updatePassword: {
      name: "UpdatePassword",
      I: UsersServiceUpdatePasswordRequest,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Reset a user's password. This operation sets a random password and sends the same to the user's email address
     *
     * @generated from rpc nail2poll.UsersService.ResetPassword
     */
    resetPassword: {
      name: "ResetPassword",
      I: UsersServiceResetPasswordRequest,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * All Identifier operations are listed below
     * Archive the user
     *
     * @generated from rpc nail2poll.UsersService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the user
     *
     * @generated from rpc nail2poll.UsersService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Register user's device for push notifications
     *
     * @generated from rpc nail2poll.UsersService.RegisterUserDevice
     */
    registerUserDevice: {
      name: "RegisterUserDevice",
      I: UsersServiceRegisterUserDeviceRequest,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Assign image
     *
     * @generated from rpc nail2poll.UsersService.AssignImage
     */
    assignImage: {
      name: "AssignImage",
      I: ImageAssignRequest,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View assigned image
     *
     * @generated from rpc nail2poll.UsersService.ViewImage
     */
    viewImage: {
      name: "ViewImage",
      I: Identifier,
      O: ImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.UsersService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * View by Username
     *
     * @generated from rpc nail2poll.UsersService.ViewByUsername
     */
    viewByUsername: {
      name: "ViewByUsername",
      I: IdentifierUsername,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * View all users
     *
     * @generated from rpc nail2poll.UsersService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: UsersList,
      kind: MethodKind.Unary,
    },
    /**
     * View users with pagination
     *
     * @generated from rpc nail2poll.UsersService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: UsersServicePaginationReq,
      O: UserPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.UsersService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: UsersServiceSearchAllReq,
      O: UsersList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.UsersService.Filter
     */
    filter: {
      name: "Filter",
      I: UsersServiceFilterReq,
      O: UsersList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.UsersService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.UsersService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.UsersService.Export
     */
    export: {
      name: "Export",
      I: UsersServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

