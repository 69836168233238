import { Router, context } from "./router";
import utilities = require("./utilities");

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/ui/dash", (ctx) => {
        if (utilities.getAuth() == "") {
            return utilities.logout();    
        }
        Dash(ctx);
    });
}

function Dash(ctx: context) {
    const primaryBody = utilities.getPrimaryBodySection();

    primaryBody.innerHTML = `
        <section class="section">
            <h1 class="title" style="text-align: center;">Select the relevant data</h1>
        </section>
        <section class="columns is-vcentered">
            <div class="column">
                <a href="/ui/households" class="button is-large is-fullwidth is-responsive is-link">Households</a>
            </div>
        </section>
        <section class="columns is-vcentered">
            <div class="column">
                <a href="/ui/voters" class="button is-large is-fullwidth is-responsive is-info">Voters</a>
            </div>
        </section>
        <section class="columns is-vcentered">
            <div class="column">
                <a href="/ui/users" class="button is-large is-fullwidth is-responsive is-primary">Users</a>
            </div>
        </section>
    `;
    document.body.innerHTML = `
    ${utilities.returnHeaderWithLogoutSection()}
    
    ${primaryBody.outerHTML}
    `;

    window.scrollTo(0, 0);

    utilities.configureLogoutButton();
}