// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file voters.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";
import { Household } from "./households_pb.js";
import { PollingStation } from "./polling_stations_pb.js";
import { Sentiment } from "./sentiments_pb.js";

/**
 *
 * Describes the available genders
 *
 * @generated from enum nail2poll.VOTER_GENDER
 */
export enum VOTER_GENDER {
  /**
   * Only used for searches
   *
   * @generated from enum value: VOTER_GENDER_ANY_UNSPECIFIED = 0;
   */
  VOTER_GENDER_ANY_UNSPECIFIED = 0,

  /**
   * Male
   *
   * @generated from enum value: VOTER_GENDER_MALE = 1;
   */
  VOTER_GENDER_MALE = 1,

  /**
   * Female
   *
   * @generated from enum value: VOTER_GENDER_FEMALE = 2;
   */
  VOTER_GENDER_FEMALE = 2,

  /**
   * Transgender
   *
   * @generated from enum value: VOTER_GENDER_TRANSGENDER = 3;
   */
  VOTER_GENDER_TRANSGENDER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(VOTER_GENDER)
proto3.util.setEnumType(VOTER_GENDER, "nail2poll.VOTER_GENDER", [
  { no: 0, name: "VOTER_GENDER_ANY_UNSPECIFIED" },
  { no: 1, name: "VOTER_GENDER_MALE" },
  { no: 2, name: "VOTER_GENDER_FEMALE" },
  { no: 3, name: "VOTER_GENDER_TRANSGENDER" },
]);

/**
 *
 * Describes the available relation types
 *
 * @generated from enum nail2poll.VOTER_RELATION_TYPE
 */
export enum VOTER_RELATION_TYPE {
  /**
   * Only used for searches
   *
   * @generated from enum value: VOTER_RELATION_TYPE_ANY_UNSPECIFIED = 0;
   */
  VOTER_RELATION_TYPE_ANY_UNSPECIFIED = 0,

  /**
   * Father
   *
   * @generated from enum value: VOTER_RELATION_TYPE_FATHER = 1;
   */
  VOTER_RELATION_TYPE_FATHER = 1,

  /**
   * Mother
   *
   * @generated from enum value: VOTER_RELATION_TYPE_MOTHER = 2;
   */
  VOTER_RELATION_TYPE_MOTHER = 2,

  /**
   * Husband
   *
   * @generated from enum value: VOTER_RELATION_TYPE_HUSBAND = 3;
   */
  VOTER_RELATION_TYPE_HUSBAND = 3,

  /**
   * Wife
   *
   * @generated from enum value: VOTER_RELATION_TYPE_WIFE = 4;
   */
  VOTER_RELATION_TYPE_WIFE = 4,

  /**
   * Other
   *
   * @generated from enum value: VOTER_RELATION_TYPE_OTHER = 5;
   */
  VOTER_RELATION_TYPE_OTHER = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(VOTER_RELATION_TYPE)
proto3.util.setEnumType(VOTER_RELATION_TYPE, "nail2poll.VOTER_RELATION_TYPE", [
  { no: 0, name: "VOTER_RELATION_TYPE_ANY_UNSPECIFIED" },
  { no: 1, name: "VOTER_RELATION_TYPE_FATHER" },
  { no: 2, name: "VOTER_RELATION_TYPE_MOTHER" },
  { no: 3, name: "VOTER_RELATION_TYPE_HUSBAND" },
  { no: 4, name: "VOTER_RELATION_TYPE_WIFE" },
  { no: 5, name: "VOTER_RELATION_TYPE_OTHER" },
]);

/**
 *
 * Describes the available sort keys for retrieving voters
 *
 * @generated from enum nail2poll.VOTER_SORT_KEY
 */
export enum VOTER_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: VOTER_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  VOTER_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: VOTER_SORT_KEY_CREATED_AT = 1;
   */
  VOTER_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: VOTER_SORT_KEY_MODIFIED_AT = 2;
   */
  VOTER_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: VOTER_SORT_KEY_NAME = 10;
   */
  VOTER_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by voter ID
   *
   * @generated from enum value: VOTER_SORT_KEY_VOTER_ID = 11;
   */
  VOTER_SORT_KEY_VOTER_ID = 11,

  /**
   * Fetch ordered results by serial number
   *
   * @generated from enum value: VOTER_SORT_KEY_SERIAL_NO = 12;
   */
  VOTER_SORT_KEY_SERIAL_NO = 12,

  /**
   * Fetch ordered results by age
   *
   * @generated from enum value: VOTER_SORT_KEY_AGE = 13;
   */
  VOTER_SORT_KEY_AGE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(VOTER_SORT_KEY)
proto3.util.setEnumType(VOTER_SORT_KEY, "nail2poll.VOTER_SORT_KEY", [
  { no: 0, name: "VOTER_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "VOTER_SORT_KEY_CREATED_AT" },
  { no: 2, name: "VOTER_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "VOTER_SORT_KEY_NAME" },
  { no: 11, name: "VOTER_SORT_KEY_VOTER_ID" },
  { no: 12, name: "VOTER_SORT_KEY_SERIAL_NO" },
  { no: 13, name: "VOTER_SORT_KEY_AGE" },
]);

/**
 *
 * Describes the basic data structure of each voter
 *
 * @generated from message nail2poll.VoterBasic
 */
export class VoterBasic extends Message<VoterBasic> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the voter
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 21;
   */
  voterId = "";

  /**
   * The serial number
   *
   * @generated from field: uint64 serial_no = 22;
   */
  serialNo = protoInt64.zero;

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 23;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 24;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 25;
   */
  houseNumber = "";

  /**
   * The age of the voter
   *
   * @generated from field: int64 age = 26;
   */
  age = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 27;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 30;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 31;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 40;
   */
  dateOfBirth = "";

  /**
   * The info of the associated household
   *
   * @generated from field: nail2poll.Household household = 60;
   */
  household?: Household;

  /**
   * The info of the asociated polling station
   *
   * @generated from field: nail2poll.PollingStation polling_station = 61;
   */
  pollingStation?: PollingStation;

  /**
   * The info of the asociated sentiment
   *
   * @generated from field: nail2poll.Sentiment sentiment = 62;
   */
  sentiment?: Sentiment;

  constructor(data?: PartialMessage<VoterBasic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterBasic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 23, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 24, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "age", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 30, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "household", kind: "message", T: Household },
    { no: 61, name: "polling_station", kind: "message", T: PollingStation },
    { no: 62, name: "sentiment", kind: "message", T: Sentiment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterBasic {
    return new VoterBasic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterBasic {
    return new VoterBasic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterBasic {
    return new VoterBasic().fromJsonString(jsonString, options);
  }

  static equals(a: VoterBasic | PlainMessage<VoterBasic> | undefined, b: VoterBasic | PlainMessage<VoterBasic> | undefined): boolean {
    return proto3.util.equals(VoterBasic, a, b);
  }
}

/**
 *
 * Describes the data structure of each voter on the platform
 *
 * @generated from message nail2poll.Voter
 */
export class Voter extends Message<Voter> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 4;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 5;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 6;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 7;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 8;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 9;
   */
  householdUuid = "";

  /**
   * The name of the voter
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 21;
   */
  voterId = "";

  /**
   * The serial number
   *
   * @generated from field: uint64 serial_no = 22;
   */
  serialNo = protoInt64.zero;

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 23;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 24;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 25;
   */
  houseNumber = "";

  /**
   * The age of the voter
   *
   * @generated from field: int64 age = 26;
   */
  age = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 27;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 30;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 31;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 40;
   */
  dateOfBirth = "";

  /**
   * The info of the associated household
   *
   * @generated from field: nail2poll.Household household = 60;
   */
  household?: Household;

  /**
   * The info of the asociated polling station
   *
   * @generated from field: nail2poll.PollingStation polling_station = 61;
   */
  pollingStation?: PollingStation;

  /**
   * The info of the asociated sentiment
   *
   * @generated from field: nail2poll.Sentiment sentiment = 62;
   */
  sentiment?: Sentiment;

  constructor(data?: PartialMessage<Voter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Voter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 4, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 23, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 24, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "age", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 30, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "household", kind: "message", T: Household },
    { no: 61, name: "polling_station", kind: "message", T: PollingStation },
    { no: 62, name: "sentiment", kind: "message", T: Sentiment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Voter {
    return new Voter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Voter {
    return new Voter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Voter {
    return new Voter().fromJsonString(jsonString, options);
  }

  static equals(a: Voter | PlainMessage<Voter> | undefined, b: Voter | PlainMessage<Voter> | undefined): boolean {
    return proto3.util.equals(Voter, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of voters
 *
 * @generated from message nail2poll.VotersList
 */
export class VotersList extends Message<VotersList> {
  /**
   * List of voters
   *
   * @generated from field: repeated nail2poll.Voter list = 1;
   */
  list: Voter[] = [];

  constructor(data?: PartialMessage<VotersList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Voter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersList {
    return new VotersList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersList {
    return new VotersList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersList {
    return new VotersList().fromJsonString(jsonString, options);
  }

  static equals(a: VotersList | PlainMessage<VotersList> | undefined, b: VotersList | PlainMessage<VotersList> | undefined): boolean {
    return proto3.util.equals(VotersList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.VoterPaginationResp
 */
export class VoterPaginationResp extends Message<VoterPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Voter payload = 3;
   */
  payload: Voter[] = [];

  constructor(data?: PartialMessage<VoterPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Voter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterPaginationResp {
    return new VoterPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterPaginationResp {
    return new VoterPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterPaginationResp {
    return new VoterPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: VoterPaginationResp | PlainMessage<VoterPaginationResp> | undefined, b: VoterPaginationResp | PlainMessage<VoterPaginationResp> | undefined): boolean {
    return proto3.util.equals(VoterPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a voter
 *
 * @generated from message nail2poll.VotersServiceCreateRequest
 */
export class VotersServiceCreateRequest extends Message<VotersServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 4;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 5;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 6;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 7;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 8;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 9;
   */
  householdUuid = "";

  /**
   * The name of the voter
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 21;
   */
  voterId = "";

  /**
   * The serial number
   *
   * @generated from field: uint64 serial_no = 22;
   */
  serialNo = protoInt64.zero;

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 23;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 24;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 25;
   */
  houseNumber = "";

  /**
   * The age of the voter
   *
   * @generated from field: int64 age = 26;
   */
  age = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 27;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 30;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 31;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 40;
   */
  dateOfBirth = "";

  constructor(data?: PartialMessage<VotersServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 23, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 24, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "age", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 30, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceCreateRequest {
    return new VotersServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceCreateRequest {
    return new VotersServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceCreateRequest {
    return new VotersServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceCreateRequest | PlainMessage<VotersServiceCreateRequest> | undefined, b: VotersServiceCreateRequest | PlainMessage<VotersServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(VotersServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a voter
 *
 * @generated from message nail2poll.VotersServiceUpdateRequest
 */
export class VotersServiceUpdateRequest extends Message<VotersServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 4;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 5;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 6;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 7;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 8;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 9;
   */
  householdUuid = "";

  /**
   * The name of the voter
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 21;
   */
  voterId = "";

  /**
   * The serial number
   *
   * @generated from field: uint64 serial_no = 22;
   */
  serialNo = protoInt64.zero;

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 23;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 24;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 25;
   */
  houseNumber = "";

  /**
   * The age of the voter
   *
   * @generated from field: int64 age = 26;
   */
  age = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 27;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 30;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 31;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 40;
   */
  dateOfBirth = "";

  /**
   * The optional UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 100;
   */
  sentimentUuid = "";

  constructor(data?: PartialMessage<VotersServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 23, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 24, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "age", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 27, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 30, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceUpdateRequest {
    return new VotersServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceUpdateRequest {
    return new VotersServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceUpdateRequest {
    return new VotersServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceUpdateRequest | PlainMessage<VotersServiceUpdateRequest> | undefined, b: VotersServiceUpdateRequest | PlainMessage<VotersServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(VotersServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.VotersServicePaginationReq
 */
export class VotersServicePaginationReq extends Message<VotersServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_SORT_KEY.VOTER_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<VotersServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServicePaginationReq {
    return new VotersServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServicePaginationReq {
    return new VotersServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServicePaginationReq {
    return new VotersServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServicePaginationReq | PlainMessage<VotersServicePaginationReq> | undefined, b: VotersServicePaginationReq | PlainMessage<VotersServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(VotersServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the request payload of a count filter
 *
 * @generated from message nail2poll.VotersServiceCountRequest
 */
export class VotersServiceCountRequest extends Message<VotersServiceCountRequest> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 10;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 11;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 12;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 13;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 14;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 15;
   */
  householdUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 16;
   */
  sentimentUuid = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 20;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 21;
   */
  districtUuid = "";

  /**
   * The UUID of the state
   *
   * --------------------------------------------------------
   *
   * @generated from field: string state_uuid = 22;
   */
  stateUuid = "";

  /**
   * The serial number (0, if needs to be ignored)
   *
   * @generated from field: uint64 serial_no = 25;
   */
  serialNo = protoInt64.zero;

  /**
   * The name of the voter
   *
   * @generated from field: string name = 30;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 31;
   */
  voterId = "";

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 32;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 33;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 34;
   */
  houseNumber = "";

  /**
   * The minimum age of the voter
   *
   * @generated from field: int64 age_min = 35;
   */
  ageMin = protoInt64.zero;

  /**
   * The maximum age of the voter
   *
   * @generated from field: int64 age_max = 36;
   */
  ageMax = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 37;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 40;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 41;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 50;
   */
  dateOfBirth = "";

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 60;
   */
  addedByUserUuid = "";

  constructor(data?: PartialMessage<VotersServiceCountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceCountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 33, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "age_min", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "age_max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 40, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceCountRequest {
    return new VotersServiceCountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceCountRequest {
    return new VotersServiceCountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceCountRequest {
    return new VotersServiceCountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceCountRequest | PlainMessage<VotersServiceCountRequest> | undefined, b: VotersServiceCountRequest | PlainMessage<VotersServiceCountRequest> | undefined): boolean {
    return proto3.util.equals(VotersServiceCountRequest, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.VotersServiceFilterReq
 */
export class VotersServiceFilterReq extends Message<VotersServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_SORT_KEY.VOTER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 10;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 11;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 12;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 13;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 14;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 15;
   */
  householdUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 16;
   */
  sentimentUuid = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 20;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 21;
   */
  districtUuid = "";

  /**
   * The UUID of the state
   *
   * --------------------------------------------------------
   *
   * @generated from field: string state_uuid = 22;
   */
  stateUuid = "";

  /**
   * The serial number (0, if needs to be ignored)
   *
   * @generated from field: uint64 serial_no = 25;
   */
  serialNo = protoInt64.zero;

  /**
   * The name of the voter
   *
   * @generated from field: string name = 30;
   */
  name = "";

  /**
   * The voter ID
   *
   * @generated from field: string voter_id = 31;
   */
  voterId = "";

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 32;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 33;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 34;
   */
  houseNumber = "";

  /**
   * The minimum age of the voter
   *
   * @generated from field: int64 age_min = 35;
   */
  ageMin = protoInt64.zero;

  /**
   * The maximum age of the voter
   *
   * @generated from field: int64 age_max = 36;
   */
  ageMax = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 37;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The mobile number of the voter
   *
   * @generated from field: string mobile_number = 40;
   */
  mobileNumber = "";

  /**
   * The email address of the voter
   *
   * @generated from field: string email = 41;
   */
  email = "";

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 50;
   */
  dateOfBirth = "";

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 60;
   */
  addedByUserUuid = "";

  constructor(data?: PartialMessage<VotersServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 30, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "voter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 33, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "age_min", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "age_max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 40, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceFilterReq {
    return new VotersServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceFilterReq {
    return new VotersServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceFilterReq {
    return new VotersServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceFilterReq | PlainMessage<VotersServiceFilterReq> | undefined, b: VotersServiceFilterReq | PlainMessage<VotersServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(VotersServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.VotersServiceSearchAllReq
 */
export class VotersServiceSearchAllReq extends Message<VotersServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_SORT_KEY.VOTER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 10;
   */
  pollingStationUuid = "";

  /**
   * The UUID of the religion
   *
   * @generated from field: string religion_uuid = 11;
   */
  religionUuid = "";

  /**
   * The UUID of the profession
   *
   * @generated from field: string profession_uuid = 12;
   */
  professionUuid = "";

  /**
   * The UUID of the party affiliation
   *
   * @generated from field: string party_affiliation_uuid = 13;
   */
  partyAffiliationUuid = "";

  /**
   * The UUID of the caste
   *
   * @generated from field: string caste_uuid = 14;
   */
  casteUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 15;
   */
  householdUuid = "";

  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 16;
   */
  sentimentUuid = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 20;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 21;
   */
  districtUuid = "";

  /**
   * The UUID of the state
   *
   * --------------------------------------------------------
   *
   * @generated from field: string state_uuid = 22;
   */
  stateUuid = "";

  /**
   * The serial number (0, if needs to be ignored)
   *
   * @generated from field: uint64 serial_no = 25;
   */
  serialNo = protoInt64.zero;

  /**
   * The relation type
   *
   * @generated from field: nail2poll.VOTER_RELATION_TYPE relation_type = 32;
   */
  relationType = VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED;

  /**
   * The name of the relative
   *
   * @generated from field: string relation_name = 33;
   */
  relationName = "";

  /**
   * The house number
   *
   * @generated from field: string house_number = 34;
   */
  houseNumber = "";

  /**
   * The minimum age of the voter
   *
   * @generated from field: int64 age_min = 35;
   */
  ageMin = protoInt64.zero;

  /**
   * The maximum age of the voter
   *
   * @generated from field: int64 age_max = 36;
   */
  ageMax = protoInt64.zero;

  /**
   * The gender of the voter
   *
   * @generated from field: nail2poll.VOTER_GENDER gender = 37;
   */
  gender = VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED;

  /**
   * The date of birth of the voter
   *
   * @generated from field: string date_of_birth = 40;
   */
  dateOfBirth = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 50;
   */
  searchKey = "";

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 60;
   */
  addedByUserUuid = "";

  constructor(data?: PartialMessage<VotersServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "religion_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "profession_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "party_affiliation_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "caste_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "serial_no", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 32, name: "relation_type", kind: "enum", T: proto3.getEnumType(VOTER_RELATION_TYPE) },
    { no: 33, name: "relation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "house_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "age_min", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 36, name: "age_max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "gender", kind: "enum", T: proto3.getEnumType(VOTER_GENDER) },
    { no: 40, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceSearchAllReq {
    return new VotersServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceSearchAllReq {
    return new VotersServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceSearchAllReq {
    return new VotersServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceSearchAllReq | PlainMessage<VotersServiceSearchAllReq> | undefined, b: VotersServiceSearchAllReq | PlainMessage<VotersServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(VotersServiceSearchAllReq, a, b);
  }
}

/**
 *
 * Describes the parameters that are part of the response for a GROUP BY request
 *
 * @generated from message nail2poll.VotersServiceGroupByResponse
 */
export class VotersServiceGroupByResponse extends Message<VotersServiceGroupByResponse> {
  /**
   * The UUID of the related attribute
   *
   * @generated from field: string uuid = 1;
   */
  uuid = "";

  /**
   * The count of records
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<VotersServiceGroupByResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceGroupByResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceGroupByResponse {
    return new VotersServiceGroupByResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceGroupByResponse {
    return new VotersServiceGroupByResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceGroupByResponse {
    return new VotersServiceGroupByResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceGroupByResponse | PlainMessage<VotersServiceGroupByResponse> | undefined, b: VotersServiceGroupByResponse | PlainMessage<VotersServiceGroupByResponse> | undefined): boolean {
    return proto3.util.equals(VotersServiceGroupByResponse, a, b);
  }
}

/**
 *
 * Describes the list of responses for a group by request
 *
 * @generated from message nail2poll.VotersServiceGroupByResponseList
 */
export class VotersServiceGroupByResponseList extends Message<VotersServiceGroupByResponseList> {
  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.VotersServiceGroupByResponse list = 1;
   */
  list: VotersServiceGroupByResponse[] = [];

  constructor(data?: PartialMessage<VotersServiceGroupByResponseList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceGroupByResponseList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: VotersServiceGroupByResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceGroupByResponseList {
    return new VotersServiceGroupByResponseList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceGroupByResponseList {
    return new VotersServiceGroupByResponseList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceGroupByResponseList {
    return new VotersServiceGroupByResponseList().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceGroupByResponseList | PlainMessage<VotersServiceGroupByResponseList> | undefined, b: VotersServiceGroupByResponseList | PlainMessage<VotersServiceGroupByResponseList> | undefined): boolean {
    return proto3.util.equals(VotersServiceGroupByResponseList, a, b);
  }
}

/**
 * @generated from message nail2poll.VotersServiceAssignHouseholdRequest
 */
export class VotersServiceAssignHouseholdRequest extends Message<VotersServiceAssignHouseholdRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the household
   *
   * @generated from field: string household_uuid = 11;
   */
  householdUuid = "";

  constructor(data?: PartialMessage<VotersServiceAssignHouseholdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VotersServiceAssignHouseholdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "household_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VotersServiceAssignHouseholdRequest {
    return new VotersServiceAssignHouseholdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VotersServiceAssignHouseholdRequest {
    return new VotersServiceAssignHouseholdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VotersServiceAssignHouseholdRequest {
    return new VotersServiceAssignHouseholdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VotersServiceAssignHouseholdRequest | PlainMessage<VotersServiceAssignHouseholdRequest> | undefined, b: VotersServiceAssignHouseholdRequest | PlainMessage<VotersServiceAssignHouseholdRequest> | undefined): boolean {
    return proto3.util.equals(VotersServiceAssignHouseholdRequest, a, b);
  }
}

/**
 *
 * Describes the parameters that are part of the response for a GROUP BY request
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceGroupByResponse
 */
export class VoterLeaderSentimentsServiceGroupByResponse extends Message<VoterLeaderSentimentsServiceGroupByResponse> {
  /**
   * The UUID of the sentiment
   *
   * @generated from field: string sentiment_uuid = 1;
   */
  sentimentUuid = "";

  /**
   * The UUID of the political leader
   *
   * @generated from field: string leader_uuid = 2;
   */
  leaderUuid = "";

  /**
   * The count of records
   *
   * @generated from field: int64 count = 3;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceGroupByResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceGroupByResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sentiment_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "leader_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceGroupByResponse {
    return new VoterLeaderSentimentsServiceGroupByResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceGroupByResponse {
    return new VoterLeaderSentimentsServiceGroupByResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceGroupByResponse {
    return new VoterLeaderSentimentsServiceGroupByResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceGroupByResponse | PlainMessage<VoterLeaderSentimentsServiceGroupByResponse> | undefined, b: VoterLeaderSentimentsServiceGroupByResponse | PlainMessage<VoterLeaderSentimentsServiceGroupByResponse> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceGroupByResponse, a, b);
  }
}

/**
 *
 * Describes the list of responses for a group by request
 *
 * @generated from message nail2poll.VoterLeaderSentimentsServiceGroupByResponseList
 */
export class VoterLeaderSentimentsServiceGroupByResponseList extends Message<VoterLeaderSentimentsServiceGroupByResponseList> {
  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.VoterLeaderSentimentsServiceGroupByResponse list = 1;
   */
  list: VoterLeaderSentimentsServiceGroupByResponse[] = [];

  constructor(data?: PartialMessage<VoterLeaderSentimentsServiceGroupByResponseList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterLeaderSentimentsServiceGroupByResponseList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: VoterLeaderSentimentsServiceGroupByResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterLeaderSentimentsServiceGroupByResponseList {
    return new VoterLeaderSentimentsServiceGroupByResponseList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceGroupByResponseList {
    return new VoterLeaderSentimentsServiceGroupByResponseList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterLeaderSentimentsServiceGroupByResponseList {
    return new VoterLeaderSentimentsServiceGroupByResponseList().fromJsonString(jsonString, options);
  }

  static equals(a: VoterLeaderSentimentsServiceGroupByResponseList | PlainMessage<VoterLeaderSentimentsServiceGroupByResponseList> | undefined, b: VoterLeaderSentimentsServiceGroupByResponseList | PlainMessage<VoterLeaderSentimentsServiceGroupByResponseList> | undefined): boolean {
    return proto3.util.equals(VoterLeaderSentimentsServiceGroupByResponseList, a, b);
  }
}

