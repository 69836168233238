// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file voters.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Voter, VoterBasic, VoterLeaderSentimentsServiceGroupByResponseList, VoterPaginationResp, VotersList, VotersServiceAssignHouseholdRequest, VotersServiceCountRequest, VotersServiceCreateRequest, VotersServiceFilterReq, VotersServiceGroupByResponseList, VotersServicePaginationReq, VotersServiceSearchAllReq, VotersServiceUpdateRequest } from "./voters_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";
import { Sentiment } from "./sentiments_pb.js";

/**
 *
 * Describes the methods applicable on each voter
 *
 * @generated from service nail2poll.VotersService
 */
export const VotersService = {
  typeName: "nail2poll.VotersService",
  methods: {
    /**
     * Create a voter
     *
     * @generated from rpc nail2poll.VotersService.Create
     */
    create: {
      name: "Create",
      I: VotersServiceCreateRequest,
      O: Voter,
      kind: MethodKind.Unary,
    },
    /**
     * Update a voter
     *
     * @generated from rpc nail2poll.VotersService.Update
     */
    update: {
      name: "Update",
      I: VotersServiceUpdateRequest,
      O: Voter,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the voter
     *
     * @generated from rpc nail2poll.VotersService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the voter
     *
     * @generated from rpc nail2poll.VotersService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Assign household to voter
     *
     * @generated from rpc nail2poll.VotersService.AssignHousehold
     */
    assignHousehold: {
      name: "AssignHousehold",
      I: VotersServiceAssignHouseholdRequest,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.VotersService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: Voter,
      kind: MethodKind.Unary,
    },
    /**
     * View basic by UUID
     *
     * @generated from rpc nail2poll.VotersService.ViewBasicByUUID
     */
    viewBasicByUUID: {
      name: "ViewBasicByUUID",
      I: Identifier,
      O: VoterBasic,
      kind: MethodKind.Unary,
    },
    /**
     * View all voters
     *
     * @generated from rpc nail2poll.VotersService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: VotersList,
      kind: MethodKind.Unary,
    },
    /**
     * View voters with pagination
     *
     * @generated from rpc nail2poll.VotersService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: VotersServicePaginationReq,
      O: VoterPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.VotersService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: VotersServiceSearchAllReq,
      O: VotersList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.VotersService.Filter
     */
    filter: {
      name: "Filter",
      I: VotersServiceFilterReq,
      O: VotersList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.VotersService.Count
     */
    count: {
      name: "Count",
      I: VotersServiceCountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by caste
     *
     * // Retrieve statistics by constituency
     * rpc GroupByConstituency (VotersServiceCountRequest) returns (VotersServiceGroupByResponseList);
     * // Retrieve statistics by district
     * rpc GroupByDistrict (VotersServiceCountRequest) returns (VotersServiceGroupByResponseList);
     *
     * @generated from rpc nail2poll.VotersService.GroupByCaste
     */
    groupByCaste: {
      name: "GroupByCaste",
      I: VotersServiceCountRequest,
      O: VotersServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by household
     *
     * @generated from rpc nail2poll.VotersService.GroupByHousehold
     */
    groupByHousehold: {
      name: "GroupByHousehold",
      I: VotersServiceCountRequest,
      O: VotersServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by party
     *
     * @generated from rpc nail2poll.VotersService.GroupByParty
     */
    groupByParty: {
      name: "GroupByParty",
      I: VotersServiceCountRequest,
      O: VotersServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by profession
     *
     * @generated from rpc nail2poll.VotersService.GroupByProfession
     */
    groupByProfession: {
      name: "GroupByProfession",
      I: VotersServiceCountRequest,
      O: VotersServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by religion
     *
     * @generated from rpc nail2poll.VotersService.GroupByReligion
     */
    groupByReligion: {
      name: "GroupByReligion",
      I: VotersServiceCountRequest,
      O: VotersServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve statistics by sentiment and leader
     *
     * @generated from rpc nail2poll.VotersService.GroupBySentimentAndLeader
     */
    groupBySentimentAndLeader: {
      name: "GroupBySentimentAndLeader",
      I: VotersServiceCountRequest,
      O: VoterLeaderSentimentsServiceGroupByResponseList,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the latest sentiment aded by the voter. This is applicable only in cases where there's a single political leader.
     *
     * @generated from rpc nail2poll.VotersService.ViewLatestSentiment
     */
    viewLatestSentiment: {
      name: "ViewLatestSentiment",
      I: Identifier,
      O: Sentiment,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.VotersService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.VotersService.Export
     */
    export: {
      name: "Export",
      I: VotersServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

