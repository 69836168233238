// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file events_types.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EventsTypesList, EventsTypesServiceCreateRequest, EventsTypesServiceFilterReq, EventsTypesServicePaginationReq, EventsTypesServiceSearchAllReq, EventsTypesServiceUpdateRequest, EventType, EventTypePaginationResp } from "./events_types_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each event type
 *
 * @generated from service nail2poll.EventsTypesService
 */
export const EventsTypesService = {
  typeName: "nail2poll.EventsTypesService",
  methods: {
    /**
     * Create a event type
     *
     * @generated from rpc nail2poll.EventsTypesService.Create
     */
    create: {
      name: "Create",
      I: EventsTypesServiceCreateRequest,
      O: EventType,
      kind: MethodKind.Unary,
    },
    /**
     * Update a event type
     *
     * @generated from rpc nail2poll.EventsTypesService.Update
     */
    update: {
      name: "Update",
      I: EventsTypesServiceUpdateRequest,
      O: EventType,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the event type
     *
     * @generated from rpc nail2poll.EventsTypesService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the event type
     *
     * @generated from rpc nail2poll.EventsTypesService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.EventsTypesService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: EventType,
      kind: MethodKind.Unary,
    },
    /**
     * View all events types
     *
     * @generated from rpc nail2poll.EventsTypesService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: EventsTypesList,
      kind: MethodKind.Unary,
    },
    /**
     * View events types with pagination
     *
     * @generated from rpc nail2poll.EventsTypesService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: EventsTypesServicePaginationReq,
      O: EventTypePaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.EventsTypesService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: EventsTypesServiceSearchAllReq,
      O: EventsTypesList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.EventsTypesService.Filter
     */
    filter: {
      name: "Filter",
      I: EventsTypesServiceFilterReq,
      O: EventsTypesList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.EventsTypesService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.EventsTypesService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.EventsTypesService.Export
     */
    export: {
      name: "Export",
      I: EventsTypesServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

