// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file religions.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Religion, ReligionPaginationResp, ReligionsList, ReligionsServiceCreateRequest, ReligionsServiceFilterReq, ReligionsServicePaginationReq, ReligionsServiceSearchAllReq, ReligionsServiceUpdateRequest } from "./religions_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each religion
 *
 * @generated from service nail2poll.ReligionsService
 */
export const ReligionsService = {
  typeName: "nail2poll.ReligionsService",
  methods: {
    /**
     * Create a religion
     *
     * @generated from rpc nail2poll.ReligionsService.Create
     */
    create: {
      name: "Create",
      I: ReligionsServiceCreateRequest,
      O: Religion,
      kind: MethodKind.Unary,
    },
    /**
     * Update a religion
     *
     * @generated from rpc nail2poll.ReligionsService.Update
     */
    update: {
      name: "Update",
      I: ReligionsServiceUpdateRequest,
      O: Religion,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the religion
     *
     * @generated from rpc nail2poll.ReligionsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the religion
     *
     * @generated from rpc nail2poll.ReligionsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.ReligionsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: Religion,
      kind: MethodKind.Unary,
    },
    /**
     * View all religions
     *
     * @generated from rpc nail2poll.ReligionsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: ReligionsList,
      kind: MethodKind.Unary,
    },
    /**
     * View religions with pagination
     *
     * @generated from rpc nail2poll.ReligionsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: ReligionsServicePaginationReq,
      O: ReligionPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.ReligionsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: ReligionsServiceSearchAllReq,
      O: ReligionsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.ReligionsService.Filter
     */
    filter: {
      name: "Filter",
      I: ReligionsServiceFilterReq,
      O: ReligionsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.ReligionsService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.ReligionsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.ReligionsService.Export
     */
    export: {
      name: "Export",
      I: ReligionsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

