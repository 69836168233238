// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file households.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving households
 *
 * @generated from enum nail2poll.HOUSEHOLD_SORT_KEY
 */
export enum HOUSEHOLD_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: HOUSEHOLD_SORT_KEY_CREATED_AT = 1;
   */
  HOUSEHOLD_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: HOUSEHOLD_SORT_KEY_MODIFIED_AT = 2;
   */
  HOUSEHOLD_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: HOUSEHOLD_SORT_KEY_NAME = 10;
   */
  HOUSEHOLD_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: HOUSEHOLD_SORT_KEY_CODE = 11;
   */
  HOUSEHOLD_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(HOUSEHOLD_SORT_KEY)
proto3.util.setEnumType(HOUSEHOLD_SORT_KEY, "nail2poll.HOUSEHOLD_SORT_KEY", [
  { no: 0, name: "HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "HOUSEHOLD_SORT_KEY_CREATED_AT" },
  { no: 2, name: "HOUSEHOLD_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "HOUSEHOLD_SORT_KEY_NAME" },
  { no: 11, name: "HOUSEHOLD_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each household on the platform
 *
 * @generated from message nail2poll.Household
 */
export class Household extends Message<Household> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the household
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the household
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Address of the household
   *
   * @generated from field: string address = 12;
   */
  address = "";

  /**
   * The latitude of the location of the household
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location of the household
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<Household>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Household";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Household {
    return new Household().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Household {
    return new Household().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Household {
    return new Household().fromJsonString(jsonString, options);
  }

  static equals(a: Household | PlainMessage<Household> | undefined, b: Household | PlainMessage<Household> | undefined): boolean {
    return proto3.util.equals(Household, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of households
 *
 * @generated from message nail2poll.HouseholdsList
 */
export class HouseholdsList extends Message<HouseholdsList> {
  /**
   * List of households
   *
   * @generated from field: repeated nail2poll.Household list = 1;
   */
  list: Household[] = [];

  constructor(data?: PartialMessage<HouseholdsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Household, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsList {
    return new HouseholdsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsList {
    return new HouseholdsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsList {
    return new HouseholdsList().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsList | PlainMessage<HouseholdsList> | undefined, b: HouseholdsList | PlainMessage<HouseholdsList> | undefined): boolean {
    return proto3.util.equals(HouseholdsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.HouseholdPaginationResp
 */
export class HouseholdPaginationResp extends Message<HouseholdPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Household payload = 3;
   */
  payload: Household[] = [];

  constructor(data?: PartialMessage<HouseholdPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Household, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdPaginationResp {
    return new HouseholdPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdPaginationResp {
    return new HouseholdPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdPaginationResp {
    return new HouseholdPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdPaginationResp | PlainMessage<HouseholdPaginationResp> | undefined, b: HouseholdPaginationResp | PlainMessage<HouseholdPaginationResp> | undefined): boolean {
    return proto3.util.equals(HouseholdPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a household
 *
 * @generated from message nail2poll.HouseholdsServiceCreateRequest
 */
export class HouseholdsServiceCreateRequest extends Message<HouseholdsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the household
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the household
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Address of the household
   *
   * @generated from field: string address = 12;
   */
  address = "";

  /**
   * The latitude of the location of the household
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location of the household
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<HouseholdsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServiceCreateRequest {
    return new HouseholdsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServiceCreateRequest {
    return new HouseholdsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServiceCreateRequest {
    return new HouseholdsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServiceCreateRequest | PlainMessage<HouseholdsServiceCreateRequest> | undefined, b: HouseholdsServiceCreateRequest | PlainMessage<HouseholdsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(HouseholdsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a household
 *
 * @generated from message nail2poll.HouseholdsServiceUpdateRequest
 */
export class HouseholdsServiceUpdateRequest extends Message<HouseholdsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the household
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the household
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Address of the household
   *
   * @generated from field: string address = 12;
   */
  address = "";

  /**
   * The latitude of the location of the household
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location of the household
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<HouseholdsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServiceUpdateRequest {
    return new HouseholdsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServiceUpdateRequest {
    return new HouseholdsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServiceUpdateRequest {
    return new HouseholdsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServiceUpdateRequest | PlainMessage<HouseholdsServiceUpdateRequest> | undefined, b: HouseholdsServiceUpdateRequest | PlainMessage<HouseholdsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(HouseholdsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.HouseholdsServicePaginationReq
 */
export class HouseholdsServicePaginationReq extends Message<HouseholdsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.HOUSEHOLD_SORT_KEY sort_key = 5;
   */
  sortKey = HOUSEHOLD_SORT_KEY.HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<HouseholdsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(HOUSEHOLD_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServicePaginationReq {
    return new HouseholdsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServicePaginationReq {
    return new HouseholdsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServicePaginationReq {
    return new HouseholdsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServicePaginationReq | PlainMessage<HouseholdsServicePaginationReq> | undefined, b: HouseholdsServicePaginationReq | PlainMessage<HouseholdsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(HouseholdsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the request payload of a count filter
 *
 * @generated from message nail2poll.HouseholdsServiceCountRequest
 */
export class HouseholdsServiceCountRequest extends Message<HouseholdsServiceCountRequest> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 10;
   */
  addedByUserUuid = "";

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 40;
   */
  pollingStationUuid = "";

  constructor(data?: PartialMessage<HouseholdsServiceCountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServiceCountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServiceCountRequest {
    return new HouseholdsServiceCountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServiceCountRequest {
    return new HouseholdsServiceCountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServiceCountRequest {
    return new HouseholdsServiceCountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServiceCountRequest | PlainMessage<HouseholdsServiceCountRequest> | undefined, b: HouseholdsServiceCountRequest | PlainMessage<HouseholdsServiceCountRequest> | undefined): boolean {
    return proto3.util.equals(HouseholdsServiceCountRequest, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.HouseholdsServiceFilterReq
 */
export class HouseholdsServiceFilterReq extends Message<HouseholdsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.HOUSEHOLD_SORT_KEY sort_key = 5;
   */
  sortKey = HOUSEHOLD_SORT_KEY.HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 10;
   */
  addedByUserUuid = "";

  /**
   * The name of the household
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the household
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 40;
   */
  pollingStationUuid = "";

  constructor(data?: PartialMessage<HouseholdsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(HOUSEHOLD_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServiceFilterReq {
    return new HouseholdsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServiceFilterReq {
    return new HouseholdsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServiceFilterReq {
    return new HouseholdsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServiceFilterReq | PlainMessage<HouseholdsServiceFilterReq> | undefined, b: HouseholdsServiceFilterReq | PlainMessage<HouseholdsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(HouseholdsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.HouseholdsServiceSearchAllReq
 */
export class HouseholdsServiceSearchAllReq extends Message<HouseholdsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.HOUSEHOLD_SORT_KEY sort_key = 5;
   */
  sortKey = HOUSEHOLD_SORT_KEY.HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The minimum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_start = 8;
   */
  modifiedTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by modified timestamp
   *
   * @generated from field: int64 modified_timestamp_end = 9;
   */
  modifiedTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the user that added this household
   *
   * @generated from field: string added_by_user_uuid = 10;
   */
  addedByUserUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  /**
   * The UUID of the polling station
   *
   * @generated from field: string polling_station_uuid = 40;
   */
  pollingStationUuid = "";

  constructor(data?: PartialMessage<HouseholdsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.HouseholdsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(HOUSEHOLD_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "polling_station_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HouseholdsServiceSearchAllReq {
    return new HouseholdsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HouseholdsServiceSearchAllReq {
    return new HouseholdsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HouseholdsServiceSearchAllReq {
    return new HouseholdsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: HouseholdsServiceSearchAllReq | PlainMessage<HouseholdsServiceSearchAllReq> | undefined, b: HouseholdsServiceSearchAllReq | PlainMessage<HouseholdsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(HouseholdsServiceSearchAllReq, a, b);
  }
}

