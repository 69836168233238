// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file voters_notes.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { VoterNote, VoterNotePaginationResp, VoterNotesList, VoterNotesServiceCreateRequest, VoterNotesServiceFilterReq, VoterNotesServicePaginationReq, VoterNotesServiceSearchAllReq, VoterNotesServiceUpdateRequest } from "./voters_notes_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, Identifier, IdentifierWithUserComment } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each voter note
 *
 * @generated from service nail2poll.VoterNotesService
 */
export const VoterNotesService = {
  typeName: "nail2poll.VoterNotesService",
  methods: {
    /**
     * Create a voter note
     *
     * @generated from rpc nail2poll.VoterNotesService.Create
     */
    create: {
      name: "Create",
      I: VoterNotesServiceCreateRequest,
      O: VoterNote,
      kind: MethodKind.Unary,
    },
    /**
     * Update a voter note
     *
     * @generated from rpc nail2poll.VoterNotesService.Update
     */
    update: {
      name: "Update",
      I: VoterNotesServiceUpdateRequest,
      O: VoterNote,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the voter note
     *
     * @generated from rpc nail2poll.VoterNotesService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the voter note
     *
     * @generated from rpc nail2poll.VoterNotesService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.VoterNotesService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: VoterNote,
      kind: MethodKind.Unary,
    },
    /**
     * View all voter notes
     *
     * @generated from rpc nail2poll.VoterNotesService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: VoterNotesList,
      kind: MethodKind.Unary,
    },
    /**
     * View voter notes with pagination
     *
     * @generated from rpc nail2poll.VoterNotesService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: VoterNotesServicePaginationReq,
      O: VoterNotePaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.VoterNotesService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: VoterNotesServiceSearchAllReq,
      O: VoterNotesList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.VoterNotesService.Filter
     */
    filter: {
      name: "Filter",
      I: VoterNotesServiceFilterReq,
      O: VoterNotesList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.VoterNotesService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

