// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file professions.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Profession, ProfessionPaginationResp, ProfessionsList, ProfessionsServiceCreateRequest, ProfessionsServiceFilterReq, ProfessionsServicePaginationReq, ProfessionsServiceSearchAllReq, ProfessionsServiceUpdateRequest } from "./professions_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each profession
 *
 * @generated from service nail2poll.ProfessionsService
 */
export const ProfessionsService = {
  typeName: "nail2poll.ProfessionsService",
  methods: {
    /**
     * Create a profession
     *
     * @generated from rpc nail2poll.ProfessionsService.Create
     */
    create: {
      name: "Create",
      I: ProfessionsServiceCreateRequest,
      O: Profession,
      kind: MethodKind.Unary,
    },
    /**
     * Update a profession
     *
     * @generated from rpc nail2poll.ProfessionsService.Update
     */
    update: {
      name: "Update",
      I: ProfessionsServiceUpdateRequest,
      O: Profession,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the profession
     *
     * @generated from rpc nail2poll.ProfessionsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the profession
     *
     * @generated from rpc nail2poll.ProfessionsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.ProfessionsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: Profession,
      kind: MethodKind.Unary,
    },
    /**
     * View all professions
     *
     * @generated from rpc nail2poll.ProfessionsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: ProfessionsList,
      kind: MethodKind.Unary,
    },
    /**
     * View professions with pagination
     *
     * @generated from rpc nail2poll.ProfessionsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: ProfessionsServicePaginationReq,
      O: ProfessionPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.ProfessionsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: ProfessionsServiceSearchAllReq,
      O: ProfessionsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.ProfessionsService.Filter
     */
    filter: {
      name: "Filter",
      I: ProfessionsServiceFilterReq,
      O: ProfessionsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.ProfessionsService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.ProfessionsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.ProfessionsService.Export
     */
    export: {
      name: "Export",
      I: ProfessionsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

