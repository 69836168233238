// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file base.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 *
 * Describes the order in which the records need to be returned
 *
 * @generated from enum nail2poll.SORT_ORDER
 */
export enum SORT_ORDER {
  /**
   * Fetch results in the ascending order of the provided sort key
   *
   * @generated from enum value: ASCENDING_UNSPECIFIED = 0;
   */
  ASCENDING_UNSPECIFIED = 0,

  /**
   * Fetch results in the descending order of the provided sort key
   *
   * @generated from enum value: DESCENDING = 1;
   */
  DESCENDING = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SORT_ORDER)
proto3.util.setEnumType(SORT_ORDER, "nail2poll.SORT_ORDER", [
  { no: 0, name: "ASCENDING_UNSPECIFIED" },
  { no: 1, name: "DESCENDING" },
]);

/**
 *
 * Describes the supported device types
 *
 * @generated from enum nail2poll.DEVICE_TYPE
 */
export enum DEVICE_TYPE {
  /**
   * Web browser
   *
   * @generated from enum value: WEB_UNSPECIFIED = 0;
   */
  WEB_UNSPECIFIED = 0,

  /**
   * Mobile device
   *
   * @generated from enum value: MOBILE = 1;
   */
  MOBILE = 1,

  /**
   * Desktop application
   *
   * @generated from enum value: DESKTOP = 2;
   */
  DESKTOP = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DEVICE_TYPE)
proto3.util.setEnumType(DEVICE_TYPE, "nail2poll.DEVICE_TYPE", [
  { no: 0, name: "WEB_UNSPECIFIED" },
  { no: 1, name: "MOBILE" },
  { no: 2, name: "DESKTOP" },
]);

/**
 *
 * Describes all the possible values within a logbook operation
 *
 * @generated from enum nail2poll.LOGBOOK_OPERATION
 */
export enum LOGBOOK_OPERATION {
  /**
   * Create a resource
   *
   * @generated from enum value: CREATE_UNSPECIFIED = 0;
   */
  CREATE_UNSPECIFIED = 0,

  /**
   * Update a resource
   *
   * @generated from enum value: UPDATE = 1;
   */
  UPDATE = 1,

  /**
   * Archive a resource
   *
   * @generated from enum value: ARCHIVE = 2;
   */
  ARCHIVE = 2,

  /**
   * Restore a resource
   *
   * @generated from enum value: RESTORE = 3;
   */
  RESTORE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LOGBOOK_OPERATION)
proto3.util.setEnumType(LOGBOOK_OPERATION, "nail2poll.LOGBOOK_OPERATION", [
  { no: 0, name: "CREATE_UNSPECIFIED" },
  { no: 1, name: "UPDATE" },
  { no: 2, name: "ARCHIVE" },
  { no: 3, name: "RESTORE" },
]);

/**
 *
 * Describes an empty object
 *
 * @generated from message nail2poll.Empty
 */
export class Empty extends Message<Empty> {
  constructor(data?: PartialMessage<Empty>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Empty";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Empty {
    return new Empty().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Empty {
    return new Empty().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Empty {
    return new Empty().fromJsonString(jsonString, options);
  }

  static equals(a: Empty | PlainMessage<Empty> | undefined, b: Empty | PlainMessage<Empty> | undefined): boolean {
    return proto3.util.equals(Empty, a, b);
  }
}

/**
 *
 * Describes the boolean response
 *
 * @generated from message nail2poll.BooleanResponse
 */
export class BooleanResponse extends Message<BooleanResponse> {
  /**
   * Stores if the value is true or false
   *
   * @generated from field: bool value = 1;
   */
  value = false;

  constructor(data?: PartialMessage<BooleanResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.BooleanResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BooleanResponse {
    return new BooleanResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BooleanResponse {
    return new BooleanResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BooleanResponse {
    return new BooleanResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BooleanResponse | PlainMessage<BooleanResponse> | undefined, b: BooleanResponse | PlainMessage<BooleanResponse> | undefined): boolean {
    return proto3.util.equals(BooleanResponse, a, b);
  }
}

/**
 *
 * Describes the parameters that are present in a response to a lengthy process (such as an import of records)
 *
 * @generated from message nail2poll.ErroredResponse
 */
export class ErroredResponse extends Message<ErroredResponse> {
  /**
   * Stores the if process has errored
   *
   * @generated from field: bool has_errored = 1;
   */
  hasErrored = false;

  /**
   * Stores all the errors occurred
   *
   * @generated from field: string errors = 2;
   */
  errors = "";

  constructor(data?: PartialMessage<ErroredResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ErroredResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "has_errored", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ErroredResponse {
    return new ErroredResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ErroredResponse {
    return new ErroredResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ErroredResponse {
    return new ErroredResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ErroredResponse | PlainMessage<ErroredResponse> | undefined, b: ErroredResponse | PlainMessage<ErroredResponse> | undefined): boolean {
    return proto3.util.equals(ErroredResponse, a, b);
  }
}

/**
 *
 * Describes the request payload for assigning an image to a record
 *
 * @generated from message nail2poll.ImageAssignRequest
 */
export class ImageAssignRequest extends Message<ImageAssignRequest> {
  /**
   * UUID of the record
   *
   * @generated from field: string uuid = 1;
   */
  uuid = "";

  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 2;
   */
  userComment = "";

  /**
   * The content of the image
   *
   * @generated from field: bytes image = 10;
   */
  image = new Uint8Array(0);

  /**
   * The MIME type of the image
   *
   * @generated from field: string mime_type = 11;
   */
  mimeType = "";

  constructor(data?: PartialMessage<ImageAssignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ImageAssignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 11, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageAssignRequest {
    return new ImageAssignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageAssignRequest {
    return new ImageAssignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageAssignRequest {
    return new ImageAssignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImageAssignRequest | PlainMessage<ImageAssignRequest> | undefined, b: ImageAssignRequest | PlainMessage<ImageAssignRequest> | undefined): boolean {
    return proto3.util.equals(ImageAssignRequest, a, b);
  }
}

/**
 *
 * Describes a generic response that consists of info of an image 
 *
 * @generated from message nail2poll.ImageResponse
 */
export class ImageResponse extends Message<ImageResponse> {
  /**
   * Stores the raw image content
   *
   * @generated from field: bytes image = 1;
   */
  image = new Uint8Array(0);

  /**
   * Stores the MIME type of the image
   *
   * @generated from field: string mime_type = 2;
   */
  mimeType = "";

  /**
   * The timestamp of when the image was captured
   *
   * @generated from field: int64 timestamp = 3;
   */
  timestamp = protoInt64.zero;

  constructor(data?: PartialMessage<ImageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ImageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageResponse {
    return new ImageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageResponse {
    return new ImageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageResponse {
    return new ImageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImageResponse | PlainMessage<ImageResponse> | undefined, b: ImageResponse | PlainMessage<ImageResponse> | undefined): boolean {
    return proto3.util.equals(ImageResponse, a, b);
  }
}

/**
 *
 * Describes the status of the records returned to a request
 *
 * @generated from message nail2poll.ActiveRequest
 */
export class ActiveRequest extends Message<ActiveRequest> {
  /**
   * Used in ViewAll procedural calls, to denote if only active records need to be returned
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  constructor(data?: PartialMessage<ActiveRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ActiveRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveRequest {
    return new ActiveRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveRequest {
    return new ActiveRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveRequest {
    return new ActiveRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveRequest | PlainMessage<ActiveRequest> | undefined, b: ActiveRequest | PlainMessage<ActiveRequest> | undefined): boolean {
    return proto3.util.equals(ActiveRequest, a, b);
  }
}

/**
 *
 * Describes the status of the records returned to a request specific to an event
 *
 * @generated from message nail2poll.ActiveForEventRequest
 */
export class ActiveForEventRequest extends Message<ActiveForEventRequest> {
  /**
   * Denotes if only active records need to be returned
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * Stores the event UUID and limits the query to the appropriate event
   *
   * @generated from field: string event_uuid = 2;
   */
  eventUuid = "";

  constructor(data?: PartialMessage<ActiveForEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ActiveForEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "event_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveForEventRequest {
    return new ActiveForEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveForEventRequest {
    return new ActiveForEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveForEventRequest {
    return new ActiveForEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveForEventRequest | PlainMessage<ActiveForEventRequest> | undefined, b: ActiveForEventRequest | PlainMessage<ActiveForEventRequest> | undefined): boolean {
    return proto3.util.equals(ActiveForEventRequest, a, b);
  }
}

/**
 *
 * Describes the payload for a request to determine the count of records
 *
 * @generated from message nail2poll.CountRequest
 */
export class CountRequest extends Message<CountRequest> {
  /**
   * Denotes if only active records need to be returned
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  constructor(data?: PartialMessage<CountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountRequest {
    return new CountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountRequest {
    return new CountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountRequest {
    return new CountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CountRequest | PlainMessage<CountRequest> | undefined, b: CountRequest | PlainMessage<CountRequest> | undefined): boolean {
    return proto3.util.equals(CountRequest, a, b);
  }
}

/**
 *
 * Describes the payload for a request to determine the count of records within a specific event
 *
 * @generated from message nail2poll.CountForEventRequest
 */
export class CountForEventRequest extends Message<CountForEventRequest> {
  /**
   * Denotes if only active records need to be returned
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * Stores the event UUID and limits the query to the appropriate event
   *
   * @generated from field: string event_uuid = 2;
   */
  eventUuid = "";

  constructor(data?: PartialMessage<CountForEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CountForEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "event_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountForEventRequest {
    return new CountForEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountForEventRequest {
    return new CountForEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountForEventRequest {
    return new CountForEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CountForEventRequest | PlainMessage<CountForEventRequest> | undefined, b: CountForEventRequest | PlainMessage<CountForEventRequest> | undefined): boolean {
    return proto3.util.equals(CountForEventRequest, a, b);
  }
}

/**
 *
 * Describes the count response
 *
 * @generated from message nail2poll.CountResponse
 */
export class CountResponse extends Message<CountResponse> {
  /**
   * The number of records
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<CountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountResponse {
    return new CountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountResponse {
    return new CountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountResponse {
    return new CountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CountResponse | PlainMessage<CountResponse> | undefined, b: CountResponse | PlainMessage<CountResponse> | undefined): boolean {
    return proto3.util.equals(CountResponse, a, b);
  }
}

/**
 *
 * Describes the URL response
 *
 * @generated from message nail2poll.URLResponse
 */
export class URLResponse extends Message<URLResponse> {
  /**
   * The URL
   *
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<URLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.URLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): URLResponse {
    return new URLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): URLResponse {
    return new URLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): URLResponse {
    return new URLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: URLResponse | PlainMessage<URLResponse> | undefined, b: URLResponse | PlainMessage<URLResponse> | undefined): boolean {
    return proto3.util.equals(URLResponse, a, b);
  }
}

/**
 *
 * Describes the message body that consists of a CSV file. This can be used to import records from a CSV file.
 *
 * @generated from message nail2poll.CSVImport
 */
export class CSVImport extends Message<CSVImport> {
  /**
   * Ignores the first line of the file (considering it as the header) If true, then the first line is skipped (considered as the header). If false, then even the first line is imported.
   *
   * @generated from field: bool ignore_first_line = 1;
   */
  ignoreFirstLine = false;

  /**
   * Stores the content of the file
   *
   * @generated from field: bytes content = 10;
   */
  content = new Uint8Array(0);

  constructor(data?: PartialMessage<CSVImport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CSVImport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ignore_first_line", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CSVImport {
    return new CSVImport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CSVImport {
    return new CSVImport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CSVImport {
    return new CSVImport().fromJsonString(jsonString, options);
  }

  static equals(a: CSVImport | PlainMessage<CSVImport> | undefined, b: CSVImport | PlainMessage<CSVImport> | undefined): boolean {
    return proto3.util.equals(CSVImport, a, b);
  }
}

/**
 *
 * Describes the metadata of each resource
 *
 * @generated from message nail2poll.Metadata
 */
export class Metadata extends Message<Metadata> {
  /**
   * ID of the resource
   *
   * @generated from field: uint64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * UUID of the resource
   *
   * @generated from field: string uuid = 5;
   */
  uuid = "";

  /**
   * Stores the UUID of the user who added this resource
   *
   * @generated from field: string added_by_user_uuid = 6;
   */
  addedByUserUuid = "";

  /**
   * Represents if the resource is active
   *
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * Stores the timestamp of when the resource was created
   *
   * @generated from field: int64 created_at = 8;
   */
  createdAt = protoInt64.zero;

  /**
   * Stores the timestamp of when the resource was last modified
   *
   * @generated from field: int64 modified_at = 9;
   */
  modifiedAt = protoInt64.zero;

  constructor(data?: PartialMessage<Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "modified_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Metadata {
    return new Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Metadata {
    return new Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Metadata {
    return new Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: Metadata | PlainMessage<Metadata> | undefined, b: Metadata | PlainMessage<Metadata> | undefined): boolean {
    return proto3.util.equals(Metadata, a, b);
  }
}

/**
 *
 * Describes the UUID identifier
 *
 * @generated from message nail2poll.Identifier
 */
export class Identifier extends Message<Identifier> {
  /**
   * UUID of the resource
   *
   * @generated from field: string uuid = 1;
   */
  uuid = "";

  constructor(data?: PartialMessage<Identifier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Identifier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Identifier {
    return new Identifier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Identifier {
    return new Identifier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Identifier {
    return new Identifier().fromJsonString(jsonString, options);
  }

  static equals(a: Identifier | PlainMessage<Identifier> | undefined, b: Identifier | PlainMessage<Identifier> | undefined): boolean {
    return proto3.util.equals(Identifier, a, b);
  }
}

/**
 *
 * Describes a simple search key request
 *
 * @generated from message nail2poll.SearchKeyRequest
 */
export class SearchKeyRequest extends Message<SearchKeyRequest> {
  /**
   * The search key
   *
   * @generated from field: string search_key = 1;
   */
  searchKey = "";

  constructor(data?: PartialMessage<SearchKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SearchKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchKeyRequest {
    return new SearchKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchKeyRequest {
    return new SearchKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchKeyRequest {
    return new SearchKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchKeyRequest | PlainMessage<SearchKeyRequest> | undefined, b: SearchKeyRequest | PlainMessage<SearchKeyRequest> | undefined): boolean {
    return proto3.util.equals(SearchKeyRequest, a, b);
  }
}

/**
 *
 * Describes the UUID identifier with a user comment. Useful when an operation needs to be performed, and a user comment needs to be recorded
 *
 * @generated from message nail2poll.IdentifierWithUserComment
 */
export class IdentifierWithUserComment extends Message<IdentifierWithUserComment> {
  /**
   * UUID of the resource
   *
   * @generated from field: string uuid = 1;
   */
  uuid = "";

  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 2;
   */
  userComment = "";

  constructor(data?: PartialMessage<IdentifierWithUserComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.IdentifierWithUserComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdentifierWithUserComment {
    return new IdentifierWithUserComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdentifierWithUserComment {
    return new IdentifierWithUserComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdentifierWithUserComment {
    return new IdentifierWithUserComment().fromJsonString(jsonString, options);
  }

  static equals(a: IdentifierWithUserComment | PlainMessage<IdentifierWithUserComment> | undefined, b: IdentifierWithUserComment | PlainMessage<IdentifierWithUserComment> | undefined): boolean {
    return proto3.util.equals(IdentifierWithUserComment, a, b);
  }
}

/**
 *
 * Describes the username that could be used as an identifier
 *
 * @generated from message nail2poll.IdentifierUsername
 */
export class IdentifierUsername extends Message<IdentifierUsername> {
  /**
   * The username of the user
   *
   * @generated from field: string username = 1;
   */
  username = "";

  constructor(data?: PartialMessage<IdentifierUsername>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.IdentifierUsername";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdentifierUsername {
    return new IdentifierUsername().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdentifierUsername {
    return new IdentifierUsername().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdentifierUsername {
    return new IdentifierUsername().fromJsonString(jsonString, options);
  }

  static equals(a: IdentifierUsername | PlainMessage<IdentifierUsername> | undefined, b: IdentifierUsername | PlainMessage<IdentifierUsername> | undefined): boolean {
    return proto3.util.equals(IdentifierUsername, a, b);
  }
}

/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object)
 *
 * @generated from message nail2poll.LogbookLogConcise
 */
export class LogbookLogConcise extends Message<LogbookLogConcise> {
  /**
   * Stores the metada of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the resource
   *
   * @generated from field: string ref_uuid = 10;
   */
  refUuid = "";

  /**
   * The operation that was performed
   *
   * @generated from field: nail2poll.LOGBOOK_OPERATION operation = 11;
   */
  operation = LOGBOOK_OPERATION.CREATE_UNSPECIFIED;

  /**
   * Stores the UUID of the user who performed this operation
   *
   * @generated from field: string added_by_user_uuid = 12;
   */
  addedByUserUuid = "";

  /**
   * The comment generated by the application
   *
   * @generated from field: string app_comment = 13;
   */
  appComment = "";

  /**
   * The comment entered by the user
   *
   * @generated from field: string user_comment = 14;
   */
  userComment = "";

  constructor(data?: PartialMessage<LogbookLogConcise>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogbookLogConcise";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "operation", kind: "enum", T: proto3.getEnumType(LOGBOOK_OPERATION) },
    { no: 12, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogbookLogConcise {
    return new LogbookLogConcise().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogbookLogConcise {
    return new LogbookLogConcise().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogbookLogConcise {
    return new LogbookLogConcise().fromJsonString(jsonString, options);
  }

  static equals(a: LogbookLogConcise | PlainMessage<LogbookLogConcise> | undefined, b: LogbookLogConcise | PlainMessage<LogbookLogConcise> | undefined): boolean {
    return proto3.util.equals(LogbookLogConcise, a, b);
  }
}

/**
 *
 * Describes each parameter that's part of the logbook (includes historical data of the object)
 *
 * @generated from message nail2poll.LogbookLogComplete
 */
export class LogbookLogComplete extends Message<LogbookLogComplete> {
  /**
   * Stores the metada of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the resource
   *
   * @generated from field: string ref_uuid = 10;
   */
  refUuid = "";

  /**
   * The operation that was performed
   *
   * @generated from field: nail2poll.LOGBOOK_OPERATION operation = 11;
   */
  operation = LOGBOOK_OPERATION.CREATE_UNSPECIFIED;

  /**
   * Stores the UUID of the user who performed this operation
   *
   * @generated from field: string added_by_user_uuid = 12;
   */
  addedByUserUuid = "";

  /**
   * The comment generated by the application
   *
   * @generated from field: string app_comment = 13;
   */
  appComment = "";

  /**
   * The comment entered by the user
   *
   * @generated from field: string user_comment = 14;
   */
  userComment = "";

  /**
   * The marshalled object at the time the log was created
   *
   * @generated from field: bytes previous_data = 15;
   */
  previousData = new Uint8Array(0);

  constructor(data?: PartialMessage<LogbookLogComplete>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogbookLogComplete";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "operation", kind: "enum", T: proto3.getEnumType(LOGBOOK_OPERATION) },
    { no: 12, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "previous_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogbookLogComplete {
    return new LogbookLogComplete().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogbookLogComplete {
    return new LogbookLogComplete().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogbookLogComplete {
    return new LogbookLogComplete().fromJsonString(jsonString, options);
  }

  static equals(a: LogbookLogComplete | PlainMessage<LogbookLogComplete> | undefined, b: LogbookLogComplete | PlainMessage<LogbookLogComplete> | undefined): boolean {
    return proto3.util.equals(LogbookLogComplete, a, b);
  }
}

/**
 *
 * Describes the list of logs from the logbook that's sent as a response to a logbook query
 *
 * @generated from message nail2poll.LogbookConcise
 */
export class LogbookConcise extends Message<LogbookConcise> {
  /**
   * List of logs
   *
   * @generated from field: repeated nail2poll.LogbookLogConcise logs = 1;
   */
  logs: LogbookLogConcise[] = [];

  constructor(data?: PartialMessage<LogbookConcise>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogbookConcise";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "logs", kind: "message", T: LogbookLogConcise, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogbookConcise {
    return new LogbookConcise().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogbookConcise {
    return new LogbookConcise().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogbookConcise {
    return new LogbookConcise().fromJsonString(jsonString, options);
  }

  static equals(a: LogbookConcise | PlainMessage<LogbookConcise> | undefined, b: LogbookConcise | PlainMessage<LogbookConcise> | undefined): boolean {
    return proto3.util.equals(LogbookConcise, a, b);
  }
}

/**
 *
 * Describes the complete list of logs from the logbook that's sent as a response to a logbook query
 *
 * @generated from message nail2poll.LogbookComplete
 */
export class LogbookComplete extends Message<LogbookComplete> {
  /**
   * List of logs
   *
   * @generated from field: repeated nail2poll.LogbookLogComplete logs = 1;
   */
  logs: LogbookLogComplete[] = [];

  constructor(data?: PartialMessage<LogbookComplete>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogbookComplete";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "logs", kind: "message", T: LogbookLogComplete, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogbookComplete {
    return new LogbookComplete().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogbookComplete {
    return new LogbookComplete().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogbookComplete {
    return new LogbookComplete().fromJsonString(jsonString, options);
  }

  static equals(a: LogbookComplete | PlainMessage<LogbookComplete> | undefined, b: LogbookComplete | PlainMessage<LogbookComplete> | undefined): boolean {
    return proto3.util.equals(LogbookComplete, a, b);
  }
}

/**
 *
 * Describes the response payload that consists of a file
 *
 * @generated from message nail2poll.StandardFile
 */
export class StandardFile extends Message<StandardFile> {
  /**
   * Stores the name of the file
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Stores the MIME type of the file
   *
   * @generated from field: string mime_type = 2;
   */
  mimeType = "";

  /**
   * Stores the raw file content
   *
   * @generated from field: bytes content = 10;
   */
  content = new Uint8Array(0);

  constructor(data?: PartialMessage<StandardFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.StandardFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StandardFile {
    return new StandardFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StandardFile {
    return new StandardFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StandardFile {
    return new StandardFile().fromJsonString(jsonString, options);
  }

  static equals(a: StandardFile | PlainMessage<StandardFile> | undefined, b: StandardFile | PlainMessage<StandardFile> | undefined): boolean {
    return proto3.util.equals(StandardFile, a, b);
  }
}

