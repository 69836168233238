// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file constituencies.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available constituency types
 *
 * @generated from enum nail2poll.CONSTITUENCY_TYPE
 */
export enum CONSTITUENCY_TYPE {
  /**
   * Only applicable for search APIs
   *
   * @generated from enum value: CONSTITUENCY_TYPE_ANY_UNSPECIFIED = 0;
   */
  CONSTITUENCY_TYPE_ANY_UNSPECIFIED = 0,

  /**
   * Assembly constituency
   *
   * @generated from enum value: CONSTITUENCY_TYPE_ASSEMBLY = 1;
   */
  CONSTITUENCY_TYPE_ASSEMBLY = 1,

  /**
   * Parliamentary constituency
   *
   * @generated from enum value: CONSTITUENCY_TYPE_PARLIAMENTARY = 2;
   */
  CONSTITUENCY_TYPE_PARLIAMENTARY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CONSTITUENCY_TYPE)
proto3.util.setEnumType(CONSTITUENCY_TYPE, "nail2poll.CONSTITUENCY_TYPE", [
  { no: 0, name: "CONSTITUENCY_TYPE_ANY_UNSPECIFIED" },
  { no: 1, name: "CONSTITUENCY_TYPE_ASSEMBLY" },
  { no: 2, name: "CONSTITUENCY_TYPE_PARLIAMENTARY" },
]);

/**
 *
 * Describes the available sort keys for retrieving constituencies
 *
 * @generated from enum nail2poll.CONSTITUENCY_SORT_KEY
 */
export enum CONSTITUENCY_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_CREATED_AT = 1;
   */
  CONSTITUENCY_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_MODIFIED_AT = 2;
   */
  CONSTITUENCY_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_NAME = 10;
   */
  CONSTITUENCY_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_CODE = 11;
   */
  CONSTITUENCY_SORT_KEY_CODE = 11,

  /**
   * Fetch ordered results by type
   *
   * @generated from enum value: CONSTITUENCY_SORT_KEY_TYPE = 12;
   */
  CONSTITUENCY_SORT_KEY_TYPE = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(CONSTITUENCY_SORT_KEY)
proto3.util.setEnumType(CONSTITUENCY_SORT_KEY, "nail2poll.CONSTITUENCY_SORT_KEY", [
  { no: 0, name: "CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "CONSTITUENCY_SORT_KEY_CREATED_AT" },
  { no: 2, name: "CONSTITUENCY_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "CONSTITUENCY_SORT_KEY_NAME" },
  { no: 11, name: "CONSTITUENCY_SORT_KEY_CODE" },
  { no: 12, name: "CONSTITUENCY_SORT_KEY_TYPE" },
]);

/**
 *
 * Describes the data structure of each constituency on the platform
 *
 * @generated from message nail2poll.Constituency
 */
export class Constituency extends Message<Constituency> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the constituency
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the constituency
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Type of the constituency
   *
   * @generated from field: nail2poll.CONSTITUENCY_TYPE type = 12;
   */
  type = CONSTITUENCY_TYPE.CONSTITUENCY_TYPE_ANY_UNSPECIFIED;

  constructor(data?: PartialMessage<Constituency>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Constituency";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "type", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_TYPE) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Constituency {
    return new Constituency().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Constituency {
    return new Constituency().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Constituency {
    return new Constituency().fromJsonString(jsonString, options);
  }

  static equals(a: Constituency | PlainMessage<Constituency> | undefined, b: Constituency | PlainMessage<Constituency> | undefined): boolean {
    return proto3.util.equals(Constituency, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of constituencies
 *
 * @generated from message nail2poll.ConstituenciesList
 */
export class ConstituenciesList extends Message<ConstituenciesList> {
  /**
   * List of constituencies
   *
   * @generated from field: repeated nail2poll.Constituency list = 1;
   */
  list: Constituency[] = [];

  constructor(data?: PartialMessage<ConstituenciesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Constituency, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesList {
    return new ConstituenciesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesList {
    return new ConstituenciesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesList {
    return new ConstituenciesList().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesList | PlainMessage<ConstituenciesList> | undefined, b: ConstituenciesList | PlainMessage<ConstituenciesList> | undefined): boolean {
    return proto3.util.equals(ConstituenciesList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.ConstituencyPaginationResp
 */
export class ConstituencyPaginationResp extends Message<ConstituencyPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Constituency payload = 3;
   */
  payload: Constituency[] = [];

  constructor(data?: PartialMessage<ConstituencyPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituencyPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Constituency, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituencyPaginationResp {
    return new ConstituencyPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituencyPaginationResp {
    return new ConstituencyPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituencyPaginationResp {
    return new ConstituencyPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituencyPaginationResp | PlainMessage<ConstituencyPaginationResp> | undefined, b: ConstituencyPaginationResp | PlainMessage<ConstituencyPaginationResp> | undefined): boolean {
    return proto3.util.equals(ConstituencyPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a constituency
 *
 * @generated from message nail2poll.ConstituenciesServiceCreateRequest
 */
export class ConstituenciesServiceCreateRequest extends Message<ConstituenciesServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the constituency
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the constituency
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Type of the constituency
   *
   * @generated from field: nail2poll.CONSTITUENCY_TYPE type = 12;
   */
  type = CONSTITUENCY_TYPE.CONSTITUENCY_TYPE_ANY_UNSPECIFIED;

  constructor(data?: PartialMessage<ConstituenciesServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "type", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_TYPE) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesServiceCreateRequest {
    return new ConstituenciesServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesServiceCreateRequest {
    return new ConstituenciesServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesServiceCreateRequest {
    return new ConstituenciesServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesServiceCreateRequest | PlainMessage<ConstituenciesServiceCreateRequest> | undefined, b: ConstituenciesServiceCreateRequest | PlainMessage<ConstituenciesServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(ConstituenciesServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a constituency
 *
 * @generated from message nail2poll.ConstituenciesServiceUpdateRequest
 */
export class ConstituenciesServiceUpdateRequest extends Message<ConstituenciesServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the constituency
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the constituency
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Type of the constituency
   *
   * @generated from field: nail2poll.CONSTITUENCY_TYPE type = 12;
   */
  type = CONSTITUENCY_TYPE.CONSTITUENCY_TYPE_ANY_UNSPECIFIED;

  constructor(data?: PartialMessage<ConstituenciesServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "type", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_TYPE) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesServiceUpdateRequest {
    return new ConstituenciesServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesServiceUpdateRequest {
    return new ConstituenciesServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesServiceUpdateRequest {
    return new ConstituenciesServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesServiceUpdateRequest | PlainMessage<ConstituenciesServiceUpdateRequest> | undefined, b: ConstituenciesServiceUpdateRequest | PlainMessage<ConstituenciesServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(ConstituenciesServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.ConstituenciesServicePaginationReq
 */
export class ConstituenciesServicePaginationReq extends Message<ConstituenciesServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CONSTITUENCY_SORT_KEY sort_key = 5;
   */
  sortKey = CONSTITUENCY_SORT_KEY.CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<ConstituenciesServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesServicePaginationReq {
    return new ConstituenciesServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesServicePaginationReq {
    return new ConstituenciesServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesServicePaginationReq {
    return new ConstituenciesServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesServicePaginationReq | PlainMessage<ConstituenciesServicePaginationReq> | undefined, b: ConstituenciesServicePaginationReq | PlainMessage<ConstituenciesServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(ConstituenciesServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.ConstituenciesServiceFilterReq
 */
export class ConstituenciesServiceFilterReq extends Message<ConstituenciesServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CONSTITUENCY_SORT_KEY sort_key = 5;
   */
  sortKey = CONSTITUENCY_SORT_KEY.CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 10;
   */
  stateUuid = "";

  /**
   * The name of the constituency
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the constituency
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * Type of the constituency
   *
   * @generated from field: nail2poll.CONSTITUENCY_TYPE type = 22;
   */
  type = CONSTITUENCY_TYPE.CONSTITUENCY_TYPE_ANY_UNSPECIFIED;

  constructor(data?: PartialMessage<ConstituenciesServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "type", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_TYPE) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesServiceFilterReq {
    return new ConstituenciesServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesServiceFilterReq {
    return new ConstituenciesServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesServiceFilterReq {
    return new ConstituenciesServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesServiceFilterReq | PlainMessage<ConstituenciesServiceFilterReq> | undefined, b: ConstituenciesServiceFilterReq | PlainMessage<ConstituenciesServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(ConstituenciesServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.ConstituenciesServiceSearchAllReq
 */
export class ConstituenciesServiceSearchAllReq extends Message<ConstituenciesServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CONSTITUENCY_SORT_KEY sort_key = 5;
   */
  sortKey = CONSTITUENCY_SORT_KEY.CONSTITUENCY_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 10;
   */
  stateUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<ConstituenciesServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ConstituenciesServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CONSTITUENCY_SORT_KEY) },
    { no: 10, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConstituenciesServiceSearchAllReq {
    return new ConstituenciesServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConstituenciesServiceSearchAllReq {
    return new ConstituenciesServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConstituenciesServiceSearchAllReq {
    return new ConstituenciesServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: ConstituenciesServiceSearchAllReq | PlainMessage<ConstituenciesServiceSearchAllReq> | undefined, b: ConstituenciesServiceSearchAllReq | PlainMessage<ConstituenciesServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(ConstituenciesServiceSearchAllReq, a, b);
  }
}

