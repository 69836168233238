// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file events.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving events
 *
 * @generated from enum nail2poll.EVENT_SORT_KEY
 */
export enum EVENT_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: EVENT_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  EVENT_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: EVENT_SORT_KEY_CREATED_AT = 1;
   */
  EVENT_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: EVENT_SORT_KEY_MODIFIED_AT = 2;
   */
  EVENT_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: EVENT_SORT_KEY_NAME = 10;
   */
  EVENT_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: EVENT_SORT_KEY_CODE = 11;
   */
  EVENT_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(EVENT_SORT_KEY)
proto3.util.setEnumType(EVENT_SORT_KEY, "nail2poll.EVENT_SORT_KEY", [
  { no: 0, name: "EVENT_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "EVENT_SORT_KEY_CREATED_AT" },
  { no: 2, name: "EVENT_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "EVENT_SORT_KEY_NAME" },
  { no: 11, name: "EVENT_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each event on the platform
 *
 * @generated from message nail2poll.Event
 */
export class Event extends Message<Event> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the event
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the event
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Describe the event
   *
   * @generated from field: string description = 12;
   */
  description = "";

  /**
   * Stores the ID of the event type
   *
   * @generated from field: string event_type_uuid = 13;
   */
  eventTypeUuid = "";

  /**
   * Stores the ID of the user that is conducting the event
   *
   * @generated from field: string conducted_by_user_uuid = 14;
   */
  conductedByUserUuid = "";

  /**
   * Stores the ID of the constituency where the event is being conducted
   *
   * @generated from field: string constituency_uuid = 15;
   */
  constituencyUuid = "";

  /**
   * The latitude of the location where this event was created
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location where this event was created
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Event";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "event_type_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "conducted_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(a: Event | PlainMessage<Event> | undefined, b: Event | PlainMessage<Event> | undefined): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of events
 *
 * @generated from message nail2poll.EventsList
 */
export class EventsList extends Message<EventsList> {
  /**
   * List of events
   *
   * @generated from field: repeated nail2poll.Event list = 1;
   */
  list: Event[] = [];

  constructor(data?: PartialMessage<EventsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsList {
    return new EventsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsList {
    return new EventsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsList {
    return new EventsList().fromJsonString(jsonString, options);
  }

  static equals(a: EventsList | PlainMessage<EventsList> | undefined, b: EventsList | PlainMessage<EventsList> | undefined): boolean {
    return proto3.util.equals(EventsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.EventPaginationResp
 */
export class EventPaginationResp extends Message<EventPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Event payload = 3;
   */
  payload: Event[] = [];

  constructor(data?: PartialMessage<EventPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventPaginationResp {
    return new EventPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventPaginationResp {
    return new EventPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventPaginationResp {
    return new EventPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: EventPaginationResp | PlainMessage<EventPaginationResp> | undefined, b: EventPaginationResp | PlainMessage<EventPaginationResp> | undefined): boolean {
    return proto3.util.equals(EventPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of an event
 *
 * @generated from message nail2poll.EventsServiceCreateRequest
 */
export class EventsServiceCreateRequest extends Message<EventsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the event
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the event
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Describe the event
   *
   * @generated from field: string description = 12;
   */
  description = "";

  /**
   * Stores the ID of the event type
   *
   * @generated from field: string event_type_uuid = 13;
   */
  eventTypeUuid = "";

  /**
   * Stores the ID of the user that is conducting the event
   *
   * @generated from field: string conducted_by_user_uuid = 14;
   */
  conductedByUserUuid = "";

  /**
   * Stores the ID of the constituency where the event is being conducted
   *
   * @generated from field: string constituency_uuid = 15;
   */
  constituencyUuid = "";

  /**
   * The latitude of the location where this event was created
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location where this event was created
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<EventsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "event_type_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "conducted_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsServiceCreateRequest {
    return new EventsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsServiceCreateRequest {
    return new EventsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsServiceCreateRequest {
    return new EventsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EventsServiceCreateRequest | PlainMessage<EventsServiceCreateRequest> | undefined, b: EventsServiceCreateRequest | PlainMessage<EventsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(EventsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on an event
 *
 * @generated from message nail2poll.EventsServiceUpdateRequest
 */
export class EventsServiceUpdateRequest extends Message<EventsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the event
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the event
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Describe the event
   *
   * @generated from field: string description = 12;
   */
  description = "";

  /**
   * Stores the ID of the event type
   *
   * @generated from field: string event_type_uuid = 13;
   */
  eventTypeUuid = "";

  /**
   * Stores the ID of the user that is conducting the event
   *
   * @generated from field: string conducted_by_user_uuid = 14;
   */
  conductedByUserUuid = "";

  /**
   * Stores the ID of the constituency where the event is being conducted
   *
   * @generated from field: string constituency_uuid = 15;
   */
  constituencyUuid = "";

  /**
   * The latitude of the location where this event was created
   *
   * @generated from field: double latitude = 20;
   */
  latitude = 0;

  /**
   * The longitude of the location where this event was created
   *
   * @generated from field: double longitude = 21;
   */
  longitude = 0;

  constructor(data?: PartialMessage<EventsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "event_type_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "conducted_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsServiceUpdateRequest {
    return new EventsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsServiceUpdateRequest {
    return new EventsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsServiceUpdateRequest {
    return new EventsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EventsServiceUpdateRequest | PlainMessage<EventsServiceUpdateRequest> | undefined, b: EventsServiceUpdateRequest | PlainMessage<EventsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(EventsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.EventsServicePaginationReq
 */
export class EventsServicePaginationReq extends Message<EventsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.EVENT_SORT_KEY sort_key = 5;
   */
  sortKey = EVENT_SORT_KEY.EVENT_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<EventsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(EVENT_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsServicePaginationReq {
    return new EventsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsServicePaginationReq {
    return new EventsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsServicePaginationReq {
    return new EventsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: EventsServicePaginationReq | PlainMessage<EventsServicePaginationReq> | undefined, b: EventsServicePaginationReq | PlainMessage<EventsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(EventsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.EventsServiceFilterReq
 */
export class EventsServiceFilterReq extends Message<EventsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.EVENT_SORT_KEY sort_key = 5;
   */
  sortKey = EVENT_SORT_KEY.EVENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * UUID of the event type
   *
   * @generated from field: string event_type_uuid = 10;
   */
  eventTypeUuid = "";

  /**
   * UUID of the user that conducted the event
   *
   * @generated from field: string conducted_by_user_uuid = 11;
   */
  conductedByUserUuid = "";

  /**
   * UUID of the constituency where the event was conducted
   *
   * @generated from field: string constituency_uuid = 12;
   */
  constituencyUuid = "";

  /**
   * The name of the event
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the event
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<EventsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(EVENT_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "event_type_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "conducted_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsServiceFilterReq {
    return new EventsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsServiceFilterReq {
    return new EventsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsServiceFilterReq {
    return new EventsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: EventsServiceFilterReq | PlainMessage<EventsServiceFilterReq> | undefined, b: EventsServiceFilterReq | PlainMessage<EventsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(EventsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.EventsServiceSearchAllReq
 */
export class EventsServiceSearchAllReq extends Message<EventsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.EVENT_SORT_KEY sort_key = 5;
   */
  sortKey = EVENT_SORT_KEY.EVENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * UUID of the event type
   *
   * @generated from field: string event_type_uuid = 10;
   */
  eventTypeUuid = "";

  /**
   * UUID of the user that conducted the event
   *
   * @generated from field: string conducted_by_user_uuid = 11;
   */
  conductedByUserUuid = "";

  /**
   * UUID of the constituency where the event was conducted
   *
   * @generated from field: string constituency_uuid = 12;
   */
  constituencyUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<EventsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.EventsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(EVENT_SORT_KEY) },
    { no: 10, name: "event_type_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "conducted_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventsServiceSearchAllReq {
    return new EventsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventsServiceSearchAllReq {
    return new EventsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventsServiceSearchAllReq {
    return new EventsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: EventsServiceSearchAllReq | PlainMessage<EventsServiceSearchAllReq> | undefined, b: EventsServiceSearchAllReq | PlainMessage<EventsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(EventsServiceSearchAllReq, a, b);
  }
}

