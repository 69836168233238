// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file voters_leaders_sentiments.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { VoterLeaderSentiment, VoterLeaderSentimentPaginationResp, VoterLeaderSentimentsList, VoterLeaderSentimentsServiceCountRequest, VoterLeaderSentimentsServiceCreateRequest, VoterLeaderSentimentsServiceFilterReq, VoterLeaderSentimentsServicePaginationReq, VoterLeaderSentimentsServiceSearchAllReq, VoterLeaderSentimentsServiceUpdateRequest } from "./voters_leaders_sentiments_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each voter leader sentiment
 *
 * @generated from service nail2poll.VoterLeaderSentimentsService
 */
export const VoterLeaderSentimentsService = {
  typeName: "nail2poll.VoterLeaderSentimentsService",
  methods: {
    /**
     * Create a voter leader sentiment
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Create
     */
    create: {
      name: "Create",
      I: VoterLeaderSentimentsServiceCreateRequest,
      O: VoterLeaderSentiment,
      kind: MethodKind.Unary,
    },
    /**
     * Upsert (create or update) a voter leader sentiment
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Upsert
     */
    upsert: {
      name: "Upsert",
      I: VoterLeaderSentimentsServiceCreateRequest,
      O: VoterLeaderSentiment,
      kind: MethodKind.Unary,
    },
    /**
     * Update a voter leader sentiment
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Update
     */
    update: {
      name: "Update",
      I: VoterLeaderSentimentsServiceUpdateRequest,
      O: VoterLeaderSentiment,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the voter leader sentiment
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the voter leader sentiment
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: VoterLeaderSentiment,
      kind: MethodKind.Unary,
    },
    /**
     * View all voter leader sentiments
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: VoterLeaderSentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * View voter leader sentiments with pagination
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: VoterLeaderSentimentsServicePaginationReq,
      O: VoterLeaderSentimentPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: VoterLeaderSentimentsServiceSearchAllReq,
      O: VoterLeaderSentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Filter
     */
    filter: {
      name: "Filter",
      I: VoterLeaderSentimentsServiceFilterReq,
      O: VoterLeaderSentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Count
     */
    count: {
      name: "Count",
      I: VoterLeaderSentimentsServiceCountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.VoterLeaderSentimentsService.Export
     */
    export: {
      name: "Export",
      I: VoterLeaderSentimentsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

