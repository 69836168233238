import { createConnectTransport } from "@connectrpc/connect-web";
import { createPromiseClient, Interceptor, StreamResponse, UnaryResponse } from "@connectrpc/connect";
import { EventsService } from "./structs/events_connect";
import { Event as _event } from "./structs/events_pb";
import { LoginService } from "./structs/logins_connect";
import { HouseholdsService } from "./structs/households_connect";
import { Household } from "./structs/households_pb";
import { VotersService } from "./structs/voters_connect";
import { Voter } from "./structs/voters_pb";
import { PollingStationsService } from "./structs/polling_stations_connect";
import { ReligionsService } from "./structs/religions_connect";
import { ProfessionsService } from "./structs/professions_connect";
import { PartiesService } from "./structs/parties_connect";
import { CastesService } from "./structs/castes_connect";
import { ConstituenciesService } from "./structs/constituencies_connect";
import { DistrictsService } from "./structs/districts_connect";
import { StatesService } from "./structs/states_connect";
import { VoterNotesService } from "./structs/voters_notes_connect";
import { UsersService } from "./structs/users_connect";
import { EventsTypesService } from "./structs/events_types_connect";
import { PollingStation } from "./structs/polling_stations_pb";
import { Religion } from "./structs/religions_pb";
import { Profession } from "./structs/professions_pb";
import { Party } from "./structs/parties_pb";
import { Caste } from "./structs/castes_pb";
import { VoterLeaderSentimentsService } from "./structs/voters_leaders_sentiments_connect";
import { SentimentsService } from "./structs/sentiments_connect";
import { PoliticalLeadersService } from "./structs/political_leaders_connect";
import { PoliticalLeader } from "./structs/political_leaders_pb";
import { Sentiment } from "./structs/sentiments_pb";
import Pikaday = require("pikaday");
import papaparse = require("papaparse");
import { User } from "./structs/users_pb";

/**Stores the ID of the section that will be used to render all the content */
export const primaryBodyID = "primary-body";

/** Returns the auth_token after reading it from the cookie */
export function getAuth(): string {
    let name = "auth_token=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Logs the user out of the application
export function logout() {
    removeCookie("auth_token");
    getLoginServiceClient().logout({}).then(() => {
        location.href = "/login";
    });
}

// Fetches the cookie with the provided cookie name
function getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**Sets the cookieVal to the cookieName with the expiry set to the given number of expiryDays */
export function setCookie(cookieName: string, cookieVal: string, expiryDays: number) {
    var d = new Date();
    d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieVal + ";" + expires + ";path=/";
}

// Removes the cookie with the provided cookie name
function removeCookie(cname: string) {
    document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**Displays an alert */
export function displayAlert(title: string, content: string) {
    window.alert(title + '\n' + content);
}

export function returnHeaderWithLogoutSection() {
    return `
    <section class="hero is-link">
        <div class="hero-body">
            <a href="/ui/dash" class="title">
                Nail2Poll
            </a>
            <button id="logout" class="button" style="float: right;">Logout</button>
        </div>
    </section>
    `
}

export function getPrimaryBodySection() {
    const section = document.createElement("section");
    section.classList.add("section");
    section.id = primaryBodyID;
    return section
}

export function configureLogoutButton() {
    document.getElementById("logout").addEventListener("click", async evt => {
        evt.preventDefault();
        logout();
    });
}

/**Returns a random ID to assign to an HTML element */
export function returnRandomIDForHTMLElement(): string {
    return Math.random().toString(36).substring(2, 15);
}

export function resetPageEssentials() {
    let primaryBody = document.getElementById(primaryBodyID);
    if (primaryBody === null || primaryBody === undefined) {
        primaryBody = getPrimaryBodySection();
        // New reload
        document.body.innerHTML = `
            ${returnHeaderWithLogoutSection()}
            ${primaryBody.outerHTML}
        `;
        primaryBody = document.getElementById(primaryBodyID);
        configureLogoutButton();
    };
    return primaryBody
}

// appendAuthToken is an interceptor that appends the auth token to the request prior to sending the request
const appendAuthToken: Interceptor = (next) => async (req) => {
    req.header.append("auth_token", getAuth());
    return await next(req).catch(err => {
        if (err) {
            displayAlert("Error", "Something went wrong");
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

function getTransport() {
    return createConnectTransport({
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken
        ]
    });
}

export function getLoginServiceClient() {
    return createPromiseClient(LoginService, getTransport());
}

export function getReligionsServiceReadClient() {
    return createPromiseClient(ReligionsService, getTransport());
}

export function getProfessionsServiceReadClient() {
    return createPromiseClient(ProfessionsService, getTransport());
}

export function getPartiesServiceReadClient() {
    return createPromiseClient(PartiesService, getTransport());
}

export function getCastesServiceReadClient() {
    return createPromiseClient(CastesService, getTransport());
}

export function getConstituenciesServiceReadClient() {
    return createPromiseClient(ConstituenciesService, getTransport());
}

export function getDistrictsServiceReadClient() {
    return createPromiseClient(DistrictsService, getTransport());
}

export function getSentimentsServiceReadClient() {
    return createPromiseClient(SentimentsService, getTransport());
}

export function getPoliticalLeadersServiceReadClient() {
    return createPromiseClient(PoliticalLeadersService, getTransport());
}

export function getStatesServiceReadClient() {
    return createPromiseClient(StatesService, getTransport());
}

export function getPollingStationsServiceReadClient() {
    return createPromiseClient(PollingStationsService, getTransport());
}

export function getEventsServiceReadClient() {
    return createPromiseClient(EventsService, getTransport());
}

export function getEventsTypesServiceReadClient() {
    return createPromiseClient(EventsTypesService, getTransport());
}

export function getHouseholdsServiceReadClient() {
    return createPromiseClient(HouseholdsService, getTransport());
}

export function getVotersServiceReadClient() {
    return createPromiseClient(VotersService, getTransport());
}

export function getVotersNotesServiceReadClient() {
    return createPromiseClient(VoterNotesService, getTransport());
}

export function getVotersNotesServiceWriteClient() {
    return createPromiseClient(VoterNotesService, getTransport());
}

export function getVotersLeadersSentimentsServiceReadClient() {
    return createPromiseClient(VoterLeaderSentimentsService, getTransport());
}

export function getVotersLeadersSentimentsServiceWriteClient() {
    return createPromiseClient(VoterLeaderSentimentsService, getTransport());
}

export function getUsersServiceReadClient() {
    return createPromiseClient(UsersService, getTransport());
}

export function getUsersServiceWriteClient() {
    return createPromiseClient(UsersService, getTransport());
}

export async function getPollingStationsMap(): Promise<Map<string, PollingStation>> {
    const client = getPollingStationsServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, PollingStation> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getReligionsMap(): Promise<Map<string, Religion>> {
    const client = getReligionsServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, Religion> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getProfessionsMap(): Promise<Map<string, Profession>> {
    const client = getProfessionsServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, Profession> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getPartiesMap(): Promise<Map<string, Party>> {
    const client = getPartiesServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, Party> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getCastesMap(): Promise<Map<string, Caste>> {
    const client = getCastesServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, Caste> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getPoliticalLeadersMap(): Promise<Map<string, PoliticalLeader>> {
    const client = getPoliticalLeadersServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, PoliticalLeader> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getSentimentsMap(): Promise<Map<string, Sentiment>> {
    const client = getSentimentsServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, Sentiment> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

export async function getUsersMap(): Promise<Map<string, User>> {
    const client = getUsersServiceReadClient();
    let records = await client.viewAll({isActive: true});
    let m: Map<string, User> = new Map();
    records.list.forEach(record => {
        m.set(record.metadata.uuid, record);
    });
    return m
}

/**Sets up Pikaday on date fields */
export function setupDateFields(dateInputClassName: string) {
    let dateFields = document.getElementsByClassName(dateInputClassName);
    for (let i = 0; i < dateFields.length; i++) {
        new Pikaday({
            field: <HTMLElement>dateFields[i],
            toString(date: Date, format: string) {
                return `${date.toDateString()}`;
            },
        });
    }
}

/**Returns the driving directions on Google Maps using the given latitude and longitude */
export function renderGoogleMapsDrivingDirectionsLink(lat: string, lng: string): string {
    let latNum = parseInt(lat);
    let lngNum = parseInt(lng);

    if (isNaN(latNum) || isNaN(lngNum)) {
        return `-`;
    }

    if (latNum != -270 && lngNum != -270) {
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
    }
    return `-`;
}

export async function setupGoogleMaps() {
    let mapsSrc = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBgZRuEkyLqXFigdKtHN4gmtZrGMxaW16A&v=weekly";
    let allBodyScripts = document.getElementsByTagName("script");
    for (let i = 0; i < allBodyScripts.length; i++) {
        if (allBodyScripts[i].src == mapsSrc) {
            // Google Maps has already been loaded
            return;
        }
    }

    let googleMapsScript = document.createElement("script");
    googleMapsScript.src = mapsSrc;
    googleMapsScript.async = true;
    document.body.appendChild(googleMapsScript);
    return new Promise((resolve, reject) => {
        googleMapsScript.addEventListener("load", () => {
            return resolve("");
        });
    });
}

export function initialiseGoogleMaps(centerLat: number, centerLong: number, mapID: string) {
    let map = new google.maps.Map(
        document.getElementById(mapID) as HTMLElement,
        {
            zoom: 11,
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: { lat: centerLat, lng: centerLong },
            styles: [
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                        { visibility: "off" }
                    ]
                }
            ]
        }
    );
    return map;
}

let allOpenInfoWindows = <google.maps.InfoWindow[]>[];
export function createEventMarker(googleMap: google.maps.Map, params: {
    event: _event,
    markerColor: "green" | "red" | "blue"
}) {
    const marker = new google.maps.Marker({
        position: { lat: params.event.latitude, lng: params.event.longitude },
        map: googleMap,
        // title: params.title,
        // label: params.title

    });
    marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${params.markerColor}-dot.png`)

    marker.addListener("click", () => {
        for (let i = 0; i < allOpenInfoWindows.length; i++) {
            try {
                allOpenInfoWindows[i].close();
            } catch (e) { }
        }

        // map.setZoom(15);
        // googleMap.setCenter(marker.getPosition() as google.maps.LatLng);

        let infowindow = new google.maps.InfoWindow({
            content: `<div class='marker-content'>${params.event.name}</div>`,
        });

        infowindow.open({
            anchor: marker,
            map: googleMap,
            shouldFocus: false,
        });
        allOpenInfoWindows.push(infowindow);

        infowindow.addListener('closeclick', () => {
            // Handle focus manually.
            infowindow.close();
            // map.setZoom(12);
        });
    });
}

export function createHouseholdMarker(googleMap: google.maps.Map, params: {
    household: Household,
    markerColor: "green" | "red" | "blue"
}) {
    const marker = new google.maps.Marker({
        position: { lat: params.household.latitude, lng: params.household.longitude },
        map: googleMap,
        // title: params.title,
        // label: params.title

    });
    marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${params.markerColor}-dot.png`)

    marker.addListener("click", () => {
        for (let i = 0; i < allOpenInfoWindows.length; i++) {
            try {
                allOpenInfoWindows[i].close();
            } catch (e) { }
        }

        // map.setZoom(15);
        // googleMap.setCenter(marker.getPosition() as google.maps.LatLng);

        let infowindow = new google.maps.InfoWindow({
            content: `<div class='marker-content'>${params.household.name}</div>`,
        });

        infowindow.open({
            anchor: marker,
            map: googleMap,
            shouldFocus: false,
        });
        allOpenInfoWindows.push(infowindow);

        infowindow.addListener('closeclick', () => {
            // Handle focus manually.
            infowindow.close();
            // map.setZoom(12);
        });
    });
}



/** Returns the date from UNIX timestamp. This is needed when the timestamp is encoded as a bigint */
export function convertBigIntTimestampToDateTime(timestamp: bigint): string {
    let parsedTimestamp = parseInt(String(timestamp));
    if (parsedTimestamp == 0) {
        return "-";
    }
    let dt = new Date(parsedTimestamp * 1000);
    return dt.toDateString() + " " + dt.toLocaleTimeString();
}

/**Returns the date from UNIX timestamp */
export function convertBigIntTimestampToDate(timestamp: bigint): string {
    let parsedTimestamp = parseInt(String(timestamp));
    if (parsedTimestamp == 0) {
        return "-";
    }
    let dt = new Date(parsedTimestamp * 1000);
    return dt.toDateString();
}

export function createPhoneNumberLink(phone: string) {
    return `<a target="_blank" href="tel:+91${phone}">${phone}</a>`
}

/**Generates the table */
export function generateTable(title: string, headers: string[], rows: string[][], elementID: string) {
    let parentDiv = document.createElement("div");
    let tableTitle = document.createElement("h3");
    tableTitle.classList.add("table-title");
    tableTitle.innerText = title;
    parentDiv.appendChild(tableTitle);

    let div = document.createElement("div");
    div.classList.add("table-container");
    let table = document.createElement("table");
    table.className = "table is-bordered is-striped is-narrow is-hoverable is-fullwidth";

    let thead = document.createElement("thead");
    let theadRow = document.createElement("tr");
    headers.forEach(header => {
        let th = document.createElement("th");
        th.innerText = header;
        theadRow.appendChild(th);
    });
    thead.appendChild(theadRow);

    table.appendChild(thead);

    let tbody = document.createElement("tbody");
    rows.forEach(row => {
        let tr = document.createElement("tr");
        row.forEach(rowDatum => {
            let td = document.createElement("td");
            td.innerHTML = rowDatum;
            if (rowDatum.toString().indexOf("button") > -1) {
                td.style.textAlign = "center";
            }
            tr.appendChild(td);
        });
        tbody.appendChild(tr);
    });

    table.appendChild(tbody);

    div.appendChild(table);
    parentDiv.appendChild(div);
    document.getElementById(elementID).appendChild(parentDiv);
    document.getElementById(elementID).appendChild(document.createElement("br"));
}

/**Returns a submit button */
export function getSubmitButton() {
    let submitButton = document.createElement("button");
    submitButton.className = "button is-medium is-fullwidth is-responsive is-link";
    submitButton.style.margin = "10px 0px";
    submitButton.innerText = "Submit";
    return submitButton;
}

/**Returns the download button that initiates the download of records */
export function getDownloadButton() {
    let downloadButton = document.createElement("button");
    downloadButton.className = "button is-medium is-responsive is-link";
    downloadButton.style.margin = "10px 0px";
    downloadButton.innerText = "Download";
    return downloadButton;
}

let allOpenSidebars = <HTMLDivElement[]>[];

/**Displays the sidebar */
export function displaySidebar(titleContent: string): {
    sidebar: HTMLDivElement,
    contentID: string
} {
    let sidebar = document.createElement("div");
    sidebar.classList.add("section");
    sidebar.classList.add("sidebar");

    document.body.appendChild(sidebar);

    let topSection = document.createElement("div");
    topSection.classList.add("column");
    
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = titleContent;
    topSection.appendChild(title);

    let closeButton = document.createElement("button");
    closeButton.className = "delete";
    closeButton.innerText = "X";
    closeButton.style.backgroundColor = "#DC143C";
    closeButton.style.position = "absolute";
    closeButton.style.top = "10px";
    closeButton.style.left = "10px";
    // Attach the close button too
    topSection.appendChild(closeButton);

    sidebar.appendChild(topSection);

    let contentSection = document.createElement("div");
    contentSection.id = returnRandomIDForHTMLElement();
    contentSection.classList.add("column");
    sidebar.appendChild(contentSection);

    closeButton.addEventListener("click", evt => {
        evt.preventDefault();
        sidebar.parentElement.removeChild(sidebar);
    });

    allOpenSidebars.push(sidebar);

    return  { sidebar, contentID: contentSection.id }
}

export function closeAllOpenSidebars() {
    for (let i = 0; i < allOpenSidebars.length; i++) {
        try {
            allOpenSidebars[i].parentElement.removeChild(allOpenSidebars[i]);
        } catch (e) {}
    }
}

export function closeSpecificSidebar(sidebar: HTMLDivElement) {
    try {
        sidebar.parentElement.removeChild(sidebar);
    } catch (e) {}
}

/**Displays a notification */
export function renderNotification(content: string) {
    let notification = document.createElement("div");
    notification.className = "notification is-primary";
    notification.style.zIndex = "5000";
    notification.style.position = "fixed";
    notification.style.top = "20px";
    notification.style.right = "20px";
    let buttonID = returnRandomIDForHTMLElement();
    notification.innerHTML = `
        <button id="${buttonID}" class="delete"></button>
        ${content}
    `;    
    document.body.appendChild(notification);

    document.getElementById(buttonID).addEventListener("click", evt => {
        evt.preventDefault();
        try {
            notification.parentElement.removeChild(notification);
        } catch (e) {}
    });

    setTimeout(() => {
        try {
            notification.parentElement.removeChild(notification);
        } catch (e) {}
    }, 5000);
}

/**Returns the WhatsApp link for the given number */
export function renderWhatsAppLink(mobile: string) {
    if (mobile.length == 0) {
        return mobile;
    }
    return `<a target="_blank" href="https://web.whatsapp.com/send?phone=${encodeURIComponent(mobile)}">${mobile} <img style="position: relative; top: 5px; width: 25px; height: auto;" src="/static/dist/img/WhatsAppIcon.webp"></a>`;
}

/**Renders the modal */
export function renderVoterNoteModal(modalTitle: string, noteTitle: string, noteContent: string, callback: (title: string, content: string) => Promise<void>) {
    let div = document.createElement("div");
    div.classList.add("modal");
    div.classList.add("is-active");

    const cancelButtonID = returnRandomIDForHTMLElement();
    const submitButtonID = returnRandomIDForHTMLElement();

    const titleInputID = returnRandomIDForHTMLElement();
    const contentInputID = returnRandomIDForHTMLElement();

    div.innerHTML = `
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" style="text-align: center;">${modalTitle}</p>
            </header>
            <section class="modal-card-body">
                <input id="${titleInputID}" class="input is-primary" placeholder="Title" style="margin-bottom: 10px;" value="${noteTitle}">
                <textarea id="${contentInputID}" class="textarea is-primary" placeholder="Content">${noteContent}</textarea>
            </section>
            <footer class="modal-card-foot"">
                <button class="button is-success" id="${submitButtonID}">Save changes</button>
                <button class="button" id="${cancelButtonID}">Cancel</button>
            </footer>
        </div>
    `

    document.body.appendChild(div);

    const cancelButton = document.getElementById(cancelButtonID);
    cancelButton.addEventListener("click", evt => {
        evt.preventDefault();
        cancelButton.parentElement.parentElement.parentElement.parentElement.removeChild(cancelButton.parentElement.parentElement.parentElement);
    });

    const submitButton = document.getElementById(submitButtonID);
    submitButton.addEventListener("click", async evt => {
        evt.preventDefault();

        const titleInputVal = (<HTMLInputElement>document.getElementById(titleInputID)).value;
        const contentInputVal = (<HTMLTextAreaElement>document.getElementById(contentInputID)).value;
        if (titleInputVal.length == 0) {
            return renderNotification("Title is mandatory");
        }
        if (contentInputVal.length == 0) {
            return renderNotification("Content is mandatory");
        }

        callback(titleInputVal, contentInputVal);
        cancelButton.dispatchEvent(new Event("click"));
    });

    return div
}

function downloadData(content: any, exportType: "csv", fileNameWithoutExtension: string) {
    let a = document.createElement('a');
    if (exportType == "csv") {
        a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content);
        a.download = `${fileNameWithoutExtension}.csv`;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// Download the given headers and rows as a CSV with the given file name
export function downloadAsCSV(headers: string[], rows: string[][], fileNameWithoutExtension: string) {
    let allRows = [];
    allRows.push(headers);
    rows.forEach(row => {
        allRows.push(row);
    });
    let csv = papaparse.unparse(allRows);
    downloadData(csv, "csv", fileNameWithoutExtension);
}

export const TDP_COLOR = "#fed41b";
export const POSITIVE_COLOR = TDP_COLOR;