// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file professions.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving professions
 *
 * @generated from enum nail2poll.PROFESSION_SORT_KEY
 */
export enum PROFESSION_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: PROFESSION_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  PROFESSION_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: PROFESSION_SORT_KEY_CREATED_AT = 1;
   */
  PROFESSION_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: PROFESSION_SORT_KEY_MODIFIED_AT = 2;
   */
  PROFESSION_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: PROFESSION_SORT_KEY_NAME = 10;
   */
  PROFESSION_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: PROFESSION_SORT_KEY_CODE = 11;
   */
  PROFESSION_SORT_KEY_CODE = 11,

  /**
   * Fetch ordered results by sub profession
   *
   * @generated from enum value: PROFESSION_SORT_KEY_SUB_PROFESSION = 12;
   */
  PROFESSION_SORT_KEY_SUB_PROFESSION = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(PROFESSION_SORT_KEY)
proto3.util.setEnumType(PROFESSION_SORT_KEY, "nail2poll.PROFESSION_SORT_KEY", [
  { no: 0, name: "PROFESSION_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "PROFESSION_SORT_KEY_CREATED_AT" },
  { no: 2, name: "PROFESSION_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "PROFESSION_SORT_KEY_NAME" },
  { no: 11, name: "PROFESSION_SORT_KEY_CODE" },
  { no: 12, name: "PROFESSION_SORT_KEY_SUB_PROFESSION" },
]);

/**
 *
 * Describes the data structure of each profession on the platform
 *
 * @generated from message nail2poll.Profession
 */
export class Profession extends Message<Profession> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the profession
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the profession
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub profession
   *
   * @generated from field: string sub_profession = 12;
   */
  subProfession = "";

  constructor(data?: PartialMessage<Profession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Profession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_profession", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profession {
    return new Profession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profession {
    return new Profession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profession {
    return new Profession().fromJsonString(jsonString, options);
  }

  static equals(a: Profession | PlainMessage<Profession> | undefined, b: Profession | PlainMessage<Profession> | undefined): boolean {
    return proto3.util.equals(Profession, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of professions
 *
 * @generated from message nail2poll.ProfessionsList
 */
export class ProfessionsList extends Message<ProfessionsList> {
  /**
   * List of professions
   *
   * @generated from field: repeated nail2poll.Profession list = 1;
   */
  list: Profession[] = [];

  constructor(data?: PartialMessage<ProfessionsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Profession, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsList {
    return new ProfessionsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsList {
    return new ProfessionsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsList {
    return new ProfessionsList().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsList | PlainMessage<ProfessionsList> | undefined, b: ProfessionsList | PlainMessage<ProfessionsList> | undefined): boolean {
    return proto3.util.equals(ProfessionsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.ProfessionPaginationResp
 */
export class ProfessionPaginationResp extends Message<ProfessionPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Profession payload = 3;
   */
  payload: Profession[] = [];

  constructor(data?: PartialMessage<ProfessionPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Profession, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionPaginationResp {
    return new ProfessionPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionPaginationResp {
    return new ProfessionPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionPaginationResp {
    return new ProfessionPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionPaginationResp | PlainMessage<ProfessionPaginationResp> | undefined, b: ProfessionPaginationResp | PlainMessage<ProfessionPaginationResp> | undefined): boolean {
    return proto3.util.equals(ProfessionPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a profession
 *
 * @generated from message nail2poll.ProfessionsServiceCreateRequest
 */
export class ProfessionsServiceCreateRequest extends Message<ProfessionsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the profession
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the profession
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub profession
   *
   * @generated from field: string sub_profession = 12;
   */
  subProfession = "";

  constructor(data?: PartialMessage<ProfessionsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_profession", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsServiceCreateRequest {
    return new ProfessionsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsServiceCreateRequest {
    return new ProfessionsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsServiceCreateRequest {
    return new ProfessionsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsServiceCreateRequest | PlainMessage<ProfessionsServiceCreateRequest> | undefined, b: ProfessionsServiceCreateRequest | PlainMessage<ProfessionsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(ProfessionsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a profession
 *
 * @generated from message nail2poll.ProfessionsServiceUpdateRequest
 */
export class ProfessionsServiceUpdateRequest extends Message<ProfessionsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the profession
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the profession
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub profession
   *
   * @generated from field: string sub_profession = 12;
   */
  subProfession = "";

  constructor(data?: PartialMessage<ProfessionsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_profession", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsServiceUpdateRequest {
    return new ProfessionsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsServiceUpdateRequest {
    return new ProfessionsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsServiceUpdateRequest {
    return new ProfessionsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsServiceUpdateRequest | PlainMessage<ProfessionsServiceUpdateRequest> | undefined, b: ProfessionsServiceUpdateRequest | PlainMessage<ProfessionsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(ProfessionsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.ProfessionsServicePaginationReq
 */
export class ProfessionsServicePaginationReq extends Message<ProfessionsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PROFESSION_SORT_KEY sort_key = 5;
   */
  sortKey = PROFESSION_SORT_KEY.PROFESSION_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<ProfessionsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PROFESSION_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsServicePaginationReq {
    return new ProfessionsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsServicePaginationReq {
    return new ProfessionsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsServicePaginationReq {
    return new ProfessionsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsServicePaginationReq | PlainMessage<ProfessionsServicePaginationReq> | undefined, b: ProfessionsServicePaginationReq | PlainMessage<ProfessionsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(ProfessionsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.ProfessionsServiceFilterReq
 */
export class ProfessionsServiceFilterReq extends Message<ProfessionsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PROFESSION_SORT_KEY sort_key = 5;
   */
  sortKey = PROFESSION_SORT_KEY.PROFESSION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the profession
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the profession
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * Sub profession
   *
   * @generated from field: string sub_profession = 22;
   */
  subProfession = "";

  constructor(data?: PartialMessage<ProfessionsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PROFESSION_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "sub_profession", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsServiceFilterReq {
    return new ProfessionsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsServiceFilterReq {
    return new ProfessionsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsServiceFilterReq {
    return new ProfessionsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsServiceFilterReq | PlainMessage<ProfessionsServiceFilterReq> | undefined, b: ProfessionsServiceFilterReq | PlainMessage<ProfessionsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(ProfessionsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.ProfessionsServiceSearchAllReq
 */
export class ProfessionsServiceSearchAllReq extends Message<ProfessionsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PROFESSION_SORT_KEY sort_key = 5;
   */
  sortKey = PROFESSION_SORT_KEY.PROFESSION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<ProfessionsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ProfessionsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PROFESSION_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfessionsServiceSearchAllReq {
    return new ProfessionsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfessionsServiceSearchAllReq {
    return new ProfessionsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfessionsServiceSearchAllReq {
    return new ProfessionsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProfessionsServiceSearchAllReq | PlainMessage<ProfessionsServiceSearchAllReq> | undefined, b: ProfessionsServiceSearchAllReq | PlainMessage<ProfessionsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(ProfessionsServiceSearchAllReq, a, b);
  }
}

