import { Party } from "../structs/parties_pb";
import { VotersServiceCountRequest, VotersServiceGroupByResponse, VotersServiceGroupByResponseList } from "../structs/voters_pb";
import * as utilities from "../utilities";
import { Grid } from "gridjs";
import * as echarts from 'echarts';


/**Render all the statistics relevant to parties */
export async function renderPartyStatistics(
    primaryBody: HTMLElement, countReq: VotersServiceCountRequest, partiesMap: Map<string, Party>
) {
    let section = document.createElement("section");
    section.classList.add("statistics-section");
    primaryBody.appendChild(section);

    const votersClient = utilities.getVotersServiceReadClient();
    const groupByParty = await votersClient.groupByParty(countReq);
    await Promise.all([
        renderPartyStatisticsInTable(section, groupByParty.list, partiesMap),
        renderBarChart(section, groupByParty.list, partiesMap),
        renderPieChart(section, groupByParty.list, partiesMap)
    ]);

    if (section.childElementCount == 0) {
        section.parentElement.removeChild(section);
    }
}

// Render table
async function renderPartyStatisticsInTable(
    section: HTMLElement, groups: VotersServiceGroupByResponse[], partiesMap: Map<string, Party>
) {
    if (groups.length == 0) {
        return
    }
    let tableDiv = document.createElement("div");
    tableDiv.classList.add("columns");
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = "Parties Distribution";
    section.appendChild(title);

    section.appendChild(tableDiv);

    const grid = new Grid({
        columns: [
            "S.No.", "Party Name", "Party Code", "Count",
        ],
        sort: true,
        fixedHeader: true,
        resizable: true,
        search: true,
        height: '50vh',
        width: '80vw',
        data: groups.map((record, i) => {
            return [
                i + 1, partiesMap.get(record.uuid).name, partiesMap.get(record.uuid).code, record.count,
            ]
        })
    });
    grid.render(tableDiv);
}

// Render bar chart
async function renderBarChart(
    section: HTMLElement, groups: VotersServiceGroupByResponse[], partiesMap: Map<string, Party>
) {
    if (groups.length == 0) {
        return
    }
    let chartDom = document.createElement('div');
    chartDom.style.width = "100vw";
    chartDom.style.height = "50vh";
    section.appendChild(chartDom);
    let myChart = echarts.init(chartDom);
    let option: echarts.EChartsOption = {
        xAxis: {
            type: 'category',
            data: groups.map(g => partiesMap.get(g.uuid).name)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: groups.map(g => parseInt(String(g.count))),
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }
        ],
        tooltip: {
            showContent: true,
        }
    };

    myChart.setOption(option);
}

interface extendedGroupByResponse {
    group: VotersServiceGroupByResponse
    color: string
}

// Render pie chart
async function renderPieChart(
    section: HTMLElement, groups: VotersServiceGroupByResponse[], partiesMap: Map<string, Party>
) {
    if (groups.length == 0) {
        return
    }
    let colorPalette = [];
    groups.forEach(g => {
        if (partiesMap.get(g.uuid).code.toLowerCase().indexOf("tdp") > -1) {
            colorPalette.push(utilities.TDP_COLOR);
        } else {
            colorPalette.push('#'+(Math.random()*0xFFFFFF<<0).toString(16));
        }
    });

    let chartDom = document.createElement('div');
    chartDom.style.width = "100vw";
    chartDom.style.height = "70vh";
    section.appendChild(chartDom);
    let myChart = echarts.init(chartDom);
    let option: echarts.EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    position: 'inside',
                    formatter: '{d}%',
                    // color: 'black',
                    fontSize: 16
                  },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: groups.map(g => {
                    return { value: parseInt(String(g.count)), name: partiesMap.get(g.uuid).name }
                })
            }
        ],
        color: colorPalette,
    };

    myChart.setOption(option);
}