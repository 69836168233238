// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file users.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { DEVICE_TYPE, Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving users
 *
 * @generated from enum nail2poll.USER_SORT_KEY
 */
export enum USER_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: USER_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  USER_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: USER_SORT_KEY_CREATED_AT = 1;
   */
  USER_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: USER_SORT_KEY_MODIFIED_AT = 2;
   */
  USER_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by first name
   *
   * @generated from enum value: USER_SORT_KEY_FIRST_NAME = 10;
   */
  USER_SORT_KEY_FIRST_NAME = 10,

  /**
   * Fetch ordered results by last name
   *
   * @generated from enum value: USER_SORT_KEY_LAST_NAME = 11;
   */
  USER_SORT_KEY_LAST_NAME = 11,

  /**
   * Fetch ordered results by username
   *
   * @generated from enum value: USER_SORT_KEY_USERNAME = 12;
   */
  USER_SORT_KEY_USERNAME = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(USER_SORT_KEY)
proto3.util.setEnumType(USER_SORT_KEY, "nail2poll.USER_SORT_KEY", [
  { no: 0, name: "USER_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "USER_SORT_KEY_CREATED_AT" },
  { no: 2, name: "USER_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "USER_SORT_KEY_FIRST_NAME" },
  { no: 11, name: "USER_SORT_KEY_LAST_NAME" },
  { no: 12, name: "USER_SORT_KEY_USERNAME" },
]);

/**
 *
 * Stores the basic information of a user
 *
 * @generated from message nail2poll.UserBasic
 */
export class UserBasic extends Message<UserBasic> {
  /**
   * The first name of the user
   *
   * @generated from field: string first_name = 10;
   */
  firstName = "";

  /**
   * The optional last name of the user
   *
   * @generated from field: string last_name = 11;
   */
  lastName = "";

  /**
   * The optional email address of the user
   *
   * @generated from field: string email = 12;
   */
  email = "";

  /**
   * The optional phone number of the user
   *
   * @generated from field: string phone = 13;
   */
  phone = "";

  /**
   * The optional date of birth of the user
   *
   * @generated from field: string date_of_birth = 16;
   */
  dateOfBirth = "";

  /**
   * The address of the user
   *
   * @generated from field: string address = 18;
   */
  address = "";

  constructor(data?: PartialMessage<UserBasic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UserBasic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserBasic {
    return new UserBasic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserBasic {
    return new UserBasic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserBasic {
    return new UserBasic().fromJsonString(jsonString, options);
  }

  static equals(a: UserBasic | PlainMessage<UserBasic> | undefined, b: UserBasic | PlainMessage<UserBasic> | undefined): boolean {
    return proto3.util.equals(UserBasic, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a user
 *
 * @generated from message nail2poll.UsersServiceCreateRequest
 */
export class UsersServiceCreateRequest extends Message<UsersServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The first name of the user
   *
   * @generated from field: string first_name = 10;
   */
  firstName = "";

  /**
   * The last name of the user
   *
   * @generated from field: string last_name = 11;
   */
  lastName = "";

  /**
   * Username of the user
   *
   * @generated from field: string username = 12;
   */
  username = "";

  /**
   * The email address of the contact
   *
   * [(buf.validate.field).string.email = true];
   *
   * @generated from field: string email = 13;
   */
  email = "";

  /**
   * The optional phone number of the contact
   *
   * @generated from field: string phone = 14;
   */
  phone = "";

  /**
   * The optional date of birth of the contact
   *
   * @generated from field: string date_of_birth = 17;
   */
  dateOfBirth = "";

  /**
   * The address of the user
   *
   * @generated from field: string address = 18;
   */
  address = "";

  /**
   * The password with which the user can login for the first time
   *
   * @generated from field: string plain_text_password = 19;
   */
  plainTextPassword = "";

  constructor(data?: PartialMessage<UsersServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceCreateRequest {
    return new UsersServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceCreateRequest {
    return new UsersServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceCreateRequest {
    return new UsersServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceCreateRequest | PlainMessage<UsersServiceCreateRequest> | undefined, b: UsersServiceCreateRequest | PlainMessage<UsersServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(UsersServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a user
 *
 * @generated from message nail2poll.UsersServiceUpdateRequest
 */
export class UsersServiceUpdateRequest extends Message<UsersServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the user that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The first name of the user
   *
   * @generated from field: string first_name = 10;
   */
  firstName = "";

  /**
   * The last name of the user
   *
   * // Username of the user
   * string username = 12 [(buf.validate.field).string = {
   *     min_bytes: 1,
   *     max_bytes: 40,
   * }];
   *
   * @generated from field: string last_name = 11;
   */
  lastName = "";

  /**
   * The email address of the contact
   *
   * [(buf.validate.field).string.email = true];
   *
   * @generated from field: string email = 13;
   */
  email = "";

  /**
   * The optional phone number of the contact
   *
   * @generated from field: string phone = 14;
   */
  phone = "";

  /**
   * The optional date of birth of the contact
   *
   * @generated from field: string date_of_birth = 17;
   */
  dateOfBirth = "";

  /**
   * The address of the user
   *
   * @generated from field: string address = 18;
   */
  address = "";

  constructor(data?: PartialMessage<UsersServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceUpdateRequest {
    return new UsersServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceUpdateRequest {
    return new UsersServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceUpdateRequest {
    return new UsersServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceUpdateRequest | PlainMessage<UsersServiceUpdateRequest> | undefined, b: UsersServiceUpdateRequest | PlainMessage<UsersServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(UsersServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to update a user's password
 *
 * @generated from message nail2poll.UsersServiceUpdatePasswordRequest
 */
export class UsersServiceUpdatePasswordRequest extends Message<UsersServiceUpdatePasswordRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the user that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The old login password
   *
   * @generated from field: string old_plain_text_password = 3;
   */
  oldPlainTextPassword = "";

  /**
   * The new password
   *
   * @generated from field: string plain_text_password = 4;
   */
  plainTextPassword = "";

  constructor(data?: PartialMessage<UsersServiceUpdatePasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceUpdatePasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "old_plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceUpdatePasswordRequest {
    return new UsersServiceUpdatePasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceUpdatePasswordRequest {
    return new UsersServiceUpdatePasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceUpdatePasswordRequest {
    return new UsersServiceUpdatePasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceUpdatePasswordRequest | PlainMessage<UsersServiceUpdatePasswordRequest> | undefined, b: UsersServiceUpdatePasswordRequest | PlainMessage<UsersServiceUpdatePasswordRequest> | undefined): boolean {
    return proto3.util.equals(UsersServiceUpdatePasswordRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to register a device against a user to send push notifications
 *
 * @generated from message nail2poll.UsersServiceRegisterUserDeviceRequest
 */
export class UsersServiceRegisterUserDeviceRequest extends Message<UsersServiceRegisterUserDeviceRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the user that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The device type
   *
   * @generated from field: nail2poll.DEVICE_TYPE device_type = 10;
   */
  deviceType = DEVICE_TYPE.WEB_UNSPECIFIED;

  /**
   * The ID of the device
   *
   * @generated from field: string device_id = 11;
   */
  deviceId = "";

  constructor(data?: PartialMessage<UsersServiceRegisterUserDeviceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceRegisterUserDeviceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "device_type", kind: "enum", T: proto3.getEnumType(DEVICE_TYPE) },
    { no: 11, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceRegisterUserDeviceRequest {
    return new UsersServiceRegisterUserDeviceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceRegisterUserDeviceRequest {
    return new UsersServiceRegisterUserDeviceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceRegisterUserDeviceRequest {
    return new UsersServiceRegisterUserDeviceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceRegisterUserDeviceRequest | PlainMessage<UsersServiceRegisterUserDeviceRequest> | undefined, b: UsersServiceRegisterUserDeviceRequest | PlainMessage<UsersServiceRegisterUserDeviceRequest> | undefined): boolean {
    return proto3.util.equals(UsersServiceRegisterUserDeviceRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to reset a user's password
 *
 * @generated from message nail2poll.UsersServiceResetPasswordRequest
 */
export class UsersServiceResetPasswordRequest extends Message<UsersServiceResetPasswordRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * Username of the user
   *
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<UsersServiceResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceResetPasswordRequest {
    return new UsersServiceResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceResetPasswordRequest {
    return new UsersServiceResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceResetPasswordRequest {
    return new UsersServiceResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceResetPasswordRequest | PlainMessage<UsersServiceResetPasswordRequest> | undefined, b: UsersServiceResetPasswordRequest | PlainMessage<UsersServiceResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(UsersServiceResetPasswordRequest, a, b);
  }
}

/**
 *
 * Describes the data structure of each user on the platform
 *
 * @generated from message nail2poll.User
 */
export class User extends Message<User> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The first name of the user
   *
   * @generated from field: string first_name = 10;
   */
  firstName = "";

  /**
   * The last name of the user
   *
   * @generated from field: string last_name = 11;
   */
  lastName = "";

  /**
   * Username of the user
   *
   * @generated from field: string username = 12;
   */
  username = "";

  /**
   * The email address of the contact
   *
   * @generated from field: string email = 13;
   */
  email = "";

  /**
   * The optional phone number of the contact
   *
   * @generated from field: string phone = 14;
   */
  phone = "";

  /**
   * The optional date of birth of the contact
   *
   * @generated from field: string date_of_birth = 17;
   */
  dateOfBirth = "";

  /**
   * The address of the user
   *
   * @generated from field: string address = 18;
   */
  address = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of users
 *
 * @generated from message nail2poll.UsersList
 */
export class UsersList extends Message<UsersList> {
  /**
   * List of users
   *
   * @generated from field: repeated nail2poll.User list = 1;
   */
  list: User[] = [];

  constructor(data?: PartialMessage<UsersList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersList {
    return new UsersList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersList {
    return new UsersList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersList {
    return new UsersList().fromJsonString(jsonString, options);
  }

  static equals(a: UsersList | PlainMessage<UsersList> | undefined, b: UsersList | PlainMessage<UsersList> | undefined): boolean {
    return proto3.util.equals(UsersList, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.UsersServicePaginationReq
 */
export class UsersServicePaginationReq extends Message<UsersServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.USER_SORT_KEY sort_key = 5;
   */
  sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<UsersServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(USER_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServicePaginationReq {
    return new UsersServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServicePaginationReq {
    return new UsersServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServicePaginationReq {
    return new UsersServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServicePaginationReq | PlainMessage<UsersServicePaginationReq> | undefined, b: UsersServicePaginationReq | PlainMessage<UsersServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(UsersServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.UserPaginationResp
 */
export class UserPaginationResp extends Message<UserPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.User payload = 3;
   */
  payload: User[] = [];

  constructor(data?: PartialMessage<UserPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UserPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPaginationResp {
    return new UserPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPaginationResp {
    return new UserPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPaginationResp {
    return new UserPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: UserPaginationResp | PlainMessage<UserPaginationResp> | undefined, b: UserPaginationResp | PlainMessage<UserPaginationResp> | undefined): boolean {
    return proto3.util.equals(UserPaginationResp, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.UsersServiceFilterReq
 */
export class UsersServiceFilterReq extends Message<UsersServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.USER_SORT_KEY sort_key = 5;
   */
  sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The first name of the user
   *
   * @generated from field: string first_name = 20;
   */
  firstName = "";

  /**
   * The last name of the user
   *
   * @generated from field: string last_name = 21;
   */
  lastName = "";

  /**
   * Username of the user
   *
   * @generated from field: string username = 22;
   */
  username = "";

  /**
   * The email address of the contact
   *
   * @generated from field: string email = 23;
   */
  email = "";

  /**
   * The optional phone number of the contact
   *
   * @generated from field: string phone = 24;
   */
  phone = "";

  /**
   * The optional date of birth of the contact
   *
   * @generated from field: string date_of_birth = 27;
   */
  dateOfBirth = "";

  constructor(data?: PartialMessage<UsersServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(USER_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "date_of_birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceFilterReq {
    return new UsersServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceFilterReq {
    return new UsersServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceFilterReq {
    return new UsersServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceFilterReq | PlainMessage<UsersServiceFilterReq> | undefined, b: UsersServiceFilterReq | PlainMessage<UsersServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(UsersServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.UsersServiceSearchAllReq
 */
export class UsersServiceSearchAllReq extends Message<UsersServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.USER_SORT_KEY sort_key = 5;
   */
  sortKey = USER_SORT_KEY.USER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 11;
   */
  searchKey = "";

  constructor(data?: PartialMessage<UsersServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UsersServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(USER_SORT_KEY) },
    { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersServiceSearchAllReq {
    return new UsersServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersServiceSearchAllReq {
    return new UsersServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersServiceSearchAllReq {
    return new UsersServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: UsersServiceSearchAllReq | PlainMessage<UsersServiceSearchAllReq> | undefined, b: UsersServiceSearchAllReq | PlainMessage<UsersServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(UsersServiceSearchAllReq, a, b);
  }
}

