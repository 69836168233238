import { Router } from "./router";
import login = require("./login");
import dash = require("./dash");
import events = require("./events");
import households = require("./households");
import users = require("./users");
import voters = require("./voters");

export function start() {
    let r = new Router();
    login.Routes(r);
    dash.Routes(r);
    events.Routes(r);
    households.Routes(r);
    users.Routes(r);
    voters.Routes(r);

    r.setDefault((ctx) => {
        location.href = "/";
    });

    r.start();
}

