import { PoliticalLeader } from "../structs/political_leaders_pb";
import { VotersServiceCountRequest, VotersServiceGroupByResponse, VotersServiceGroupByResponseList } from "../structs/voters_pb";
import * as utilities from "../utilities";
import { Grid } from "gridjs";
import * as echarts from 'echarts';
import { VoterLeaderSentimentsServiceGroupByResponse } from "../structs/voters_pb";
import { Sentiment } from "../structs/sentiments_pb";


/**Render all the statistics relevant to leaders */
export async function renderLeaderStatistics(
    primaryBody: HTMLElement, countReq: VotersServiceCountRequest,
    leadersMap: Map<string, PoliticalLeader>,
    sentimentsMap: Map<string, Sentiment>
) {
    let section = document.createElement("section");
    section.classList.add("statistics-section");
    primaryBody.appendChild(section);

    const votersClient = utilities.getVotersServiceReadClient();
    const [groupByLeader, votersCount] = await Promise.all([
        votersClient.groupBySentimentAndLeader(countReq),
        votersClient.count(countReq)
    ]);
    await Promise.all([
        renderLeaderStatisticsInTable(section, groupByLeader.list, votersCount.count, leadersMap, sentimentsMap),
        // renderBarChart(section, groupByLeader.list, votersCount.count, leadersMap, sentimentsMap),
        renderPieChart(section, groupByLeader.list, votersCount.count, leadersMap, sentimentsMap)
    ]);

    if (section.childElementCount == 0) {
        section.parentElement.removeChild(section);
    }
}

// Render table
async function renderLeaderStatisticsInTable(
    section: HTMLElement, groups: VoterLeaderSentimentsServiceGroupByResponse[],
    totalVoterCount: bigint,
    leadersMap: Map<string, PoliticalLeader>,
    sentimentsMap: Map<string, Sentiment>
) {
    if (groups.length == 0) {
        return
    }
    let tableDiv = document.createElement("div");
    tableDiv.classList.add("columns");
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = "Leaders Sentiment Distribution";
    section.appendChild(title);

    section.appendChild(tableDiv);

    const grid = new Grid({
        columns: [
            "S.No.", "Leader Name", "Leader Code", "Sentiment", "Count", "Response Percentage (%)",
        ],
        sort: true,
        fixedHeader: true,
        resizable: true,
        search: true,
        height: '50vh',
        width: '80vw',
        data: groups.map((record, i) => {
            return [
                i + 1, leadersMap.get(record.leaderUuid).name, leadersMap.get(record.leaderUuid).code, sentimentsMap.get(record.sentimentUuid).name, record.count,
                ((parseInt(String(record.count)) / parseInt(String(totalVoterCount))) * 100).toFixed(2) + ' %'
            ]
        })
    });
    grid.render(tableDiv);
}

// Render bar chart
// async function renderBarChart(
//     section: HTMLElement, groups: VoterLeaderSentimentsServiceGroupByResponse[],
//     totalVoterCount: bigint,
//     leadersMap: Map<string, PoliticalLeader>,
//     sentimentsMap: Map<string, Sentiment>
// ) {
//     if (groups.length == 0) {
//         return
//     }
//     let chartDom = document.createElement('div');
//     chartDom.style.width = "100vw";
//     chartDom.style.height = "50vh";
//     section.appendChild(chartDom);
//     let myChart = echarts.init(chartDom);

//     let trimmedLeadersMap: Map<string, PoliticalLeader> = new Map();
//     let leadersList = <PoliticalLeader[]>[];
//     for (let i = 0; i < groups.length; i++) {
//         trimmedLeadersMap.set(groups[i].leaderUuid, leadersMap.get(groups[i].leaderUuid));
//     }

//     trimmedLeadersMap.forEach(leader => {
//         leadersList.push(leader);
//     });

//     let sentimentsList = <Sentiment[]>[];
//     sentimentsMap.forEach(sentiment => {
//         sentimentsList.push(sentiment);
//     });

//     // Zero out all the available sentiments
//     // for (let [key, value] of sentimentsMap) {
//     //     console.log(key, value);
//     // }

//     // For each sentiment, check if the leader already has a sentiment value. If not, then create a 0 value, otherwise, set the value

//     let dataSeries = <number[][]>[];
//     for (let i = 0; i < sentimentsList.length; i++) {
//         const sentiment = sentimentsList[i];
//         const series = leadersList.map(leader => {
//             for (let j = 0; j < groups.length; j++) {
//                 if (groups[j].leaderUuid == leader.metadata.uuid && groups[j].sentimentUuid == sentiment.metadata.uuid) {
//                     return parseInt(String(groups[j].count));
//                 }
//             }
//             return 0;
//         });
//         dataSeries.push(series);
//     }

//     console.log(dataSeries);





//     const computedSeries = groups.map(g => {
//         return {
//             name: sentimentsMap.get(g.sentimentUuid).name,
//             type: <"bar">'bar',
//             stack: 'total',
//             label: {
//                 show: true
//             },
//             emphasis: {
//                 focus: <"series">'series'
//             },
//             data: sentimentsList.map(s => {
//                 for (let i = 0; i < groups.length; i++) {
//                     if (groups[i].sentimentUuid == s.metadata.uuid) {
//                         return parseInt(String(groups[i].count));
//                     }
//                 }
//                 return 0;
//             }),

//             showBackground: true,
//             backgroundStyle: {
//                 color: 'rgba(180, 180, 180, 0.2)'
//             }
//         }
//     });

//     console.log(computedSeries);

//     let option: echarts.EChartsOption = {
//         grid: {
//             left: '3%',
//             right: '4%',
//             bottom: '3%',
//             containLabel: true
//           },
//           xAxis: {
//             type: 'value'
//           },
//           yAxis: {
//             type: 'category',
//             data: leadersList.map(l => l.name)
//           },
//         series: computedSeries,
//         tooltip: {
//             showContent: true,
//             trigger: 'axis',
//             axisPointer: {
//                 // Use axis to trigger tooltip
//                 type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
//               }
//         }
//     };

//     myChart.setOption(option);
// }

// Render pie chart
async function renderPieChart(
    section: HTMLElement, groups: VoterLeaderSentimentsServiceGroupByResponse[],
    totalVoterCount: bigint,
    leadersMap: Map<string, PoliticalLeader>,
    sentimentsMap: Map<string, Sentiment>
) {
    if (groups.length == 0) {
        return
    }
    let trimmedLeadersMap: Map<string, PoliticalLeader> = new Map();
    let leadersList = <PoliticalLeader[]>[];
    for (let i = 0; i < groups.length; i++) {
        trimmedLeadersMap.set(groups[i].leaderUuid, leadersMap.get(groups[i].leaderUuid));
    }
    trimmedLeadersMap.forEach(leader => {
        leadersList.push(leader);
    });

    let colorPalette = [];
    groups.forEach(g => {
        if (sentimentsMap.get(g.sentimentUuid).name.toLowerCase().indexOf("positive") > -1) {
            colorPalette.push(utilities.POSITIVE_COLOR);
        } else {
            colorPalette.push('#'+(Math.random()*0xFFFFFF<<0).toString(16));
        }
    });

    for (let i = 0; i < leadersList.length; i++) {
        const leader = leadersList[i];

        let title = document.createElement("h5");
        title.style.textAlign = "center";
        title.style.fontSize = "1.2rem";
        title.innerText = leader.name;
        section.appendChild(title);

        let chartDom = document.createElement('div');
        chartDom.style.width = "100vw";
        chartDom.style.height = "70vh";
        section.appendChild(chartDom);
        let myChart = echarts.init(chartDom);
        let option: echarts.EChartsOption = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    // name: 'Access From',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        position: 'inside',
                        formatter: '{d}%',
                        // color: 'black',
                        fontSize: 16
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: groups.filter(g => {
                        if (g.leaderUuid == leader.metadata.uuid) {
                            return g
                        }
                    }).map(r => {
                        return { value: parseInt(String(r.count)), name: sentimentsMap.get(r.sentimentUuid).name };
                    })
                }
            ],
            color: colorPalette
        };

        myChart.setOption(option);
    }
}