// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file voters_notes.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";
import { VoterBasic } from "./voters_pb.js";

/**
 *
 * Describes the available sort keys for retrieving voter notes
 *
 * @generated from enum nail2poll.VOTER_NOTE_SORT_KEY
 */
export enum VOTER_NOTE_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: VOTER_NOTE_SORT_KEY_CREATED_AT = 1;
   */
  VOTER_NOTE_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: VOTER_NOTE_SORT_KEY_MODIFIED_AT = 2;
   */
  VOTER_NOTE_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by title
   *
   * @generated from enum value: VOTER_NOTE_SORT_KEY_TITLE = 10;
   */
  VOTER_NOTE_SORT_KEY_TITLE = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(VOTER_NOTE_SORT_KEY)
proto3.util.setEnumType(VOTER_NOTE_SORT_KEY, "nail2poll.VOTER_NOTE_SORT_KEY", [
  { no: 0, name: "VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "VOTER_NOTE_SORT_KEY_CREATED_AT" },
  { no: 2, name: "VOTER_NOTE_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "VOTER_NOTE_SORT_KEY_TITLE" },
]);

/**
 *
 * Describes the data structure of each voter note on the platform
 *
 * @generated from message nail2poll.VoterNote
 */
export class VoterNote extends Message<VoterNote> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 5;
   */
  voterUuid = "";

  /**
   * The title of the voter note
   *
   * @generated from field: string title = 10;
   */
  title = "";

  /**
   * Content of the voter note
   *
   * @generated from field: string content = 11;
   */
  content = "";

  /**
   * Stores the basic info of the relevant voter
   *
   * @generated from field: nail2poll.VoterBasic voter = 20;
   */
  voter?: VoterBasic;

  constructor(data?: PartialMessage<VoterNote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "voter", kind: "message", T: VoterBasic },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNote {
    return new VoterNote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNote {
    return new VoterNote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNote {
    return new VoterNote().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNote | PlainMessage<VoterNote> | undefined, b: VoterNote | PlainMessage<VoterNote> | undefined): boolean {
    return proto3.util.equals(VoterNote, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of voter notes
 *
 * @generated from message nail2poll.VoterNotesList
 */
export class VoterNotesList extends Message<VoterNotesList> {
  /**
   * List of voter notes
   *
   * @generated from field: repeated nail2poll.VoterNote list = 1;
   */
  list: VoterNote[] = [];

  constructor(data?: PartialMessage<VoterNotesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: VoterNote, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesList {
    return new VoterNotesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesList {
    return new VoterNotesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesList {
    return new VoterNotesList().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesList | PlainMessage<VoterNotesList> | undefined, b: VoterNotesList | PlainMessage<VoterNotesList> | undefined): boolean {
    return proto3.util.equals(VoterNotesList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.VoterNotePaginationResp
 */
export class VoterNotePaginationResp extends Message<VoterNotePaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.VoterNote payload = 3;
   */
  payload: VoterNote[] = [];

  constructor(data?: PartialMessage<VoterNotePaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotePaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: VoterNote, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotePaginationResp {
    return new VoterNotePaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotePaginationResp {
    return new VoterNotePaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotePaginationResp {
    return new VoterNotePaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotePaginationResp | PlainMessage<VoterNotePaginationResp> | undefined, b: VoterNotePaginationResp | PlainMessage<VoterNotePaginationResp> | undefined): boolean {
    return proto3.util.equals(VoterNotePaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a voter note
 *
 * @generated from message nail2poll.VoterNotesServiceCreateRequest
 */
export class VoterNotesServiceCreateRequest extends Message<VoterNotesServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 5;
   */
  voterUuid = "";

  /**
   * The title of the voter note
   *
   * @generated from field: string title = 10;
   */
  title = "";

  /**
   * Content of the voter note
   *
   * @generated from field: string content = 11;
   */
  content = "";

  constructor(data?: PartialMessage<VoterNotesServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesServiceCreateRequest {
    return new VoterNotesServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesServiceCreateRequest {
    return new VoterNotesServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesServiceCreateRequest {
    return new VoterNotesServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesServiceCreateRequest | PlainMessage<VoterNotesServiceCreateRequest> | undefined, b: VoterNotesServiceCreateRequest | PlainMessage<VoterNotesServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(VoterNotesServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a voter note
 *
 * @generated from message nail2poll.VoterNotesServiceUpdateRequest
 */
export class VoterNotesServiceUpdateRequest extends Message<VoterNotesServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The title of the voter note
   *
   * @generated from field: string title = 10;
   */
  title = "";

  /**
   * Content of the voter note
   *
   * @generated from field: string content = 11;
   */
  content = "";

  constructor(data?: PartialMessage<VoterNotesServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesServiceUpdateRequest {
    return new VoterNotesServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesServiceUpdateRequest {
    return new VoterNotesServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesServiceUpdateRequest {
    return new VoterNotesServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesServiceUpdateRequest | PlainMessage<VoterNotesServiceUpdateRequest> | undefined, b: VoterNotesServiceUpdateRequest | PlainMessage<VoterNotesServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(VoterNotesServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.VoterNotesServicePaginationReq
 */
export class VoterNotesServicePaginationReq extends Message<VoterNotesServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_NOTE_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_NOTE_SORT_KEY.VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<VoterNotesServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_NOTE_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesServicePaginationReq {
    return new VoterNotesServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesServicePaginationReq {
    return new VoterNotesServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesServicePaginationReq {
    return new VoterNotesServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesServicePaginationReq | PlainMessage<VoterNotesServicePaginationReq> | undefined, b: VoterNotesServicePaginationReq | PlainMessage<VoterNotesServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(VoterNotesServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.VoterNotesServiceFilterReq
 */
export class VoterNotesServiceFilterReq extends Message<VoterNotesServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_NOTE_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_NOTE_SORT_KEY.VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the user that added this note
   *
   * @generated from field: string added_by_user_uuid = 11;
   */
  addedByUserUuid = "";

  /**
   * The title of the voter note
   *
   * @generated from field: string title = 20;
   */
  title = "";

  constructor(data?: PartialMessage<VoterNotesServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_NOTE_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesServiceFilterReq {
    return new VoterNotesServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesServiceFilterReq {
    return new VoterNotesServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesServiceFilterReq {
    return new VoterNotesServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesServiceFilterReq | PlainMessage<VoterNotesServiceFilterReq> | undefined, b: VoterNotesServiceFilterReq | PlainMessage<VoterNotesServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(VoterNotesServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.VoterNotesServiceSearchAllReq
 */
export class VoterNotesServiceSearchAllReq extends Message<VoterNotesServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.VOTER_NOTE_SORT_KEY sort_key = 5;
   */
  sortKey = VOTER_NOTE_SORT_KEY.VOTER_NOTE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The UUID of the voter
   *
   * @generated from field: string voter_uuid = 10;
   */
  voterUuid = "";

  /**
   * The UUID of the user that added this note
   *
   * @generated from field: string added_by_user_uuid = 11;
   */
  addedByUserUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<VoterNotesServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.VoterNotesServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(VOTER_NOTE_SORT_KEY) },
    { no: 10, name: "voter_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "added_by_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoterNotesServiceSearchAllReq {
    return new VoterNotesServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoterNotesServiceSearchAllReq {
    return new VoterNotesServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoterNotesServiceSearchAllReq {
    return new VoterNotesServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: VoterNotesServiceSearchAllReq | PlainMessage<VoterNotesServiceSearchAllReq> | undefined, b: VoterNotesServiceSearchAllReq | PlainMessage<VoterNotesServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(VoterNotesServiceSearchAllReq, a, b);
  }
}

