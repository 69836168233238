// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file polling_stations.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving polling stations
 *
 * @generated from enum nail2poll.POLLING_STATION_SORT_KEY
 */
export enum POLLING_STATION_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  POLLING_STATION_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_CREATED_AT = 1;
   */
  POLLING_STATION_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_MODIFIED_AT = 2;
   */
  POLLING_STATION_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_NAME = 10;
   */
  POLLING_STATION_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_CODE = 11;
   */
  POLLING_STATION_SORT_KEY_CODE = 11,

  /**
   * Fetch ordered results by number
   *
   * @generated from enum value: POLLING_STATION_SORT_KEY_NUMBER = 12;
   */
  POLLING_STATION_SORT_KEY_NUMBER = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(POLLING_STATION_SORT_KEY)
proto3.util.setEnumType(POLLING_STATION_SORT_KEY, "nail2poll.POLLING_STATION_SORT_KEY", [
  { no: 0, name: "POLLING_STATION_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "POLLING_STATION_SORT_KEY_CREATED_AT" },
  { no: 2, name: "POLLING_STATION_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "POLLING_STATION_SORT_KEY_NAME" },
  { no: 11, name: "POLLING_STATION_SORT_KEY_CODE" },
  { no: 12, name: "POLLING_STATION_SORT_KEY_NUMBER" },
]);

/**
 *
 * Describes the data structure of each polling station on the platform
 *
 * @generated from message nail2poll.PollingStation
 */
export class PollingStation extends Message<PollingStation> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 6;
   */
  districtUuid = "";

  /**
   * The name of the polling station
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the polling station
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Station number
   *
   * @generated from field: string number = 12;
   */
  number = "";

  /**
   * Address of the polling station
   *
   * @generated from field: string address = 13;
   */
  address = "";

  constructor(data?: PartialMessage<PollingStation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStation {
    return new PollingStation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStation {
    return new PollingStation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStation {
    return new PollingStation().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStation | PlainMessage<PollingStation> | undefined, b: PollingStation | PlainMessage<PollingStation> | undefined): boolean {
    return proto3.util.equals(PollingStation, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of polling stations
 *
 * @generated from message nail2poll.PollingStationsList
 */
export class PollingStationsList extends Message<PollingStationsList> {
  /**
   * List of polling stations
   *
   * @generated from field: repeated nail2poll.PollingStation list = 1;
   */
  list: PollingStation[] = [];

  constructor(data?: PartialMessage<PollingStationsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: PollingStation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsList {
    return new PollingStationsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsList {
    return new PollingStationsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsList {
    return new PollingStationsList().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsList | PlainMessage<PollingStationsList> | undefined, b: PollingStationsList | PlainMessage<PollingStationsList> | undefined): boolean {
    return proto3.util.equals(PollingStationsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.PollingStationPaginationResp
 */
export class PollingStationPaginationResp extends Message<PollingStationPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.PollingStation payload = 3;
   */
  payload: PollingStation[] = [];

  constructor(data?: PartialMessage<PollingStationPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: PollingStation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationPaginationResp {
    return new PollingStationPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationPaginationResp {
    return new PollingStationPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationPaginationResp {
    return new PollingStationPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationPaginationResp | PlainMessage<PollingStationPaginationResp> | undefined, b: PollingStationPaginationResp | PlainMessage<PollingStationPaginationResp> | undefined): boolean {
    return proto3.util.equals(PollingStationPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a polling station
 *
 * @generated from message nail2poll.PollingStationsServiceCreateRequest
 */
export class PollingStationsServiceCreateRequest extends Message<PollingStationsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 6;
   */
  districtUuid = "";

  /**
   * The name of the polling station
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the polling station
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Station number
   *
   * @generated from field: string number = 12;
   */
  number = "";

  /**
   * Address of the polling station
   *
   * @generated from field: string address = 13;
   */
  address = "";

  constructor(data?: PartialMessage<PollingStationsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsServiceCreateRequest {
    return new PollingStationsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsServiceCreateRequest {
    return new PollingStationsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsServiceCreateRequest {
    return new PollingStationsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsServiceCreateRequest | PlainMessage<PollingStationsServiceCreateRequest> | undefined, b: PollingStationsServiceCreateRequest | PlainMessage<PollingStationsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(PollingStationsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a polling station
 *
 * @generated from message nail2poll.PollingStationsServiceUpdateRequest
 */
export class PollingStationsServiceUpdateRequest extends Message<PollingStationsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the district
   *
   * @generated from field: string district_uuid = 6;
   */
  districtUuid = "";

  /**
   * The name of the polling station
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the polling station
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Station number
   *
   * @generated from field: string number = 12;
   */
  number = "";

  /**
   * Address of the polling station
   *
   * @generated from field: string address = 13;
   */
  address = "";

  constructor(data?: PartialMessage<PollingStationsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsServiceUpdateRequest {
    return new PollingStationsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsServiceUpdateRequest {
    return new PollingStationsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsServiceUpdateRequest {
    return new PollingStationsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsServiceUpdateRequest | PlainMessage<PollingStationsServiceUpdateRequest> | undefined, b: PollingStationsServiceUpdateRequest | PlainMessage<PollingStationsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(PollingStationsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.PollingStationsServicePaginationReq
 */
export class PollingStationsServicePaginationReq extends Message<PollingStationsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLLING_STATION_SORT_KEY sort_key = 5;
   */
  sortKey = POLLING_STATION_SORT_KEY.POLLING_STATION_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<PollingStationsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLLING_STATION_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsServicePaginationReq {
    return new PollingStationsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsServicePaginationReq {
    return new PollingStationsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsServicePaginationReq {
    return new PollingStationsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsServicePaginationReq | PlainMessage<PollingStationsServicePaginationReq> | undefined, b: PollingStationsServicePaginationReq | PlainMessage<PollingStationsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(PollingStationsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.PollingStationsServiceFilterReq
 */
export class PollingStationsServiceFilterReq extends Message<PollingStationsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLLING_STATION_SORT_KEY sort_key = 5;
   */
  sortKey = POLLING_STATION_SORT_KEY.POLLING_STATION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * UUID of the constituency where the polling station is present
   *
   * @generated from field: string constituency_uuid = 10;
   */
  constituencyUuid = "";

  /**
   * UUID of the district where the polling station is present
   *
   * @generated from field: string district_uuid = 11;
   */
  districtUuid = "";

  /**
   * The name of the polling station
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the polling station
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * Polling station number
   *
   * @generated from field: string number = 22;
   */
  number = "";

  constructor(data?: PartialMessage<PollingStationsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLLING_STATION_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsServiceFilterReq {
    return new PollingStationsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsServiceFilterReq {
    return new PollingStationsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsServiceFilterReq {
    return new PollingStationsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsServiceFilterReq | PlainMessage<PollingStationsServiceFilterReq> | undefined, b: PollingStationsServiceFilterReq | PlainMessage<PollingStationsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(PollingStationsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.PollingStationsServiceSearchAllReq
 */
export class PollingStationsServiceSearchAllReq extends Message<PollingStationsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLLING_STATION_SORT_KEY sort_key = 5;
   */
  sortKey = POLLING_STATION_SORT_KEY.POLLING_STATION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * UUID of the constituency where the polling station is present
   *
   * @generated from field: string constituency_uuid = 10;
   */
  constituencyUuid = "";

  /**
   * UUID of the district where the polling station is present
   *
   * @generated from field: string district_uuid = 11;
   */
  districtUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<PollingStationsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PollingStationsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLLING_STATION_SORT_KEY) },
    { no: 10, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "district_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingStationsServiceSearchAllReq {
    return new PollingStationsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingStationsServiceSearchAllReq {
    return new PollingStationsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingStationsServiceSearchAllReq {
    return new PollingStationsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: PollingStationsServiceSearchAllReq | PlainMessage<PollingStationsServiceSearchAllReq> | undefined, b: PollingStationsServiceSearchAllReq | PlainMessage<PollingStationsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(PollingStationsServiceSearchAllReq, a, b);
  }
}

