// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file political_leaders.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving political leaders
 *
 * @generated from enum nail2poll.POLITICAL_LEADER_SORT_KEY
 */
export enum POLITICAL_LEADER_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: POLITICAL_LEADER_SORT_KEY_CREATED_AT = 1;
   */
  POLITICAL_LEADER_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: POLITICAL_LEADER_SORT_KEY_MODIFIED_AT = 2;
   */
  POLITICAL_LEADER_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: POLITICAL_LEADER_SORT_KEY_NAME = 10;
   */
  POLITICAL_LEADER_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: POLITICAL_LEADER_SORT_KEY_CODE = 11;
   */
  POLITICAL_LEADER_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(POLITICAL_LEADER_SORT_KEY)
proto3.util.setEnumType(POLITICAL_LEADER_SORT_KEY, "nail2poll.POLITICAL_LEADER_SORT_KEY", [
  { no: 0, name: "POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "POLITICAL_LEADER_SORT_KEY_CREATED_AT" },
  { no: 2, name: "POLITICAL_LEADER_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "POLITICAL_LEADER_SORT_KEY_NAME" },
  { no: 11, name: "POLITICAL_LEADER_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each political leader on the platform
 *
 * @generated from message nail2poll.PoliticalLeader
 */
export class PoliticalLeader extends Message<PoliticalLeader> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the party
   *
   * @generated from field: string party_uuid = 6;
   */
  partyUuid = "";

  /**
   * The name of the political leader
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the political leader
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<PoliticalLeader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "party_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeader {
    return new PoliticalLeader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeader {
    return new PoliticalLeader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeader {
    return new PoliticalLeader().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeader | PlainMessage<PoliticalLeader> | undefined, b: PoliticalLeader | PlainMessage<PoliticalLeader> | undefined): boolean {
    return proto3.util.equals(PoliticalLeader, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of political leaders
 *
 * @generated from message nail2poll.PoliticalLeadersList
 */
export class PoliticalLeadersList extends Message<PoliticalLeadersList> {
  /**
   * List of political leaders
   *
   * @generated from field: repeated nail2poll.PoliticalLeader list = 1;
   */
  list: PoliticalLeader[] = [];

  constructor(data?: PartialMessage<PoliticalLeadersList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: PoliticalLeader, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersList {
    return new PoliticalLeadersList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersList {
    return new PoliticalLeadersList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersList {
    return new PoliticalLeadersList().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersList | PlainMessage<PoliticalLeadersList> | undefined, b: PoliticalLeadersList | PlainMessage<PoliticalLeadersList> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.PoliticalLeaderPaginationResp
 */
export class PoliticalLeaderPaginationResp extends Message<PoliticalLeaderPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.PoliticalLeader payload = 3;
   */
  payload: PoliticalLeader[] = [];

  constructor(data?: PartialMessage<PoliticalLeaderPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeaderPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: PoliticalLeader, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeaderPaginationResp {
    return new PoliticalLeaderPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeaderPaginationResp {
    return new PoliticalLeaderPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeaderPaginationResp {
    return new PoliticalLeaderPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeaderPaginationResp | PlainMessage<PoliticalLeaderPaginationResp> | undefined, b: PoliticalLeaderPaginationResp | PlainMessage<PoliticalLeaderPaginationResp> | undefined): boolean {
    return proto3.util.equals(PoliticalLeaderPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a political leader
 *
 * @generated from message nail2poll.PoliticalLeadersServiceCreateRequest
 */
export class PoliticalLeadersServiceCreateRequest extends Message<PoliticalLeadersServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the party
   *
   * @generated from field: string party_uuid = 6;
   */
  partyUuid = "";

  /**
   * The name of the political leader
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the political leader
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<PoliticalLeadersServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "party_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersServiceCreateRequest {
    return new PoliticalLeadersServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceCreateRequest {
    return new PoliticalLeadersServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceCreateRequest {
    return new PoliticalLeadersServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersServiceCreateRequest | PlainMessage<PoliticalLeadersServiceCreateRequest> | undefined, b: PoliticalLeadersServiceCreateRequest | PlainMessage<PoliticalLeadersServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a political leader
 *
 * @generated from message nail2poll.PoliticalLeadersServiceUpdateRequest
 */
export class PoliticalLeadersServiceUpdateRequest extends Message<PoliticalLeadersServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the constituency
   *
   * @generated from field: string constituency_uuid = 5;
   */
  constituencyUuid = "";

  /**
   * The UUID of the party
   *
   * @generated from field: string party_uuid = 6;
   */
  partyUuid = "";

  /**
   * The name of the political leader
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the political leader
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<PoliticalLeadersServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "party_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersServiceUpdateRequest {
    return new PoliticalLeadersServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceUpdateRequest {
    return new PoliticalLeadersServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceUpdateRequest {
    return new PoliticalLeadersServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersServiceUpdateRequest | PlainMessage<PoliticalLeadersServiceUpdateRequest> | undefined, b: PoliticalLeadersServiceUpdateRequest | PlainMessage<PoliticalLeadersServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.PoliticalLeadersServicePaginationReq
 */
export class PoliticalLeadersServicePaginationReq extends Message<PoliticalLeadersServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLITICAL_LEADER_SORT_KEY sort_key = 5;
   */
  sortKey = POLITICAL_LEADER_SORT_KEY.POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<PoliticalLeadersServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLITICAL_LEADER_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersServicePaginationReq {
    return new PoliticalLeadersServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersServicePaginationReq {
    return new PoliticalLeadersServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersServicePaginationReq {
    return new PoliticalLeadersServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersServicePaginationReq | PlainMessage<PoliticalLeadersServicePaginationReq> | undefined, b: PoliticalLeadersServicePaginationReq | PlainMessage<PoliticalLeadersServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.PoliticalLeadersServiceFilterReq
 */
export class PoliticalLeadersServiceFilterReq extends Message<PoliticalLeadersServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLITICAL_LEADER_SORT_KEY sort_key = 5;
   */
  sortKey = POLITICAL_LEADER_SORT_KEY.POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * UUID of the constituency where the political leader is present
   *
   * @generated from field: string constituency_uuid = 10;
   */
  constituencyUuid = "";

  /**
   * UUID of the party that the political leader belongs to
   *
   * @generated from field: string party_uuid = 11;
   */
  partyUuid = "";

  /**
   * The name of the political leader
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the political leader
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<PoliticalLeadersServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLITICAL_LEADER_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "party_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersServiceFilterReq {
    return new PoliticalLeadersServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceFilterReq {
    return new PoliticalLeadersServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceFilterReq {
    return new PoliticalLeadersServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersServiceFilterReq | PlainMessage<PoliticalLeadersServiceFilterReq> | undefined, b: PoliticalLeadersServiceFilterReq | PlainMessage<PoliticalLeadersServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.PoliticalLeadersServiceSearchAllReq
 */
export class PoliticalLeadersServiceSearchAllReq extends Message<PoliticalLeadersServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.POLITICAL_LEADER_SORT_KEY sort_key = 5;
   */
  sortKey = POLITICAL_LEADER_SORT_KEY.POLITICAL_LEADER_SORT_KEY_ID_UNSPECIFIED;

  /**
   * UUID of the constituency where the political leader is present
   *
   * @generated from field: string constituency_uuid = 10;
   */
  constituencyUuid = "";

  /**
   * UUID of the party that the political leader belongs to
   *
   * @generated from field: string party_uuid = 11;
   */
  partyUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<PoliticalLeadersServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PoliticalLeadersServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(POLITICAL_LEADER_SORT_KEY) },
    { no: 10, name: "constituency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "party_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoliticalLeadersServiceSearchAllReq {
    return new PoliticalLeadersServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceSearchAllReq {
    return new PoliticalLeadersServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoliticalLeadersServiceSearchAllReq {
    return new PoliticalLeadersServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: PoliticalLeadersServiceSearchAllReq | PlainMessage<PoliticalLeadersServiceSearchAllReq> | undefined, b: PoliticalLeadersServiceSearchAllReq | PlainMessage<PoliticalLeadersServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(PoliticalLeadersServiceSearchAllReq, a, b);
  }
}

