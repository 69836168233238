// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file parties.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving parties
 *
 * @generated from enum nail2poll.PARTY_SORT_KEY
 */
export enum PARTY_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: PARTY_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  PARTY_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: PARTY_SORT_KEY_CREATED_AT = 1;
   */
  PARTY_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: PARTY_SORT_KEY_MODIFIED_AT = 2;
   */
  PARTY_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: PARTY_SORT_KEY_NAME = 10;
   */
  PARTY_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: PARTY_SORT_KEY_CODE = 11;
   */
  PARTY_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(PARTY_SORT_KEY)
proto3.util.setEnumType(PARTY_SORT_KEY, "nail2poll.PARTY_SORT_KEY", [
  { no: 0, name: "PARTY_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "PARTY_SORT_KEY_CREATED_AT" },
  { no: 2, name: "PARTY_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "PARTY_SORT_KEY_NAME" },
  { no: 11, name: "PARTY_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each party on the platform
 *
 * @generated from message nail2poll.Party
 */
export class Party extends Message<Party> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the party
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the party
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<Party>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Party";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Party {
    return new Party().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Party {
    return new Party().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Party {
    return new Party().fromJsonString(jsonString, options);
  }

  static equals(a: Party | PlainMessage<Party> | undefined, b: Party | PlainMessage<Party> | undefined): boolean {
    return proto3.util.equals(Party, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of parties
 *
 * @generated from message nail2poll.PartiesList
 */
export class PartiesList extends Message<PartiesList> {
  /**
   * List of parties
   *
   * @generated from field: repeated nail2poll.Party list = 1;
   */
  list: Party[] = [];

  constructor(data?: PartialMessage<PartiesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Party, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesList {
    return new PartiesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesList {
    return new PartiesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesList {
    return new PartiesList().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesList | PlainMessage<PartiesList> | undefined, b: PartiesList | PlainMessage<PartiesList> | undefined): boolean {
    return proto3.util.equals(PartiesList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.PartyPaginationResp
 */
export class PartyPaginationResp extends Message<PartyPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Party payload = 3;
   */
  payload: Party[] = [];

  constructor(data?: PartialMessage<PartyPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartyPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Party, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartyPaginationResp {
    return new PartyPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartyPaginationResp {
    return new PartyPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartyPaginationResp {
    return new PartyPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: PartyPaginationResp | PlainMessage<PartyPaginationResp> | undefined, b: PartyPaginationResp | PlainMessage<PartyPaginationResp> | undefined): boolean {
    return proto3.util.equals(PartyPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a party
 *
 * @generated from message nail2poll.PartiesServiceCreateRequest
 */
export class PartiesServiceCreateRequest extends Message<PartiesServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the party
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the party
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<PartiesServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesServiceCreateRequest {
    return new PartiesServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesServiceCreateRequest {
    return new PartiesServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesServiceCreateRequest {
    return new PartiesServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesServiceCreateRequest | PlainMessage<PartiesServiceCreateRequest> | undefined, b: PartiesServiceCreateRequest | PlainMessage<PartiesServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(PartiesServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a party
 *
 * @generated from message nail2poll.PartiesServiceUpdateRequest
 */
export class PartiesServiceUpdateRequest extends Message<PartiesServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the party
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the party
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<PartiesServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesServiceUpdateRequest {
    return new PartiesServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesServiceUpdateRequest {
    return new PartiesServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesServiceUpdateRequest {
    return new PartiesServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesServiceUpdateRequest | PlainMessage<PartiesServiceUpdateRequest> | undefined, b: PartiesServiceUpdateRequest | PlainMessage<PartiesServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(PartiesServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.PartiesServicePaginationReq
 */
export class PartiesServicePaginationReq extends Message<PartiesServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PARTY_SORT_KEY sort_key = 5;
   */
  sortKey = PARTY_SORT_KEY.PARTY_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<PartiesServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PARTY_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesServicePaginationReq {
    return new PartiesServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesServicePaginationReq {
    return new PartiesServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesServicePaginationReq {
    return new PartiesServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesServicePaginationReq | PlainMessage<PartiesServicePaginationReq> | undefined, b: PartiesServicePaginationReq | PlainMessage<PartiesServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(PartiesServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.PartiesServiceFilterReq
 */
export class PartiesServiceFilterReq extends Message<PartiesServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PARTY_SORT_KEY sort_key = 5;
   */
  sortKey = PARTY_SORT_KEY.PARTY_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the party
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the party
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<PartiesServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PARTY_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesServiceFilterReq {
    return new PartiesServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesServiceFilterReq {
    return new PartiesServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesServiceFilterReq {
    return new PartiesServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesServiceFilterReq | PlainMessage<PartiesServiceFilterReq> | undefined, b: PartiesServiceFilterReq | PlainMessage<PartiesServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(PartiesServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.PartiesServiceSearchAllReq
 */
export class PartiesServiceSearchAllReq extends Message<PartiesServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.PARTY_SORT_KEY sort_key = 5;
   */
  sortKey = PARTY_SORT_KEY.PARTY_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<PartiesServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.PartiesServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(PARTY_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartiesServiceSearchAllReq {
    return new PartiesServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartiesServiceSearchAllReq {
    return new PartiesServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartiesServiceSearchAllReq {
    return new PartiesServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: PartiesServiceSearchAllReq | PlainMessage<PartiesServiceSearchAllReq> | undefined, b: PartiesServiceSearchAllReq | PlainMessage<PartiesServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(PartiesServiceSearchAllReq, a, b);
  }
}

