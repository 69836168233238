import { Router, context } from "./router";
import * as utilities from "./utilities";
import { HOUSEHOLD_SORT_KEY, Household, HouseholdsServiceCountRequest, HouseholdsServiceFilterReq } from "./structs/households_pb";
import * as searchables from "./searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { SORT_ORDER } from "./structs/base_pb";
import { Voter } from "./structs/voters_pb";
import { decodeVoterGender, decodeVoterRelationType } from "./voters";
import { HouseholdsService } from "./structs/households_connect";
import { PromiseClient } from "@connectrpc/connect";
import { Grid, html } from "gridjs";


const mapDivID = "map";
let allOpenInfoWindows = <google.maps.InfoWindow[]>[];
let allMarkers = <google.maps.Marker[]>[];

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/ui/households", (ctx) => {
        if (utilities.getAuth() == "") {
            return utilities.logout();
        }
        Households(ctx);
    });
}

export async function Households(ctx: context) {
    let primaryBody = utilities.resetPageEssentials();
    primaryBody.innerHTML = "";

    let contentDiv = document.createElement("div");
    contentDiv.classList.add("columns");

    const mapDiv = document.createElement("div");
    mapDiv.id = mapDivID;
    mapDiv.classList.add("is-four-fifths");
    mapDiv.classList.add("column");

    contentDiv.appendChild(mapDiv);

    let title = document.createElement("h1");
    title.classList.add("title");
    title.innerText = "Households List";
    primaryBody.appendChild(title);
    primaryBody.appendChild(contentDiv);

    let miscellaneousContentDiv = document.createElement("div");
    primaryBody.appendChild(miscellaneousContentDiv);

    utilities.setupGoogleMaps().then(async () => {
        // Coords to Machilipatnam
        let latitude = 16.170000;
        let longitude = 81.129997;

        let mapDiv = document.getElementById(mapDivID);
        while (mapDiv?.firstChild) {
            mapDiv.removeChild(mapDiv.firstChild);
        }

        let googleMap = utilities.initialiseGoogleMaps(latitude, longitude, mapDivID);
        setupPageFilters(miscellaneousContentDiv, contentDiv, googleMap);
    });
}

function setupPageFilters(primaryBody: HTMLDivElement, parentDiv: HTMLDivElement, googleMap: google.maps.Map) {
    let filtersDiv = document.createElement("div");
    filtersDiv.classList.add("column");
    filtersDiv.classList.add("filters");

    let filterTitle = document.createElement("h2");
    filterTitle.classList.add("is-size-3");
    filterTitle.innerText = "Filters";
    filterTitle.style.textAlign = "center";
    filtersDiv.appendChild(filterTitle);

    parentDiv.appendChild(filtersDiv);

    const nameInput = searchables.renderInput("Name", "text");
    filtersDiv.appendChild(nameInput.inputDiv);

    const codeInput = searchables.renderInput("Code", "text");
    filtersDiv.appendChild(codeInput.inputDiv);

    // Insert polling station
    const pollingStationsSearchableID = searchables.pollingStationsSearchable(filtersDiv);

    // Insert user searchable here
    const usersSearchableID = searchables.usersSearchable(filtersDiv);
    
    // Insert creation timestamp start date input
    const creationTimestampStartInput = searchables.renderDate("Creation Date Start");
    filtersDiv.appendChild(creationTimestampStartInput.inputDiv);
    
    // Insert creation timestamp end date input
    const creationTimestampEndInput = searchables.renderDate("Creation Date End");
    filtersDiv.appendChild(creationTimestampEndInput.inputDiv);

    utilities.setupDateFields("__date");

    // Submit button
    let submitButton = utilities.getSubmitButton();
    filtersDiv.appendChild(submitButton);
    submitButton.addEventListener("click", async evt => {
        while (primaryBody.firstChild) {
            primaryBody.removeChild(primaryBody.firstChild);
        }
        evt.preventDefault();

        let t1Val = (<HTMLInputElement>document.getElementById(creationTimestampStartInput.inputElID)).value;
        let t2Val = (<HTMLInputElement>document.getElementById(creationTimestampEndInput.inputElID)).value;

        let filterReq = new HouseholdsServiceFilterReq({
            isActive: true,
            count: protoInt64.parse(-1),
            offset: protoInt64.zero,
            sortOrder: SORT_ORDER.ASCENDING_UNSPECIFIED,
            sortKey: HOUSEHOLD_SORT_KEY.HOUSEHOLD_SORT_KEY_ID_UNSPECIFIED,
            creationTimestampStart: t1Val.length != 0 ? protoInt64.parse((new Date(t1Val)).getTime() / 1000) : protoInt64.zero,
            creationTimestampEnd: t2Val.length != 0 ? protoInt64.parse((new Date(t2Val)).getTime() / 1000) : protoInt64.zero,

            pollingStationUuid: (<HTMLSelectElement>document.getElementById(pollingStationsSearchableID)).value,
            addedByUserUuid: (<HTMLSelectElement>document.getElementById(usersSearchableID)).value,

            name: (<HTMLInputElement>document.getElementById(nameInput.inputElID)).value,
            code: (<HTMLInputElement>document.getElementById(codeInput.inputElID)).value,
        });

        let countReq = new HouseholdsServiceCountRequest({
            isActive: true,
            
            creationTimestampStart: t1Val.length != 0 ? protoInt64.parse((new Date(t1Val)).getTime() / 1000) : protoInt64.zero,
            creationTimestampEnd: t2Val.length != 0 ? protoInt64.parse((new Date(t2Val)).getTime() / 1000) : protoInt64.zero,
            
            pollingStationUuid: (<HTMLSelectElement>document.getElementById(pollingStationsSearchableID)).value,
            addedByUserUuid: (<HTMLSelectElement>document.getElementById(usersSearchableID)).value,
        });

        displayHouseholds(primaryBody, googleMap, filterReq, countReq);
    });
}

async function displayHouseholds(primaryBody: HTMLElement, googleMap: google.maps.Map, filterReq: HouseholdsServiceFilterReq, countReq: HouseholdsServiceCountRequest) {
    allMarkers.forEach(m => {
        m.setMap(null);
    });

    const householdsClient = utilities.getHouseholdsServiceReadClient();
    const allHouseholds = (await householdsClient.filter(filterReq)).list;
    for (let i = 0; i < allHouseholds.length; i++) {
        let household = allHouseholds[i];
        createHouseholdMarker(googleMap, {
            household,
            markerColor: "red"
        });
    }
    if (allHouseholds.length > 0) {
        await renderHouseholdsInTable(primaryBody, householdsClient, allHouseholds);
    }

    // Render a notification about the number of records retrieved
    utilities.renderNotification(`${allHouseholds.length} record(s) found`);
}

export async function renderHouseholdsInTable(
    primaryBody: HTMLElement, householdsClient: PromiseClient<typeof HouseholdsService>, households: Household[],
) {
    let container = document.createElement("div");
    container.classList.add("households-section");
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = `Households Table (${households.length == 1 ? '1 Household' : households.length+" Households"})`;
    container.appendChild(title);

    let downloadHouseholdsBtn = utilities.getDownloadButton();
    container.appendChild(downloadHouseholdsBtn);

    let tableBody = document.createElement("div");
    container.appendChild(tableBody);

    primaryBody.appendChild(container);

    const votersClient = utilities.getVotersServiceReadClient();

    const columns = [
        "S.No.", "Name", "Address", "Voter Count", "View", "Lat", "Lng", "Directions"
    ];

    const rows = await Promise.all(households.map(async (household, i) => {
        const votersCount = await votersClient.count({ isActive: true, householdUuid: household.metadata.uuid });

        return [
            i+1 , household.name, household.address,
            votersCount.count,
            html(`<a class="household-button button is-link" data-uuid='${household.metadata.uuid}'>View</a>`),

            household.latitude != 270 ? household.latitude : "-",
            household.longitude != 270 ? household.longitude : "-",
            html(`<a href="${utilities.renderGoogleMapsDrivingDirectionsLink(household.latitude.toString(), household.longitude.toString())}" target="_blank" class="button is-success">Directions</a>`)
        ]
    }));

    downloadHouseholdsBtn.addEventListener("click", async evt => {
        evt.preventDefault();

        let localColumns = [...columns];

        const VIEW_COLUMN_INDEX = 6;

        // Remove the VIEW column
        localColumns.splice(VIEW_COLUMN_INDEX, 1);

        const directionsRow = localColumns.length - 1;

        let transformedRows = <string[][]>[];
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let localArr = <string[]>[];
            for (let j = 0; j < row.length; j++) {
                localArr.push(row[j].toString());
            }

            localArr.splice(VIEW_COLUMN_INDEX, 1);
            
            localArr.splice(directionsRow, 1)
            localArr.push(utilities.renderGoogleMapsDrivingDirectionsLink(localArr[directionsRow - 2], localArr[directionsRow - 1]));

            transformedRows.push(localArr);
        }

        utilities.downloadAsCSV(localColumns, transformedRows, `Households`);
    });

    const grid = new Grid({
        columns: columns,
        sort: true,
        fixedHeader: true,
        resizable: true,
        search: true,
        height: '50vh',
        data: rows
      });
      grid.render(tableBody);
      grid.on("cellClick", async evt => {
        const target = <HTMLElement>evt.target;
        if (target.nodeName.toLowerCase() == "a" && target.classList.contains("household-button")) {
            let button = <HTMLAnchorElement>evt.target;
            const household = await householdsClient.viewByUUID({ uuid: button.getAttribute("data-uuid") });
            closeAndOpenHouseholdInfoSidebar(household);
        }
      });
}

export function createHouseholdMarker(googleMap: google.maps.Map, params: {
    household: Household,
    markerColor: "green" | "red" | "blue"
}) {
    if (params.household.latitude == 270 || params.household.longitude == 270) {
        return
    }

    const marker = new google.maps.Marker({
        position: { lat: params.household.latitude, lng: params.household.longitude },
        map: googleMap,
        // title: params.title,
        // label: params.title

    });
    allMarkers.push(marker);
    marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${params.markerColor}-dot.png`)

    marker.addListener("click", () => {
        for (let i = 0; i < allOpenInfoWindows.length; i++) {
            try {
                allOpenInfoWindows[i].close();
            } catch (e) { }
        }

        const sidebar = closeAndOpenHouseholdInfoSidebar(params.household);

        // map.setZoom(15);
        // googleMap.setCenter(marker.getPosition() as google.maps.LatLng);

        let infowindow = new google.maps.InfoWindow({
            content: `<div class='marker-content'>${params.household.name}</div>`,
        });

        infowindow.open({
            anchor: marker,
            map: googleMap,
            shouldFocus: false,
        });
        allOpenInfoWindows.push(infowindow);

        infowindow.addListener('closeclick', () => {
            // Handle focus manually.
            infowindow.close();
            // map.setZoom(12);
            utilities.closeSpecificSidebar(sidebar);
        });
    });
}

function closeAndOpenHouseholdInfoSidebar(household: Household) {
    utilities.closeAllOpenSidebars();
    const { sidebar, contentID } = utilities.displaySidebar("Household Information");
    displayHouseholdContentInSidebar(sidebar, contentID, household);
    return sidebar
}

/**Displays the household information within the sidebar */
async function displayHouseholdContentInSidebar(sidebar: HTMLDivElement, contentID: string, household: Household) {
    let primaryDiv = document.createElement("div");
    primaryDiv.classList.add("column");
    let [
        voters
    ] = await Promise.all([
        utilities.getVotersServiceReadClient().filter({ isActive: true, count: protoInt64.parse(-1), sortOrder: SORT_ORDER.DESCENDING, householdUuid: household.metadata.uuid })
    ]);

    primaryDiv.innerHTML = `
    <div style='padding: 5px;' class='content'>
        <blockquote>
            <h4 class="is-size-5">Name: ${household.name}</h4>
            <h4 class="is-size-5">Code: ${household.code}</h4>
            <h4 class="is-size-5">Address: ${household.address}</h4>
        </blockquote>
    </div>
    <div style='padding: 5px; margin-top: 20px;'>
        <h4 class="is-size-4" style="text-align: center;">Voters (${voters.list.length})</h4>
        ${await Promise.all(voters.list.map(async (voter, index) => {
            return (await renderVoter(voter, index+1)).outerHTML;
        }))}
    </div>
    `;
    document.getElementById(contentID).innerHTML = primaryDiv.outerHTML;
}

async function renderVoter(voter: Voter, index: number): Promise<HTMLDivElement> {
    let div = document.createElement("div");
    div.classList.add("content");

    let [pollingstation, profession, party, caste, religion] = await Promise.all([
        utilities.getPollingStationsServiceReadClient().viewByUUID({ uuid: voter.pollingStationUuid }),
        utilities.getProfessionsServiceReadClient().viewByUUID({ uuid: voter.professionUuid }),
        utilities.getPartiesServiceReadClient().viewByUUID({ uuid: voter.partyAffiliationUuid }),
        utilities.getCastesServiceReadClient().viewByUUID({ uuid: voter.casteUuid }),
        utilities.getReligionsServiceReadClient().viewByUUID({ uuid: voter.religionUuid })
    ]);

    div.innerHTML = `
    <blockquote>
        <h4 class="is-size-5">Name: ${voter.name}</h4>
        <h4 class="is-size-5">Gender: ${decodeVoterGender(voter.gender)}</h4>
        <h4 class="is-size-5">Age: ${voter.age}</h4>
        <h4 class="is-size-5">Voter ID: ${voter.voterId}</h4>
        <h4 class="is-size-5">Mobile: ${utilities.renderWhatsAppLink(voter.mobileNumber)}</h4>
        <h4 class="is-size-5">Email: ${voter.email}</h4>
        <h4 class="is-size-5">Birthday: ${voter.dateOfBirth}</h4>

        <h4 class="is-size-5">Relation Type: ${decodeVoterRelationType(voter.relationType)}</h4>
        <h4 class="is-size-5">Relation Name: ${voter.relationName}</h4>

        <h4 class="is-size-5">Serial No: ${voter.serialNo}</h4>

        <h4 class="is-size-5">Polling Station: (${pollingstation.code}) ${pollingstation.name}</h4>
        <h4 class="is-size-5">Profession: (${profession.code}) ${profession.name} (${profession.subProfession})</h4>
        <h4 class="is-size-5">Party: (${party.code}) ${party.name}</h4>
        <h4 class="is-size-5">Caste: (${caste.code}) ${caste.name} (${caste.subCaste})</h4>
        <h4 class="is-size-5">Religion: (${religion.code}) ${religion.name} (${religion.subReligion})</h4>
    </blockquote>
    `;
    return div;
}