// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file sentiments.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Sentiment, SentimentPaginationResp, SentimentsList, SentimentsServiceCreateRequest, SentimentsServiceFilterReq, SentimentsServicePaginationReq, SentimentsServiceSearchAllReq, SentimentsServiceUpdateRequest } from "./sentiments_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each sentiment
 *
 * @generated from service nail2poll.SentimentsService
 */
export const SentimentsService = {
  typeName: "nail2poll.SentimentsService",
  methods: {
    /**
     * Create a sentiment
     *
     * @generated from rpc nail2poll.SentimentsService.Create
     */
    create: {
      name: "Create",
      I: SentimentsServiceCreateRequest,
      O: Sentiment,
      kind: MethodKind.Unary,
    },
    /**
     * Update a sentiment
     *
     * @generated from rpc nail2poll.SentimentsService.Update
     */
    update: {
      name: "Update",
      I: SentimentsServiceUpdateRequest,
      O: Sentiment,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the sentiment
     *
     * @generated from rpc nail2poll.SentimentsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the sentiment
     *
     * @generated from rpc nail2poll.SentimentsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.SentimentsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: Sentiment,
      kind: MethodKind.Unary,
    },
    /**
     * View all sentiments
     *
     * @generated from rpc nail2poll.SentimentsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: SentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * View sentiments with pagination
     *
     * @generated from rpc nail2poll.SentimentsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: SentimentsServicePaginationReq,
      O: SentimentPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.SentimentsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: SentimentsServiceSearchAllReq,
      O: SentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.SentimentsService.Filter
     */
    filter: {
      name: "Filter",
      I: SentimentsServiceFilterReq,
      O: SentimentsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.SentimentsService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.SentimentsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.SentimentsService.Export
     */
    export: {
      name: "Export",
      I: SentimentsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

