// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file states.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { State, StatePaginationResp, StatesList, StatesServiceCreateRequest, StatesServiceFilterReq, StatesServicePaginationReq, StatesServiceSearchAllReq, StatesServiceUpdateRequest } from "./states_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each state
 *
 * @generated from service nail2poll.StatesService
 */
export const StatesService = {
  typeName: "nail2poll.StatesService",
  methods: {
    /**
     * Create a state
     *
     * @generated from rpc nail2poll.StatesService.Create
     */
    create: {
      name: "Create",
      I: StatesServiceCreateRequest,
      O: State,
      kind: MethodKind.Unary,
    },
    /**
     * Update a state
     *
     * @generated from rpc nail2poll.StatesService.Update
     */
    update: {
      name: "Update",
      I: StatesServiceUpdateRequest,
      O: State,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the state
     *
     * @generated from rpc nail2poll.StatesService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the state
     *
     * @generated from rpc nail2poll.StatesService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.StatesService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: State,
      kind: MethodKind.Unary,
    },
    /**
     * View all states
     *
     * @generated from rpc nail2poll.StatesService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: StatesList,
      kind: MethodKind.Unary,
    },
    /**
     * View states with pagination
     *
     * @generated from rpc nail2poll.StatesService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: StatesServicePaginationReq,
      O: StatePaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.StatesService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: StatesServiceSearchAllReq,
      O: StatesList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.StatesService.Filter
     */
    filter: {
      name: "Filter",
      I: StatesServiceFilterReq,
      O: StatesList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.StatesService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.StatesService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.StatesService.Export
     */
    export: {
      name: "Export",
      I: StatesServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

