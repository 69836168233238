import { Router, context } from "./router";
import * as utilities from "./utilities";
import { EVENT_SORT_KEY, Event, EventsServiceFilterReq } from "./structs/events_pb";
import * as searchables from "./searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { SORT_ORDER } from "./structs/base_pb";


const mapDivID = "map";
let allOpenInfoWindows = <google.maps.InfoWindow[]>[];
let allMarkers = <google.maps.Marker[]>[];

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/ui/events", (ctx) => {
        if (utilities.getAuth() == "") {
            return utilities.logout();
        }
        Events(ctx);
    });
}

export async function Events(ctx: context) {
    let primaryBody = utilities.resetPageEssentials();
    primaryBody.innerHTML = "";

    let contentDiv = document.createElement("div");
    contentDiv.classList.add("columns");

    const mapDiv = document.createElement("div");
    mapDiv.id = mapDivID;
    mapDiv.classList.add("is-four-fifths");
    mapDiv.classList.add("column");

    contentDiv.appendChild(mapDiv);

    let title = document.createElement("h1");
    title.classList.add("title");
    title.innerText = "Events List";
    primaryBody.appendChild(title);
    primaryBody.appendChild(contentDiv);

    utilities.setupGoogleMaps().then(async () => {
        // Coords to Machilipatnam
        let latitude = 16.170000;
        let longitude = 81.129997;

        let mapDiv = document.getElementById(mapDivID);
        while (mapDiv?.firstChild) {
            mapDiv.removeChild(mapDiv.firstChild);
        }

        let googleMap = utilities.initialiseGoogleMaps(latitude, longitude, mapDivID);
        setupPageFilters(contentDiv, googleMap);
    });
}

function setupPageFilters(parentDiv: HTMLDivElement, googleMap: google.maps.Map) {
    let filtersDiv = document.createElement("div");
    filtersDiv.classList.add("column");
    filtersDiv.classList.add("filters");

    let filterTitle = document.createElement("h2");
    filterTitle.classList.add("is-size-3");
    filterTitle.innerText = "Filters";
    filterTitle.style.textAlign = "center";
    filtersDiv.appendChild(filterTitle);

    parentDiv.appendChild(filtersDiv);

    const eventstypesSearchableID = searchables.eventstypesSearchable(filtersDiv);
    const usersSearchableID = searchables.usersSearchable(filtersDiv);
    const constituenciesSearchableID = searchables.constituenciesSearchable(filtersDiv);

    const nameInput = searchables.renderInput("Name", "text");
    filtersDiv.appendChild(nameInput.inputDiv);

    const codeInput = searchables.renderInput("Code", "text");
    filtersDiv.appendChild(codeInput.inputDiv);

    // Submit button
    let submitButton = utilities.getSubmitButton();
    filtersDiv.appendChild(submitButton);
    submitButton.addEventListener("click", async evt => {
        evt.preventDefault();
        let filterReq = new EventsServiceFilterReq({
            isActive: true,
            count: protoInt64.parse(-1),
            offset: protoInt64.zero,
            sortOrder: SORT_ORDER.ASCENDING_UNSPECIFIED,
            sortKey: EVENT_SORT_KEY.EVENT_SORT_KEY_ID_UNSPECIFIED,
            creationTimestampStart: protoInt64.zero,
            creationTimestampEnd: protoInt64.zero,
            
            eventTypeUuid: (<HTMLSelectElement>document.getElementById(eventstypesSearchableID)).value,
            constituencyUuid: (<HTMLSelectElement>document.getElementById(constituenciesSearchableID)).value,
            conductedByUserUuid: (<HTMLSelectElement>document.getElementById(usersSearchableID)).value,

            name: (<HTMLInputElement>document.getElementById(nameInput.inputElID)).value,
            code: (<HTMLInputElement>document.getElementById(codeInput.inputElID)).value,
        });

        displayEvents(googleMap, filterReq);
    });
}

async function displayEvents(googleMap: google.maps.Map, filterReq: EventsServiceFilterReq) {
    allMarkers.forEach(m => {
        m.setMap(null);
    });

    const eventsClient = utilities.getEventsServiceReadClient();
    const allEvents = (await eventsClient.filter(filterReq)).list;
    for (let i = 0; i < allEvents.length; i++) {
        let event = allEvents[i];
        createEventMarker(googleMap, {
            event,
            markerColor: "red"
        });
    }

    // Render a notification about the number of records retrieved
    utilities.renderNotification(`${allEvents.length} record(s) found`);
}

export function createEventMarker(googleMap: google.maps.Map, params: {
    event: Event,
    markerColor: "green" | "red" | "blue"
}) {
    if (params.event.latitude == 270 || params.event.longitude == 270) {
        return
    }

    const marker = new google.maps.Marker({
        position: { lat: params.event.latitude, lng: params.event.longitude },
        map: googleMap,
        // title: params.title,
        // label: params.title

    });
    allMarkers.push(marker);
    marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${params.markerColor}-dot.png`)

    marker.addListener("click", () => {
        for (let i = 0; i < allOpenInfoWindows.length; i++) {
            try {
                allOpenInfoWindows[i].close();
            } catch (e) { }
        }

        utilities.closeAllOpenSidebars();

        const { sidebar, contentID } = utilities.displaySidebar("Event Information");
        displayEventContentInSidebar(sidebar, contentID, params.event);

        // map.setZoom(15);
        // googleMap.setCenter(marker.getPosition() as google.maps.LatLng);

        let infowindow = new google.maps.InfoWindow({
            content: `<div class='marker-content'>${params.event.name}</div>`,
        });

        infowindow.open({
            anchor: marker,
            map: googleMap,
            shouldFocus: false,
        });
        allOpenInfoWindows.push(infowindow);

        infowindow.addListener('closeclick', () => {
            // Handle focus manually.
            infowindow.close();
            // map.setZoom(12);
            utilities.closeSpecificSidebar(sidebar);
        });
    });
}

/**Displays the event information within the sidebar */
async function displayEventContentInSidebar(sidebar: HTMLDivElement, contentID: string, event: Event) {
    let primaryDiv = document.createElement("div");
    primaryDiv.classList.add("column");
    let [
        eventtype, conductedBy, constituency
    ] = await Promise.all([
        utilities.getEventsTypesServiceReadClient().viewByUUID({ uuid: event.eventTypeUuid }),
        utilities.getUsersServiceReadClient().viewByUUID({ uuid: event.conductedByUserUuid }),
        utilities.getConstituenciesServiceReadClient().viewByUUID({ uuid: event.constituencyUuid }),
    ]);

    primaryDiv.innerHTML = `
    <div style='padding: 5px;' class='content'>
        <blockquote>
            <h4 class="is-size-5">Name: ${event.name}</h4>
            <h4 class="is-size-5">Code: ${event.code}</h4>
            <h4 class="is-size-5">Description: ${event.description}</h4>

            <h4 class="is-size-5">Event Type: (${eventtype.code}) ${eventtype.name}}</h4>
            <h4 class="is-size-5">Constituency: (${constituency.code}) ${constituency.name}}</h4>
            <h4 class="is-size-5">Conducted By: ${conductedBy.firstName} ${conductedBy.lastName} (Ph: ${conductedBy.phone})</h4>
            <h4 class="is-size-5">Created At: ${utilities.convertBigIntTimestampToDateTime(event.metadata.createdAt)})</h4>
        </blockquote>
    </div>
    `;

    document.getElementById(contentID).innerHTML = primaryDiv.outerHTML;
}