// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file districts.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { District, DistrictPaginationResp, DistrictsList, DistrictsServiceCreateRequest, DistrictsServiceFilterReq, DistrictsServicePaginationReq, DistrictsServiceSearchAllReq, DistrictsServiceUpdateRequest } from "./districts_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each district
 *
 * @generated from service nail2poll.DistrictsService
 */
export const DistrictsService = {
  typeName: "nail2poll.DistrictsService",
  methods: {
    /**
     * Create a district
     *
     * @generated from rpc nail2poll.DistrictsService.Create
     */
    create: {
      name: "Create",
      I: DistrictsServiceCreateRequest,
      O: District,
      kind: MethodKind.Unary,
    },
    /**
     * Update a district
     *
     * @generated from rpc nail2poll.DistrictsService.Update
     */
    update: {
      name: "Update",
      I: DistrictsServiceUpdateRequest,
      O: District,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the district
     *
     * @generated from rpc nail2poll.DistrictsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the district
     *
     * @generated from rpc nail2poll.DistrictsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.DistrictsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: District,
      kind: MethodKind.Unary,
    },
    /**
     * View all districts
     *
     * @generated from rpc nail2poll.DistrictsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: DistrictsList,
      kind: MethodKind.Unary,
    },
    /**
     * View districts with pagination
     *
     * @generated from rpc nail2poll.DistrictsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: DistrictsServicePaginationReq,
      O: DistrictPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.DistrictsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: DistrictsServiceSearchAllReq,
      O: DistrictsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.DistrictsService.Filter
     */
    filter: {
      name: "Filter",
      I: DistrictsServiceFilterReq,
      O: DistrictsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.DistrictsService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.DistrictsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.DistrictsService.Export
     */
    export: {
      name: "Export",
      I: DistrictsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

