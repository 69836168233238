import { Router, context } from "./router";
import { UserLoginResponse } from "./structs/logins_pb";
import utilities = require("./utilities");

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/", (ctx) => {
        Login(ctx);
    });
}

function Login(ctx: context) {
    document.body.innerHTML = `
    <section class="hero is-link">
        <div class="hero-body">
            <a class="title">
                Nail2Poll
            </a>
            <p class="subtitle">
                
            </p>
        </div>
    </section>
    <section class="section">
        <section class="section">
            <h1 id="title" class="title" style="text-align: center">Login to access data</h1>
        </section>
        <section class="columns is-vcentered">
            <div class="column is-4 is-offset-4">
                    <div class="field">
                        <label class="label">Username</label>
                        <div class="is-fullwidth">
                            <input id="username" class="input is-primary" type="text" placeholder="Username">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Password</label>
                        <div class="is-fullwidth">
                            <input id="password" class="input is-primary" type="password" placeholder="Password">
                        </div>
                    </div>
                    <div class="field" style="margin-top: 30px;">
                        <button id="login" class="button is-link is-fullwidth">Login</button>
                    </div>
            </div>
        </section>
    </section>
    `;

    let usernameEl = <HTMLInputElement>document.getElementById("username");
    let passwordEl = <HTMLInputElement>document.getElementById("password");
    let loginEl = <HTMLButtonElement>document.getElementById("login");
    let titleEl = document.getElementById("title");

    const loginClient = utilities.getLoginServiceClient();

    loginEl.addEventListener("click", async evt => {
        evt.preventDefault();
        await loginClient.userLogin({
            username: usernameEl.value.trim(),
            plainTextPassword: passwordEl.value.trim()
        }).catch(err => {
            titleEl.innerText = "Invalid Login Credentials";
        }).then((resp: UserLoginResponse) => {
            if (resp !== undefined && resp !== null) {
                utilities.setCookie('auth_token', resp.authToken, 1);
                location.href = "/ui/dash";
            }
        });
    });

}