// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file districts.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving districts
 *
 * @generated from enum nail2poll.DISTRICT_SORT_KEY
 */
export enum DISTRICT_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: DISTRICT_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  DISTRICT_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: DISTRICT_SORT_KEY_CREATED_AT = 1;
   */
  DISTRICT_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: DISTRICT_SORT_KEY_MODIFIED_AT = 2;
   */
  DISTRICT_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: DISTRICT_SORT_KEY_NAME = 10;
   */
  DISTRICT_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: DISTRICT_SORT_KEY_CODE = 11;
   */
  DISTRICT_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(DISTRICT_SORT_KEY)
proto3.util.setEnumType(DISTRICT_SORT_KEY, "nail2poll.DISTRICT_SORT_KEY", [
  { no: 0, name: "DISTRICT_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "DISTRICT_SORT_KEY_CREATED_AT" },
  { no: 2, name: "DISTRICT_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "DISTRICT_SORT_KEY_NAME" },
  { no: 11, name: "DISTRICT_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each district on the platform
 *
 * @generated from message nail2poll.District
 */
export class District extends Message<District> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the district
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the district
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<District>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.District";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): District {
    return new District().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): District {
    return new District().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): District {
    return new District().fromJsonString(jsonString, options);
  }

  static equals(a: District | PlainMessage<District> | undefined, b: District | PlainMessage<District> | undefined): boolean {
    return proto3.util.equals(District, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of districts
 *
 * @generated from message nail2poll.DistrictsList
 */
export class DistrictsList extends Message<DistrictsList> {
  /**
   * List of districts
   *
   * @generated from field: repeated nail2poll.District list = 1;
   */
  list: District[] = [];

  constructor(data?: PartialMessage<DistrictsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: District, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsList {
    return new DistrictsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsList {
    return new DistrictsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsList {
    return new DistrictsList().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsList | PlainMessage<DistrictsList> | undefined, b: DistrictsList | PlainMessage<DistrictsList> | undefined): boolean {
    return proto3.util.equals(DistrictsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.DistrictPaginationResp
 */
export class DistrictPaginationResp extends Message<DistrictPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.District payload = 3;
   */
  payload: District[] = [];

  constructor(data?: PartialMessage<DistrictPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: District, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictPaginationResp {
    return new DistrictPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictPaginationResp {
    return new DistrictPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictPaginationResp {
    return new DistrictPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictPaginationResp | PlainMessage<DistrictPaginationResp> | undefined, b: DistrictPaginationResp | PlainMessage<DistrictPaginationResp> | undefined): boolean {
    return proto3.util.equals(DistrictPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a district
 *
 * @generated from message nail2poll.DistrictsServiceCreateRequest
 */
export class DistrictsServiceCreateRequest extends Message<DistrictsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the district
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the district
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<DistrictsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsServiceCreateRequest {
    return new DistrictsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsServiceCreateRequest {
    return new DistrictsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsServiceCreateRequest {
    return new DistrictsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsServiceCreateRequest | PlainMessage<DistrictsServiceCreateRequest> | undefined, b: DistrictsServiceCreateRequest | PlainMessage<DistrictsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(DistrictsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a district
 *
 * @generated from message nail2poll.DistrictsServiceUpdateRequest
 */
export class DistrictsServiceUpdateRequest extends Message<DistrictsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 5;
   */
  stateUuid = "";

  /**
   * The name of the district
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the district
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<DistrictsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsServiceUpdateRequest {
    return new DistrictsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsServiceUpdateRequest {
    return new DistrictsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsServiceUpdateRequest {
    return new DistrictsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsServiceUpdateRequest | PlainMessage<DistrictsServiceUpdateRequest> | undefined, b: DistrictsServiceUpdateRequest | PlainMessage<DistrictsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(DistrictsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.DistrictsServicePaginationReq
 */
export class DistrictsServicePaginationReq extends Message<DistrictsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.DISTRICT_SORT_KEY sort_key = 5;
   */
  sortKey = DISTRICT_SORT_KEY.DISTRICT_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<DistrictsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(DISTRICT_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsServicePaginationReq {
    return new DistrictsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsServicePaginationReq {
    return new DistrictsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsServicePaginationReq {
    return new DistrictsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsServicePaginationReq | PlainMessage<DistrictsServicePaginationReq> | undefined, b: DistrictsServicePaginationReq | PlainMessage<DistrictsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(DistrictsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.DistrictsServiceFilterReq
 */
export class DistrictsServiceFilterReq extends Message<DistrictsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.DISTRICT_SORT_KEY sort_key = 5;
   */
  sortKey = DISTRICT_SORT_KEY.DISTRICT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 10;
   */
  stateUuid = "";

  /**
   * The name of the district
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the district
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<DistrictsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(DISTRICT_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsServiceFilterReq {
    return new DistrictsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsServiceFilterReq {
    return new DistrictsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsServiceFilterReq {
    return new DistrictsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsServiceFilterReq | PlainMessage<DistrictsServiceFilterReq> | undefined, b: DistrictsServiceFilterReq | PlainMessage<DistrictsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(DistrictsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.DistrictsServiceSearchAllReq
 */
export class DistrictsServiceSearchAllReq extends Message<DistrictsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.DISTRICT_SORT_KEY sort_key = 5;
   */
  sortKey = DISTRICT_SORT_KEY.DISTRICT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The UUID of the state
   *
   * @generated from field: string state_uuid = 10;
   */
  stateUuid = "";

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<DistrictsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.DistrictsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(DISTRICT_SORT_KEY) },
    { no: 10, name: "state_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DistrictsServiceSearchAllReq {
    return new DistrictsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DistrictsServiceSearchAllReq {
    return new DistrictsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DistrictsServiceSearchAllReq {
    return new DistrictsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: DistrictsServiceSearchAllReq | PlainMessage<DistrictsServiceSearchAllReq> | undefined, b: DistrictsServiceSearchAllReq | PlainMessage<DistrictsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(DistrictsServiceSearchAllReq, a, b);
  }
}

