// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file political_leaders.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PoliticalLeader, PoliticalLeaderPaginationResp, PoliticalLeadersList, PoliticalLeadersServiceCreateRequest, PoliticalLeadersServiceFilterReq, PoliticalLeadersServicePaginationReq, PoliticalLeadersServiceSearchAllReq, PoliticalLeadersServiceUpdateRequest } from "./political_leaders_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each political leader
 *
 * @generated from service nail2poll.PoliticalLeadersService
 */
export const PoliticalLeadersService = {
  typeName: "nail2poll.PoliticalLeadersService",
  methods: {
    /**
     * Create a political leader
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Create
     */
    create: {
      name: "Create",
      I: PoliticalLeadersServiceCreateRequest,
      O: PoliticalLeader,
      kind: MethodKind.Unary,
    },
    /**
     * Update a political leader
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Update
     */
    update: {
      name: "Update",
      I: PoliticalLeadersServiceUpdateRequest,
      O: PoliticalLeader,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the political leader
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the political leader
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: PoliticalLeader,
      kind: MethodKind.Unary,
    },
    /**
     * View all political leaders
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: PoliticalLeadersList,
      kind: MethodKind.Unary,
    },
    /**
     * View political leaders with pagination
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: PoliticalLeadersServicePaginationReq,
      O: PoliticalLeaderPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: PoliticalLeadersServiceSearchAllReq,
      O: PoliticalLeadersList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Filter
     */
    filter: {
      name: "Filter",
      I: PoliticalLeadersServiceFilterReq,
      O: PoliticalLeadersList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.PoliticalLeadersService.Export
     */
    export: {
      name: "Export",
      I: PoliticalLeadersServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

