import { Router, context } from "./router";
import * as utilities from "./utilities";
import { USER_SORT_KEY, User, UsersServiceCreateRequest, UsersServiceFilterReq } from "./structs/users_pb";
import * as searchables from "./searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { SORT_ORDER } from "./structs/base_pb";
import { Voter } from "./structs/voters_pb";
import { decodeVoterGender, decodeVoterRelationType } from "./voters";
import { UsersService } from "./structs/users_connect";
import { PromiseClient } from "@connectrpc/connect";
import { Grid, html } from "gridjs";


/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/ui/users", (ctx) => {
        if (utilities.getAuth() == "") {
            return utilities.logout();
        }
        Users(ctx);
    });
}

export async function Users(ctx: context) {
    let primaryBody = utilities.resetPageEssentials();
    primaryBody.innerHTML = "";

    let contentDiv = document.createElement("div");
    contentDiv.classList.add("columns");

    let title = document.createElement("h1");
    title.classList.add("title");
    title.innerText = "User Management";
    primaryBody.appendChild(title);
    primaryBody.appendChild(contentDiv);

    let miscellaneousContentDiv = document.createElement("div");
    primaryBody.appendChild(miscellaneousContentDiv);

    setupPageFilters(miscellaneousContentDiv, contentDiv);
}

function setupPageFilters(primaryBody: HTMLDivElement, parentDiv: HTMLDivElement) {
    let filtersDiv = document.createElement("div");
    filtersDiv.classList.add("column");
    filtersDiv.classList.add("filters");

    let filterTitle = document.createElement("h2");
    filterTitle.classList.add("is-size-3");
    filterTitle.innerText = "Create User";
    filterTitle.style.textAlign = "center";
    filtersDiv.appendChild(filterTitle);

    parentDiv.appendChild(filtersDiv);

    const firstNameInput = searchables.renderInput("First Name", "text");
    filtersDiv.appendChild(firstNameInput.inputDiv);

    const lastNameInput = searchables.renderInput("Last Name", "text");
    filtersDiv.appendChild(lastNameInput.inputDiv);

    const usernameInput = searchables.renderInput("Username", "text");
    filtersDiv.appendChild(usernameInput.inputDiv);

    // const emailInput = searchables.renderInput("Email", "text");
    // filtersDiv.appendChild(emailInput.inputDiv);

    const phoneInput = searchables.renderInput("Phone", "text");
    filtersDiv.appendChild(phoneInput.inputDiv);

    const passwordInput = searchables.renderInput("Password", "password");
    filtersDiv.appendChild(passwordInput.inputDiv);

    utilities.setupDateFields("__date");

    // Submit button
    let submitButton = utilities.getSubmitButton();
    submitButton.innerText = "Create";
    filtersDiv.appendChild(submitButton);
    submitButton.addEventListener("click", async evt => {

        evt.preventDefault();

        // Validate fields
        const listToValidate = [firstNameInput, usernameInput, phoneInput, passwordInput];
        let invalidated = <boolean[]>[];
        for (let i = 0; i < listToValidate.length; i++) {
            let input = listToValidate[i];
            let element = (<HTMLInputElement>document.getElementById(input.inputElID));
            let elementLabel = element.parentElement.getElementsByTagName("label")[0];
            if (element.value == "") {
                elementLabel.style.color = "red";
                // utilities.renderNotification(`${elementLabel.innerText} is required`);
                invalidated.push(true);
            } else {
                elementLabel.style.color = "initial";
            }
        }
        if (invalidated.length > 0) {
            return;
        }

        // Create a user here
        let req = new UsersServiceCreateRequest({
            firstName: (<HTMLInputElement>document.getElementById(firstNameInput.inputElID)).value.trim(),
            lastName: (<HTMLInputElement>document.getElementById(lastNameInput.inputElID)).value.trim(),
            username: (<HTMLInputElement>document.getElementById(usernameInput.inputElID)).value.trim(),
            // email: (<HTMLInputElement>document.getElementById(emailInput.inputElID)).value.trim(),
            phone: (<HTMLInputElement>document.getElementById(phoneInput.inputElID)).value.trim(),
            dateOfBirth: "",
            address: "",
            plainTextPassword: (<HTMLInputElement>document.getElementById(passwordInput.inputElID)).value.trim(),
        });

        const client = utilities.getUsersServiceWriteClient();
        try {
            await client.create(req)
            while (primaryBody.firstChild) {
                primaryBody.removeChild(primaryBody.firstChild);
            }

            displayUsers(primaryBody);
        } catch (e) {
            window.alert("Something went wrong");
        }
    });

    displayUsers(primaryBody);
}

async function displayUsers(primaryBody: HTMLElement) {

    let filterReq = new UsersServiceFilterReq({
        isActive: true,
        count: protoInt64.parse(-1),
        offset: protoInt64.zero,
        sortOrder: SORT_ORDER.ASCENDING_UNSPECIFIED,
        sortKey: USER_SORT_KEY.USER_SORT_KEY_USERNAME,
    });

    const usersClient = utilities.getUsersServiceReadClient();
    const allUsers = (await usersClient.filter(filterReq)).list;
    if (allUsers.length > 0) {
        await renderUsersInTable(primaryBody, usersClient, allUsers);
    }

    // Render a notification about the number of records retrieved
    utilities.renderNotification(`${allUsers.length} record(s) found`);
}

export async function renderUsersInTable(
    primaryBody: HTMLElement, usersClient: PromiseClient<typeof UsersService>, users: User[],
) {
    let container = document.createElement("div");
    container.classList.add("users-section");
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = `Users Table (${users.length == 1 ? '1 User' : users.length + " Users"})`;
    container.appendChild(title);

    let downloadUsersBtn = utilities.getDownloadButton();
    container.appendChild(downloadUsersBtn);

    let tableBody = document.createElement("div");
    container.appendChild(tableBody);

    primaryBody.appendChild(container);

    const columns = [
        "S.No.", "First Name", "Last Name", "Username", "Phone", "Email", "Date of Birth", "Address"
    ];

    const rows = await Promise.all(users.map(async (user, i) => {
        return [
            i + 1, user.firstName, user.lastName,
            user.username, user.phone, user.email, user.dateOfBirth, user.address,
        ]
    }));

    downloadUsersBtn.addEventListener("click", async evt => {
        evt.preventDefault();

        let localColumns = [...columns];

        const VIEW_COLUMN_INDEX = 6;

        // Remove the VIEW column
        localColumns.splice(VIEW_COLUMN_INDEX, 1);

        const directionsRow = localColumns.length - 1;

        let transformedRows = <string[][]>[];
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let localArr = <string[]>[];
            for (let j = 0; j < row.length; j++) {
                localArr.push(row[j].toString());
            }

            localArr.splice(VIEW_COLUMN_INDEX, 1);

            transformedRows.push(localArr);
        }

        utilities.downloadAsCSV(localColumns, transformedRows, `Users`);
    });

    const grid = new Grid({
        columns: columns,
        sort: true,
        fixedHeader: true,
        resizable: true,
        search: true,
        height: '50vh',
        data: rows
    });
    grid.render(tableBody);
}