// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file castes.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving castes
 *
 * @generated from enum nail2poll.CASTE_SORT_KEY
 */
export enum CASTE_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: CASTE_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  CASTE_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: CASTE_SORT_KEY_CREATED_AT = 1;
   */
  CASTE_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: CASTE_SORT_KEY_MODIFIED_AT = 2;
   */
  CASTE_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: CASTE_SORT_KEY_NAME = 10;
   */
  CASTE_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: CASTE_SORT_KEY_CODE = 11;
   */
  CASTE_SORT_KEY_CODE = 11,

  /**
   * Fetch ordered results by sub caste
   *
   * @generated from enum value: CASTE_SORT_KEY_SUB_CASTE = 12;
   */
  CASTE_SORT_KEY_SUB_CASTE = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(CASTE_SORT_KEY)
proto3.util.setEnumType(CASTE_SORT_KEY, "nail2poll.CASTE_SORT_KEY", [
  { no: 0, name: "CASTE_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "CASTE_SORT_KEY_CREATED_AT" },
  { no: 2, name: "CASTE_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "CASTE_SORT_KEY_NAME" },
  { no: 11, name: "CASTE_SORT_KEY_CODE" },
  { no: 12, name: "CASTE_SORT_KEY_SUB_CASTE" },
]);

/**
 *
 * Describes the data structure of each caste on the platform
 *
 * @generated from message nail2poll.Caste
 */
export class Caste extends Message<Caste> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the caste
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the caste
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub caste
   *
   * @generated from field: string sub_caste = 12;
   */
  subCaste = "";

  constructor(data?: PartialMessage<Caste>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Caste";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_caste", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Caste {
    return new Caste().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Caste {
    return new Caste().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Caste {
    return new Caste().fromJsonString(jsonString, options);
  }

  static equals(a: Caste | PlainMessage<Caste> | undefined, b: Caste | PlainMessage<Caste> | undefined): boolean {
    return proto3.util.equals(Caste, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of castes
 *
 * @generated from message nail2poll.CastesList
 */
export class CastesList extends Message<CastesList> {
  /**
   * List of castes
   *
   * @generated from field: repeated nail2poll.Caste list = 1;
   */
  list: Caste[] = [];

  constructor(data?: PartialMessage<CastesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Caste, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesList {
    return new CastesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesList {
    return new CastesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesList {
    return new CastesList().fromJsonString(jsonString, options);
  }

  static equals(a: CastesList | PlainMessage<CastesList> | undefined, b: CastesList | PlainMessage<CastesList> | undefined): boolean {
    return proto3.util.equals(CastesList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.CastePaginationResp
 */
export class CastePaginationResp extends Message<CastePaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Caste payload = 3;
   */
  payload: Caste[] = [];

  constructor(data?: PartialMessage<CastePaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastePaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Caste, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastePaginationResp {
    return new CastePaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastePaginationResp {
    return new CastePaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastePaginationResp {
    return new CastePaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: CastePaginationResp | PlainMessage<CastePaginationResp> | undefined, b: CastePaginationResp | PlainMessage<CastePaginationResp> | undefined): boolean {
    return proto3.util.equals(CastePaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a caste
 *
 * @generated from message nail2poll.CastesServiceCreateRequest
 */
export class CastesServiceCreateRequest extends Message<CastesServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the caste
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the caste
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub caste
   *
   * @generated from field: string sub_caste = 12;
   */
  subCaste = "";

  constructor(data?: PartialMessage<CastesServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_caste", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesServiceCreateRequest {
    return new CastesServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesServiceCreateRequest {
    return new CastesServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesServiceCreateRequest {
    return new CastesServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CastesServiceCreateRequest | PlainMessage<CastesServiceCreateRequest> | undefined, b: CastesServiceCreateRequest | PlainMessage<CastesServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(CastesServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a caste
 *
 * @generated from message nail2poll.CastesServiceUpdateRequest
 */
export class CastesServiceUpdateRequest extends Message<CastesServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the caste
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the caste
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub caste
   *
   * @generated from field: string sub_caste = 12;
   */
  subCaste = "";

  constructor(data?: PartialMessage<CastesServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_caste", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesServiceUpdateRequest {
    return new CastesServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesServiceUpdateRequest {
    return new CastesServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesServiceUpdateRequest {
    return new CastesServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CastesServiceUpdateRequest | PlainMessage<CastesServiceUpdateRequest> | undefined, b: CastesServiceUpdateRequest | PlainMessage<CastesServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(CastesServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.CastesServicePaginationReq
 */
export class CastesServicePaginationReq extends Message<CastesServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CASTE_SORT_KEY sort_key = 5;
   */
  sortKey = CASTE_SORT_KEY.CASTE_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<CastesServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CASTE_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesServicePaginationReq {
    return new CastesServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesServicePaginationReq {
    return new CastesServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesServicePaginationReq {
    return new CastesServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: CastesServicePaginationReq | PlainMessage<CastesServicePaginationReq> | undefined, b: CastesServicePaginationReq | PlainMessage<CastesServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(CastesServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.CastesServiceFilterReq
 */
export class CastesServiceFilterReq extends Message<CastesServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CASTE_SORT_KEY sort_key = 5;
   */
  sortKey = CASTE_SORT_KEY.CASTE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the caste
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the caste
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * Sub caste
   *
   * @generated from field: string sub_caste = 22;
   */
  subCaste = "";

  constructor(data?: PartialMessage<CastesServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CASTE_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "sub_caste", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesServiceFilterReq {
    return new CastesServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesServiceFilterReq {
    return new CastesServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesServiceFilterReq {
    return new CastesServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: CastesServiceFilterReq | PlainMessage<CastesServiceFilterReq> | undefined, b: CastesServiceFilterReq | PlainMessage<CastesServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(CastesServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.CastesServiceSearchAllReq
 */
export class CastesServiceSearchAllReq extends Message<CastesServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.CASTE_SORT_KEY sort_key = 5;
   */
  sortKey = CASTE_SORT_KEY.CASTE_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<CastesServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.CastesServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(CASTE_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastesServiceSearchAllReq {
    return new CastesServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastesServiceSearchAllReq {
    return new CastesServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastesServiceSearchAllReq {
    return new CastesServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: CastesServiceSearchAllReq | PlainMessage<CastesServiceSearchAllReq> | undefined, b: CastesServiceSearchAllReq | PlainMessage<CastesServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(CastesServiceSearchAllReq, a, b);
  }
}

