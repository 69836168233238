// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file constituencies.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConstituenciesList, ConstituenciesServiceCreateRequest, ConstituenciesServiceFilterReq, ConstituenciesServicePaginationReq, ConstituenciesServiceSearchAllReq, ConstituenciesServiceUpdateRequest, Constituency, ConstituencyPaginationResp } from "./constituencies_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each constituency
 *
 * @generated from service nail2poll.ConstituenciesService
 */
export const ConstituenciesService = {
  typeName: "nail2poll.ConstituenciesService",
  methods: {
    /**
     * Create a constituency
     *
     * @generated from rpc nail2poll.ConstituenciesService.Create
     */
    create: {
      name: "Create",
      I: ConstituenciesServiceCreateRequest,
      O: Constituency,
      kind: MethodKind.Unary,
    },
    /**
     * Update a constituency
     *
     * @generated from rpc nail2poll.ConstituenciesService.Update
     */
    update: {
      name: "Update",
      I: ConstituenciesServiceUpdateRequest,
      O: Constituency,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the constituency
     *
     * @generated from rpc nail2poll.ConstituenciesService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the constituency
     *
     * @generated from rpc nail2poll.ConstituenciesService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.ConstituenciesService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: Constituency,
      kind: MethodKind.Unary,
    },
    /**
     * View all constituencies
     *
     * @generated from rpc nail2poll.ConstituenciesService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: ConstituenciesList,
      kind: MethodKind.Unary,
    },
    /**
     * View constituencies with pagination
     *
     * @generated from rpc nail2poll.ConstituenciesService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: ConstituenciesServicePaginationReq,
      O: ConstituencyPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.ConstituenciesService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: ConstituenciesServiceSearchAllReq,
      O: ConstituenciesList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.ConstituenciesService.Filter
     */
    filter: {
      name: "Filter",
      I: ConstituenciesServiceFilterReq,
      O: ConstituenciesList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.ConstituenciesService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.ConstituenciesService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.ConstituenciesService.Export
     */
    export: {
      name: "Export",
      I: ConstituenciesServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

