// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file religions.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving religions
 *
 * @generated from enum nail2poll.RELIGION_SORT_KEY
 */
export enum RELIGION_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: RELIGION_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  RELIGION_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: RELIGION_SORT_KEY_CREATED_AT = 1;
   */
  RELIGION_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: RELIGION_SORT_KEY_MODIFIED_AT = 2;
   */
  RELIGION_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: RELIGION_SORT_KEY_NAME = 10;
   */
  RELIGION_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: RELIGION_SORT_KEY_CODE = 11;
   */
  RELIGION_SORT_KEY_CODE = 11,

  /**
   * Fetch ordered results by sub religion
   *
   * @generated from enum value: RELIGION_SORT_KEY_SUB_RELIGION = 12;
   */
  RELIGION_SORT_KEY_SUB_RELIGION = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(RELIGION_SORT_KEY)
proto3.util.setEnumType(RELIGION_SORT_KEY, "nail2poll.RELIGION_SORT_KEY", [
  { no: 0, name: "RELIGION_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "RELIGION_SORT_KEY_CREATED_AT" },
  { no: 2, name: "RELIGION_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "RELIGION_SORT_KEY_NAME" },
  { no: 11, name: "RELIGION_SORT_KEY_CODE" },
  { no: 12, name: "RELIGION_SORT_KEY_SUB_RELIGION" },
]);

/**
 *
 * Describes the data structure of each religion on the platform
 *
 * @generated from message nail2poll.Religion
 */
export class Religion extends Message<Religion> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the religion
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the religion
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub religion
   *
   * @generated from field: string sub_religion = 12;
   */
  subReligion = "";

  constructor(data?: PartialMessage<Religion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Religion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_religion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Religion {
    return new Religion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Religion {
    return new Religion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Religion {
    return new Religion().fromJsonString(jsonString, options);
  }

  static equals(a: Religion | PlainMessage<Religion> | undefined, b: Religion | PlainMessage<Religion> | undefined): boolean {
    return proto3.util.equals(Religion, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of religions
 *
 * @generated from message nail2poll.ReligionsList
 */
export class ReligionsList extends Message<ReligionsList> {
  /**
   * List of religions
   *
   * @generated from field: repeated nail2poll.Religion list = 1;
   */
  list: Religion[] = [];

  constructor(data?: PartialMessage<ReligionsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Religion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsList {
    return new ReligionsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsList {
    return new ReligionsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsList {
    return new ReligionsList().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsList | PlainMessage<ReligionsList> | undefined, b: ReligionsList | PlainMessage<ReligionsList> | undefined): boolean {
    return proto3.util.equals(ReligionsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.ReligionPaginationResp
 */
export class ReligionPaginationResp extends Message<ReligionPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Religion payload = 3;
   */
  payload: Religion[] = [];

  constructor(data?: PartialMessage<ReligionPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Religion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionPaginationResp {
    return new ReligionPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionPaginationResp {
    return new ReligionPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionPaginationResp {
    return new ReligionPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionPaginationResp | PlainMessage<ReligionPaginationResp> | undefined, b: ReligionPaginationResp | PlainMessage<ReligionPaginationResp> | undefined): boolean {
    return proto3.util.equals(ReligionPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a religion
 *
 * @generated from message nail2poll.ReligionsServiceCreateRequest
 */
export class ReligionsServiceCreateRequest extends Message<ReligionsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the religion
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the religion
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub religion
   *
   * @generated from field: string sub_religion = 12;
   */
  subReligion = "";

  constructor(data?: PartialMessage<ReligionsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_religion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsServiceCreateRequest {
    return new ReligionsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsServiceCreateRequest {
    return new ReligionsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsServiceCreateRequest {
    return new ReligionsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsServiceCreateRequest | PlainMessage<ReligionsServiceCreateRequest> | undefined, b: ReligionsServiceCreateRequest | PlainMessage<ReligionsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(ReligionsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a religion
 *
 * @generated from message nail2poll.ReligionsServiceUpdateRequest
 */
export class ReligionsServiceUpdateRequest extends Message<ReligionsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the religion
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the religion
   *
   * @generated from field: string code = 11;
   */
  code = "";

  /**
   * Sub religion
   *
   * @generated from field: string sub_religion = 12;
   */
  subReligion = "";

  constructor(data?: PartialMessage<ReligionsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sub_religion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsServiceUpdateRequest {
    return new ReligionsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsServiceUpdateRequest {
    return new ReligionsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsServiceUpdateRequest {
    return new ReligionsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsServiceUpdateRequest | PlainMessage<ReligionsServiceUpdateRequest> | undefined, b: ReligionsServiceUpdateRequest | PlainMessage<ReligionsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(ReligionsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.ReligionsServicePaginationReq
 */
export class ReligionsServicePaginationReq extends Message<ReligionsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.RELIGION_SORT_KEY sort_key = 5;
   */
  sortKey = RELIGION_SORT_KEY.RELIGION_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<ReligionsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(RELIGION_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsServicePaginationReq {
    return new ReligionsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsServicePaginationReq {
    return new ReligionsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsServicePaginationReq {
    return new ReligionsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsServicePaginationReq | PlainMessage<ReligionsServicePaginationReq> | undefined, b: ReligionsServicePaginationReq | PlainMessage<ReligionsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(ReligionsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.ReligionsServiceFilterReq
 */
export class ReligionsServiceFilterReq extends Message<ReligionsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.RELIGION_SORT_KEY sort_key = 5;
   */
  sortKey = RELIGION_SORT_KEY.RELIGION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the religion
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the religion
   *
   * @generated from field: string code = 21;
   */
  code = "";

  /**
   * Sub religion
   *
   * @generated from field: string sub_religion = 22;
   */
  subReligion = "";

  constructor(data?: PartialMessage<ReligionsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(RELIGION_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "sub_religion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsServiceFilterReq {
    return new ReligionsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsServiceFilterReq {
    return new ReligionsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsServiceFilterReq {
    return new ReligionsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsServiceFilterReq | PlainMessage<ReligionsServiceFilterReq> | undefined, b: ReligionsServiceFilterReq | PlainMessage<ReligionsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(ReligionsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.ReligionsServiceSearchAllReq
 */
export class ReligionsServiceSearchAllReq extends Message<ReligionsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.RELIGION_SORT_KEY sort_key = 5;
   */
  sortKey = RELIGION_SORT_KEY.RELIGION_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<ReligionsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.ReligionsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(RELIGION_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReligionsServiceSearchAllReq {
    return new ReligionsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReligionsServiceSearchAllReq {
    return new ReligionsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReligionsServiceSearchAllReq {
    return new ReligionsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: ReligionsServiceSearchAllReq | PlainMessage<ReligionsServiceSearchAllReq> | undefined, b: ReligionsServiceSearchAllReq | PlainMessage<ReligionsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(ReligionsServiceSearchAllReq, a, b);
  }
}

