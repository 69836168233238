// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file logins.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 *
 * Describes the data structure for a user to login
 *
 * @generated from message nail2poll.UserLoginRequest
 */
export class UserLoginRequest extends Message<UserLoginRequest> {
  /**
   * Username of the user
   *
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * The password of the user
   *
   * @generated from field: string plain_text_password = 12;
   */
  plainTextPassword = "";

  constructor(data?: PartialMessage<UserLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UserLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserLoginRequest | PlainMessage<UserLoginRequest> | undefined, b: UserLoginRequest | PlainMessage<UserLoginRequest> | undefined): boolean {
    return proto3.util.equals(UserLoginRequest, a, b);
  }
}

/**
 *
 * Describes the response to a user login request
 *
 * @generated from message nail2poll.UserLoginResponse
 */
export class UserLoginResponse extends Message<UserLoginResponse> {
  /**
   * Username of the user that just logged in
   *
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * The token that needs to be used as part of every request
   *
   * @generated from field: string auth_token = 2;
   */
  authToken = "";

  /**
   * The unix timestamp after which the auth_token is invalid
   *
   * @generated from field: int64 expires_at = 3;
   */
  expiresAt = protoInt64.zero;

  constructor(data?: PartialMessage<UserLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.UserLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserLoginResponse {
    return new UserLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserLoginResponse {
    return new UserLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserLoginResponse {
    return new UserLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserLoginResponse | PlainMessage<UserLoginResponse> | undefined, b: UserLoginResponse | PlainMessage<UserLoginResponse> | undefined): boolean {
    return proto3.util.equals(UserLoginResponse, a, b);
  }
}

/**
 *
 * Describes the data structure required to check if the login is still valid
 *
 * @generated from message nail2poll.AuthTokenValidityRequest
 */
export class AuthTokenValidityRequest extends Message<AuthTokenValidityRequest> {
  /**
   * The token whose validity needs to be determined
   *
   * @generated from field: string auth_token = 1;
   */
  authToken = "";

  constructor(data?: PartialMessage<AuthTokenValidityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.AuthTokenValidityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthTokenValidityRequest {
    return new AuthTokenValidityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthTokenValidityRequest {
    return new AuthTokenValidityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthTokenValidityRequest {
    return new AuthTokenValidityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AuthTokenValidityRequest | PlainMessage<AuthTokenValidityRequest> | undefined, b: AuthTokenValidityRequest | PlainMessage<AuthTokenValidityRequest> | undefined): boolean {
    return proto3.util.equals(AuthTokenValidityRequest, a, b);
  }
}

/**
 *
 * Describes the data structure required to logout a user
 *
 * @generated from message nail2poll.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 *
 * Describes the response data structure to a logout request
 *
 * @generated from message nail2poll.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

