// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// Author: kenton@google.com (Kenton Varda)
//  Based on original Protocol Buffers design by
//  Sanjay Ghemawat, Jeff Dean, and others.
//
// The messages in this file describe the definitions found in .proto files.
// A valid .proto file can be translated directly to a FileDescriptorProto
// without any other information (e.g. without reading its imports).
// @generated by protoc-gen-es v1.6.0 with parameter "bootstrap_wkt=true,ts_nocheck=false,target=ts"
// @generated from file google/protobuf/descriptor.proto (package google.protobuf, syntax proto2)
/* eslint-disable */
import { proto2 } from "../../proto2.js";
import { Message } from "../../message.js";
/**
 * The full set of known editions.
 *
 * @generated from enum google.protobuf.Edition
 */
export var Edition;
(function (Edition) {
    /**
     * A placeholder for an unknown edition value.
     *
     * @generated from enum value: EDITION_UNKNOWN = 0;
     */
    Edition[Edition["EDITION_UNKNOWN"] = 0] = "EDITION_UNKNOWN";
    /**
     * Legacy syntax "editions".  These pre-date editions, but behave much like
     * distinct editions.  These can't be used to specify the edition of proto
     * files, but feature definitions must supply proto2/proto3 defaults for
     * backwards compatibility.
     *
     * @generated from enum value: EDITION_PROTO2 = 998;
     */
    Edition[Edition["EDITION_PROTO2"] = 998] = "EDITION_PROTO2";
    /**
     * @generated from enum value: EDITION_PROTO3 = 999;
     */
    Edition[Edition["EDITION_PROTO3"] = 999] = "EDITION_PROTO3";
    /**
     * Editions that have been released.  The specific values are arbitrary and
     * should not be depended on, but they will always be time-ordered for easy
     * comparison.
     *
     * @generated from enum value: EDITION_2023 = 1000;
     */
    Edition[Edition["EDITION_2023"] = 1000] = "EDITION_2023";
    /**
     * Placeholder editions for testing feature resolution.  These should not be
     * used or relyed on outside of tests.
     *
     * @generated from enum value: EDITION_1_TEST_ONLY = 1;
     */
    Edition[Edition["EDITION_1_TEST_ONLY"] = 1] = "EDITION_1_TEST_ONLY";
    /**
     * @generated from enum value: EDITION_2_TEST_ONLY = 2;
     */
    Edition[Edition["EDITION_2_TEST_ONLY"] = 2] = "EDITION_2_TEST_ONLY";
    /**
     * @generated from enum value: EDITION_99997_TEST_ONLY = 99997;
     */
    Edition[Edition["EDITION_99997_TEST_ONLY"] = 99997] = "EDITION_99997_TEST_ONLY";
    /**
     * @generated from enum value: EDITION_99998_TEST_ONLY = 99998;
     */
    Edition[Edition["EDITION_99998_TEST_ONLY"] = 99998] = "EDITION_99998_TEST_ONLY";
    /**
     * @generated from enum value: EDITION_99999_TEST_ONLY = 99999;
     */
    Edition[Edition["EDITION_99999_TEST_ONLY"] = 99999] = "EDITION_99999_TEST_ONLY";
})(Edition || (Edition = {}));
// Retrieve enum metadata with: proto2.getEnumType(Edition)
proto2.util.setEnumType(Edition, "google.protobuf.Edition", [
    { no: 0, name: "EDITION_UNKNOWN" },
    { no: 998, name: "EDITION_PROTO2" },
    { no: 999, name: "EDITION_PROTO3" },
    { no: 1000, name: "EDITION_2023" },
    { no: 1, name: "EDITION_1_TEST_ONLY" },
    { no: 2, name: "EDITION_2_TEST_ONLY" },
    { no: 99997, name: "EDITION_99997_TEST_ONLY" },
    { no: 99998, name: "EDITION_99998_TEST_ONLY" },
    { no: 99999, name: "EDITION_99999_TEST_ONLY" },
]);
/**
 * The protocol compiler can output a FileDescriptorSet containing the .proto
 * files it parses.
 *
 * @generated from message google.protobuf.FileDescriptorSet
 */
export class FileDescriptorSet extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.FileDescriptorProto file = 1;
         */
        this.file = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FileDescriptorSet().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FileDescriptorSet().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FileDescriptorSet().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FileDescriptorSet, a, b);
    }
}
FileDescriptorSet.runtime = proto2;
FileDescriptorSet.typeName = "google.protobuf.FileDescriptorSet";
FileDescriptorSet.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "file", kind: "message", T: FileDescriptorProto, repeated: true },
]);
/**
 * Describes a complete .proto file.
 *
 * @generated from message google.protobuf.FileDescriptorProto
 */
export class FileDescriptorProto extends Message {
    constructor(data) {
        super();
        /**
         * Names of files imported by this file.
         *
         * @generated from field: repeated string dependency = 3;
         */
        this.dependency = [];
        /**
         * Indexes of the public imported files in the dependency list above.
         *
         * @generated from field: repeated int32 public_dependency = 10;
         */
        this.publicDependency = [];
        /**
         * Indexes of the weak imported files in the dependency list.
         * For Google-internal migration only. Do not use.
         *
         * @generated from field: repeated int32 weak_dependency = 11;
         */
        this.weakDependency = [];
        /**
         * All top-level definitions in this file.
         *
         * @generated from field: repeated google.protobuf.DescriptorProto message_type = 4;
         */
        this.messageType = [];
        /**
         * @generated from field: repeated google.protobuf.EnumDescriptorProto enum_type = 5;
         */
        this.enumType = [];
        /**
         * @generated from field: repeated google.protobuf.ServiceDescriptorProto service = 6;
         */
        this.service = [];
        /**
         * @generated from field: repeated google.protobuf.FieldDescriptorProto extension = 7;
         */
        this.extension = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FileDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FileDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FileDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FileDescriptorProto, a, b);
    }
}
FileDescriptorProto.runtime = proto2;
FileDescriptorProto.typeName = "google.protobuf.FileDescriptorProto";
FileDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "package", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "dependency", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "public_dependency", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: "weak_dependency", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "message_type", kind: "message", T: DescriptorProto, repeated: true },
    { no: 5, name: "enum_type", kind: "message", T: EnumDescriptorProto, repeated: true },
    { no: 6, name: "service", kind: "message", T: ServiceDescriptorProto, repeated: true },
    { no: 7, name: "extension", kind: "message", T: FieldDescriptorProto, repeated: true },
    { no: 8, name: "options", kind: "message", T: FileOptions, opt: true },
    { no: 9, name: "source_code_info", kind: "message", T: SourceCodeInfo, opt: true },
    { no: 12, name: "syntax", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "edition", kind: "enum", T: proto2.getEnumType(Edition), opt: true },
]);
/**
 * Describes a message type.
 *
 * @generated from message google.protobuf.DescriptorProto
 */
export class DescriptorProto extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.FieldDescriptorProto field = 2;
         */
        this.field = [];
        /**
         * @generated from field: repeated google.protobuf.FieldDescriptorProto extension = 6;
         */
        this.extension = [];
        /**
         * @generated from field: repeated google.protobuf.DescriptorProto nested_type = 3;
         */
        this.nestedType = [];
        /**
         * @generated from field: repeated google.protobuf.EnumDescriptorProto enum_type = 4;
         */
        this.enumType = [];
        /**
         * @generated from field: repeated google.protobuf.DescriptorProto.ExtensionRange extension_range = 5;
         */
        this.extensionRange = [];
        /**
         * @generated from field: repeated google.protobuf.OneofDescriptorProto oneof_decl = 8;
         */
        this.oneofDecl = [];
        /**
         * @generated from field: repeated google.protobuf.DescriptorProto.ReservedRange reserved_range = 9;
         */
        this.reservedRange = [];
        /**
         * Reserved field names, which may not be used by fields in the same message.
         * A given name may only be reserved once.
         *
         * @generated from field: repeated string reserved_name = 10;
         */
        this.reservedName = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(DescriptorProto, a, b);
    }
}
DescriptorProto.runtime = proto2;
DescriptorProto.typeName = "google.protobuf.DescriptorProto";
DescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "field", kind: "message", T: FieldDescriptorProto, repeated: true },
    { no: 6, name: "extension", kind: "message", T: FieldDescriptorProto, repeated: true },
    { no: 3, name: "nested_type", kind: "message", T: DescriptorProto, repeated: true },
    { no: 4, name: "enum_type", kind: "message", T: EnumDescriptorProto, repeated: true },
    { no: 5, name: "extension_range", kind: "message", T: DescriptorProto_ExtensionRange, repeated: true },
    { no: 8, name: "oneof_decl", kind: "message", T: OneofDescriptorProto, repeated: true },
    { no: 7, name: "options", kind: "message", T: MessageOptions, opt: true },
    { no: 9, name: "reserved_range", kind: "message", T: DescriptorProto_ReservedRange, repeated: true },
    { no: 10, name: "reserved_name", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message google.protobuf.DescriptorProto.ExtensionRange
 */
export class DescriptorProto_ExtensionRange extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DescriptorProto_ExtensionRange().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DescriptorProto_ExtensionRange().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DescriptorProto_ExtensionRange().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(DescriptorProto_ExtensionRange, a, b);
    }
}
DescriptorProto_ExtensionRange.runtime = proto2;
DescriptorProto_ExtensionRange.typeName = "google.protobuf.DescriptorProto.ExtensionRange";
DescriptorProto_ExtensionRange.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "options", kind: "message", T: ExtensionRangeOptions, opt: true },
]);
/**
 * Range of reserved tag numbers. Reserved tag numbers may not be used by
 * fields or extension ranges in the same message. Reserved ranges may
 * not overlap.
 *
 * @generated from message google.protobuf.DescriptorProto.ReservedRange
 */
export class DescriptorProto_ReservedRange extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DescriptorProto_ReservedRange().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DescriptorProto_ReservedRange().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DescriptorProto_ReservedRange().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(DescriptorProto_ReservedRange, a, b);
    }
}
DescriptorProto_ReservedRange.runtime = proto2;
DescriptorProto_ReservedRange.typeName = "google.protobuf.DescriptorProto.ReservedRange";
DescriptorProto_ReservedRange.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
]);
/**
 * @generated from message google.protobuf.ExtensionRangeOptions
 */
export class ExtensionRangeOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        /**
         * For external users: DO NOT USE. We are in the process of open sourcing
         * extension declaration and executing internal cleanups before it can be
         * used externally.
         *
         * @generated from field: repeated google.protobuf.ExtensionRangeOptions.Declaration declaration = 2;
         */
        this.declaration = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ExtensionRangeOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExtensionRangeOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExtensionRangeOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(ExtensionRangeOptions, a, b);
    }
}
ExtensionRangeOptions.runtime = proto2;
ExtensionRangeOptions.typeName = "google.protobuf.ExtensionRangeOptions";
ExtensionRangeOptions.fields = proto2.util.newFieldList(() => [
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
    { no: 2, name: "declaration", kind: "message", T: ExtensionRangeOptions_Declaration, repeated: true },
    { no: 50, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 3, name: "verification", kind: "enum", T: proto2.getEnumType(ExtensionRangeOptions_VerificationState), opt: true, default: ExtensionRangeOptions_VerificationState.UNVERIFIED },
]);
/**
 * The verification state of the extension range.
 *
 * @generated from enum google.protobuf.ExtensionRangeOptions.VerificationState
 */
export var ExtensionRangeOptions_VerificationState;
(function (ExtensionRangeOptions_VerificationState) {
    /**
     * All the extensions of the range must be declared.
     *
     * @generated from enum value: DECLARATION = 0;
     */
    ExtensionRangeOptions_VerificationState[ExtensionRangeOptions_VerificationState["DECLARATION"] = 0] = "DECLARATION";
    /**
     * @generated from enum value: UNVERIFIED = 1;
     */
    ExtensionRangeOptions_VerificationState[ExtensionRangeOptions_VerificationState["UNVERIFIED"] = 1] = "UNVERIFIED";
})(ExtensionRangeOptions_VerificationState || (ExtensionRangeOptions_VerificationState = {}));
// Retrieve enum metadata with: proto2.getEnumType(ExtensionRangeOptions_VerificationState)
proto2.util.setEnumType(ExtensionRangeOptions_VerificationState, "google.protobuf.ExtensionRangeOptions.VerificationState", [
    { no: 0, name: "DECLARATION" },
    { no: 1, name: "UNVERIFIED" },
]);
/**
 * @generated from message google.protobuf.ExtensionRangeOptions.Declaration
 */
export class ExtensionRangeOptions_Declaration extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ExtensionRangeOptions_Declaration().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ExtensionRangeOptions_Declaration().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ExtensionRangeOptions_Declaration().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(ExtensionRangeOptions_Declaration, a, b);
    }
}
ExtensionRangeOptions_Declaration.runtime = proto2;
ExtensionRangeOptions_Declaration.typeName = "google.protobuf.ExtensionRangeOptions.Declaration";
ExtensionRangeOptions_Declaration.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "reserved", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "repeated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
]);
/**
 * Describes a field within a message.
 *
 * @generated from message google.protobuf.FieldDescriptorProto
 */
export class FieldDescriptorProto extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FieldDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FieldDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FieldDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FieldDescriptorProto, a, b);
    }
}
FieldDescriptorProto.runtime = proto2;
FieldDescriptorProto.typeName = "google.protobuf.FieldDescriptorProto";
FieldDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "label", kind: "enum", T: proto2.getEnumType(FieldDescriptorProto_Label), opt: true },
    { no: 5, name: "type", kind: "enum", T: proto2.getEnumType(FieldDescriptorProto_Type), opt: true },
    { no: 6, name: "type_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "extendee", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "default_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "oneof_index", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "json_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "options", kind: "message", T: FieldOptions, opt: true },
    { no: 17, name: "proto3_optional", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
]);
/**
 * @generated from enum google.protobuf.FieldDescriptorProto.Type
 */
export var FieldDescriptorProto_Type;
(function (FieldDescriptorProto_Type) {
    /**
     * 0 is reserved for errors.
     * Order is weird for historical reasons.
     *
     * @generated from enum value: TYPE_DOUBLE = 1;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["DOUBLE"] = 1] = "DOUBLE";
    /**
     * @generated from enum value: TYPE_FLOAT = 2;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["FLOAT"] = 2] = "FLOAT";
    /**
     * Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT64 if
     * negative values are likely.
     *
     * @generated from enum value: TYPE_INT64 = 3;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["INT64"] = 3] = "INT64";
    /**
     * @generated from enum value: TYPE_UINT64 = 4;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["UINT64"] = 4] = "UINT64";
    /**
     * Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT32 if
     * negative values are likely.
     *
     * @generated from enum value: TYPE_INT32 = 5;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["INT32"] = 5] = "INT32";
    /**
     * @generated from enum value: TYPE_FIXED64 = 6;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["FIXED64"] = 6] = "FIXED64";
    /**
     * @generated from enum value: TYPE_FIXED32 = 7;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["FIXED32"] = 7] = "FIXED32";
    /**
     * @generated from enum value: TYPE_BOOL = 8;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["BOOL"] = 8] = "BOOL";
    /**
     * @generated from enum value: TYPE_STRING = 9;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["STRING"] = 9] = "STRING";
    /**
     * Tag-delimited aggregate.
     * Group type is deprecated and not supported after google.protobuf. However, Proto3
     * implementations should still be able to parse the group wire format and
     * treat group fields as unknown fields.  In Editions, the group wire format
     * can be enabled via the `message_encoding` feature.
     *
     * @generated from enum value: TYPE_GROUP = 10;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["GROUP"] = 10] = "GROUP";
    /**
     * Length-delimited aggregate.
     *
     * @generated from enum value: TYPE_MESSAGE = 11;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["MESSAGE"] = 11] = "MESSAGE";
    /**
     * New in version 2.
     *
     * @generated from enum value: TYPE_BYTES = 12;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["BYTES"] = 12] = "BYTES";
    /**
     * @generated from enum value: TYPE_UINT32 = 13;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["UINT32"] = 13] = "UINT32";
    /**
     * @generated from enum value: TYPE_ENUM = 14;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["ENUM"] = 14] = "ENUM";
    /**
     * @generated from enum value: TYPE_SFIXED32 = 15;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["SFIXED32"] = 15] = "SFIXED32";
    /**
     * @generated from enum value: TYPE_SFIXED64 = 16;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["SFIXED64"] = 16] = "SFIXED64";
    /**
     * Uses ZigZag encoding.
     *
     * @generated from enum value: TYPE_SINT32 = 17;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["SINT32"] = 17] = "SINT32";
    /**
     * Uses ZigZag encoding.
     *
     * @generated from enum value: TYPE_SINT64 = 18;
     */
    FieldDescriptorProto_Type[FieldDescriptorProto_Type["SINT64"] = 18] = "SINT64";
})(FieldDescriptorProto_Type || (FieldDescriptorProto_Type = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldDescriptorProto_Type)
proto2.util.setEnumType(FieldDescriptorProto_Type, "google.protobuf.FieldDescriptorProto.Type", [
    { no: 1, name: "TYPE_DOUBLE" },
    { no: 2, name: "TYPE_FLOAT" },
    { no: 3, name: "TYPE_INT64" },
    { no: 4, name: "TYPE_UINT64" },
    { no: 5, name: "TYPE_INT32" },
    { no: 6, name: "TYPE_FIXED64" },
    { no: 7, name: "TYPE_FIXED32" },
    { no: 8, name: "TYPE_BOOL" },
    { no: 9, name: "TYPE_STRING" },
    { no: 10, name: "TYPE_GROUP" },
    { no: 11, name: "TYPE_MESSAGE" },
    { no: 12, name: "TYPE_BYTES" },
    { no: 13, name: "TYPE_UINT32" },
    { no: 14, name: "TYPE_ENUM" },
    { no: 15, name: "TYPE_SFIXED32" },
    { no: 16, name: "TYPE_SFIXED64" },
    { no: 17, name: "TYPE_SINT32" },
    { no: 18, name: "TYPE_SINT64" },
]);
/**
 * @generated from enum google.protobuf.FieldDescriptorProto.Label
 */
export var FieldDescriptorProto_Label;
(function (FieldDescriptorProto_Label) {
    /**
     * 0 is reserved for errors
     *
     * @generated from enum value: LABEL_OPTIONAL = 1;
     */
    FieldDescriptorProto_Label[FieldDescriptorProto_Label["OPTIONAL"] = 1] = "OPTIONAL";
    /**
     * @generated from enum value: LABEL_REPEATED = 3;
     */
    FieldDescriptorProto_Label[FieldDescriptorProto_Label["REPEATED"] = 3] = "REPEATED";
    /**
     * The required label is only allowed in google.protobuf.  In proto3 and Editions
     * it's explicitly prohibited.  In Editions, the `field_presence` feature
     * can be used to get this behavior.
     *
     * @generated from enum value: LABEL_REQUIRED = 2;
     */
    FieldDescriptorProto_Label[FieldDescriptorProto_Label["REQUIRED"] = 2] = "REQUIRED";
})(FieldDescriptorProto_Label || (FieldDescriptorProto_Label = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldDescriptorProto_Label)
proto2.util.setEnumType(FieldDescriptorProto_Label, "google.protobuf.FieldDescriptorProto.Label", [
    { no: 1, name: "LABEL_OPTIONAL" },
    { no: 3, name: "LABEL_REPEATED" },
    { no: 2, name: "LABEL_REQUIRED" },
]);
/**
 * Describes a oneof.
 *
 * @generated from message google.protobuf.OneofDescriptorProto
 */
export class OneofDescriptorProto extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new OneofDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OneofDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OneofDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(OneofDescriptorProto, a, b);
    }
}
OneofDescriptorProto.runtime = proto2;
OneofDescriptorProto.typeName = "google.protobuf.OneofDescriptorProto";
OneofDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "options", kind: "message", T: OneofOptions, opt: true },
]);
/**
 * Describes an enum type.
 *
 * @generated from message google.protobuf.EnumDescriptorProto
 */
export class EnumDescriptorProto extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.EnumValueDescriptorProto value = 2;
         */
        this.value = [];
        /**
         * Range of reserved numeric values. Reserved numeric values may not be used
         * by enum values in the same enum declaration. Reserved ranges may not
         * overlap.
         *
         * @generated from field: repeated google.protobuf.EnumDescriptorProto.EnumReservedRange reserved_range = 4;
         */
        this.reservedRange = [];
        /**
         * Reserved enum value names, which may not be reused. A given name may only
         * be reserved once.
         *
         * @generated from field: repeated string reserved_name = 5;
         */
        this.reservedName = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(EnumDescriptorProto, a, b);
    }
}
EnumDescriptorProto.runtime = proto2;
EnumDescriptorProto.typeName = "google.protobuf.EnumDescriptorProto";
EnumDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "value", kind: "message", T: EnumValueDescriptorProto, repeated: true },
    { no: 3, name: "options", kind: "message", T: EnumOptions, opt: true },
    { no: 4, name: "reserved_range", kind: "message", T: EnumDescriptorProto_EnumReservedRange, repeated: true },
    { no: 5, name: "reserved_name", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * Range of reserved numeric values. Reserved values may not be used by
 * entries in the same enum. Reserved ranges may not overlap.
 *
 * Note that this is distinct from DescriptorProto.ReservedRange in that it
 * is inclusive such that it can appropriately represent the entire int32
 * domain.
 *
 * @generated from message google.protobuf.EnumDescriptorProto.EnumReservedRange
 */
export class EnumDescriptorProto_EnumReservedRange extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumDescriptorProto_EnumReservedRange().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumDescriptorProto_EnumReservedRange().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumDescriptorProto_EnumReservedRange().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(EnumDescriptorProto_EnumReservedRange, a, b);
    }
}
EnumDescriptorProto_EnumReservedRange.runtime = proto2;
EnumDescriptorProto_EnumReservedRange.typeName = "google.protobuf.EnumDescriptorProto.EnumReservedRange";
EnumDescriptorProto_EnumReservedRange.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
]);
/**
 * Describes a value within an enum.
 *
 * @generated from message google.protobuf.EnumValueDescriptorProto
 */
export class EnumValueDescriptorProto extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumValueDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumValueDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumValueDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(EnumValueDescriptorProto, a, b);
    }
}
EnumValueDescriptorProto.runtime = proto2;
EnumValueDescriptorProto.typeName = "google.protobuf.EnumValueDescriptorProto";
EnumValueDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "options", kind: "message", T: EnumValueOptions, opt: true },
]);
/**
 * Describes a service.
 *
 * @generated from message google.protobuf.ServiceDescriptorProto
 */
export class ServiceDescriptorProto extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.MethodDescriptorProto method = 2;
         */
        this.method = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ServiceDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ServiceDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ServiceDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(ServiceDescriptorProto, a, b);
    }
}
ServiceDescriptorProto.runtime = proto2;
ServiceDescriptorProto.typeName = "google.protobuf.ServiceDescriptorProto";
ServiceDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "method", kind: "message", T: MethodDescriptorProto, repeated: true },
    { no: 3, name: "options", kind: "message", T: ServiceOptions, opt: true },
]);
/**
 * Describes a method of a service.
 *
 * @generated from message google.protobuf.MethodDescriptorProto
 */
export class MethodDescriptorProto extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new MethodDescriptorProto().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MethodDescriptorProto().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MethodDescriptorProto().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(MethodDescriptorProto, a, b);
    }
}
MethodDescriptorProto.runtime = proto2;
MethodDescriptorProto.typeName = "google.protobuf.MethodDescriptorProto";
MethodDescriptorProto.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "input_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "output_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "options", kind: "message", T: MethodOptions, opt: true },
    { no: 5, name: "client_streaming", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 6, name: "server_streaming", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
]);
/**
 * @generated from message google.protobuf.FileOptions
 */
export class FileOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here.
         * See the documentation for the "Options" section above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FileOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FileOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FileOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FileOptions, a, b);
    }
}
FileOptions.runtime = proto2;
FileOptions.typeName = "google.protobuf.FileOptions";
FileOptions.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "java_package", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "java_outer_classname", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "java_multiple_files", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 20, name: "java_generate_equals_and_hash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "java_string_check_utf8", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 9, name: "optimize_for", kind: "enum", T: proto2.getEnumType(FileOptions_OptimizeMode), opt: true, default: FileOptions_OptimizeMode.SPEED },
    { no: 11, name: "go_package", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "cc_generic_services", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 17, name: "java_generic_services", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 18, name: "py_generic_services", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 42, name: "php_generic_services", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 23, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 31, name: "cc_enable_arenas", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: true },
    { no: 36, name: "objc_class_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 37, name: "csharp_namespace", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "swift_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 40, name: "php_class_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 41, name: "php_namespace", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 44, name: "php_metadata_namespace", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 45, name: "ruby_package", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 50, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * Generated classes can be optimized for speed or code size.
 *
 * @generated from enum google.protobuf.FileOptions.OptimizeMode
 */
export var FileOptions_OptimizeMode;
(function (FileOptions_OptimizeMode) {
    /**
     * Generate complete code for parsing, serialization,
     *
     * @generated from enum value: SPEED = 1;
     */
    FileOptions_OptimizeMode[FileOptions_OptimizeMode["SPEED"] = 1] = "SPEED";
    /**
     * etc.
     *
     * Use ReflectionOps to implement these methods.
     *
     * @generated from enum value: CODE_SIZE = 2;
     */
    FileOptions_OptimizeMode[FileOptions_OptimizeMode["CODE_SIZE"] = 2] = "CODE_SIZE";
    /**
     * Generate code using MessageLite and the lite runtime.
     *
     * @generated from enum value: LITE_RUNTIME = 3;
     */
    FileOptions_OptimizeMode[FileOptions_OptimizeMode["LITE_RUNTIME"] = 3] = "LITE_RUNTIME";
})(FileOptions_OptimizeMode || (FileOptions_OptimizeMode = {}));
// Retrieve enum metadata with: proto2.getEnumType(FileOptions_OptimizeMode)
proto2.util.setEnumType(FileOptions_OptimizeMode, "google.protobuf.FileOptions.OptimizeMode", [
    { no: 1, name: "SPEED" },
    { no: 2, name: "CODE_SIZE" },
    { no: 3, name: "LITE_RUNTIME" },
]);
/**
 * @generated from message google.protobuf.MessageOptions
 */
export class MessageOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new MessageOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MessageOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MessageOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(MessageOptions, a, b);
    }
}
MessageOptions.runtime = proto2;
MessageOptions.typeName = "google.protobuf.MessageOptions";
MessageOptions.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "message_set_wire_format", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 2, name: "no_standard_descriptor_accessor", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 3, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 7, name: "map_entry", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "deprecated_legacy_json_field_conflicts", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 12, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from message google.protobuf.FieldOptions
 */
export class FieldOptions extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.FieldOptions.OptionTargetType targets = 19;
         */
        this.targets = [];
        /**
         * @generated from field: repeated google.protobuf.FieldOptions.EditionDefault edition_defaults = 20;
         */
        this.editionDefaults = [];
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FieldOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FieldOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FieldOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FieldOptions, a, b);
    }
}
FieldOptions.runtime = proto2;
FieldOptions.typeName = "google.protobuf.FieldOptions";
FieldOptions.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "ctype", kind: "enum", T: proto2.getEnumType(FieldOptions_CType), opt: true, default: FieldOptions_CType.STRING },
    { no: 2, name: "packed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "jstype", kind: "enum", T: proto2.getEnumType(FieldOptions_JSType), opt: true, default: FieldOptions_JSType.JS_NORMAL },
    { no: 5, name: "lazy", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 15, name: "unverified_lazy", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 3, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 10, name: "weak", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 16, name: "debug_redact", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 17, name: "retention", kind: "enum", T: proto2.getEnumType(FieldOptions_OptionRetention), opt: true },
    { no: 19, name: "targets", kind: "enum", T: proto2.getEnumType(FieldOptions_OptionTargetType), repeated: true },
    { no: 20, name: "edition_defaults", kind: "message", T: FieldOptions_EditionDefault, repeated: true },
    { no: 21, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from enum google.protobuf.FieldOptions.CType
 */
export var FieldOptions_CType;
(function (FieldOptions_CType) {
    /**
     * Default mode.
     *
     * @generated from enum value: STRING = 0;
     */
    FieldOptions_CType[FieldOptions_CType["STRING"] = 0] = "STRING";
    /**
     * The option [ctype=CORD] may be applied to a non-repeated field of type
     * "bytes". It indicates that in C++, the data should be stored in a Cord
     * instead of a string.  For very large strings, this may reduce memory
     * fragmentation. It may also allow better performance when parsing from a
     * Cord, or when parsing with aliasing enabled, as the parsed Cord may then
     * alias the original buffer.
     *
     * @generated from enum value: CORD = 1;
     */
    FieldOptions_CType[FieldOptions_CType["CORD"] = 1] = "CORD";
    /**
     * @generated from enum value: STRING_PIECE = 2;
     */
    FieldOptions_CType[FieldOptions_CType["STRING_PIECE"] = 2] = "STRING_PIECE";
})(FieldOptions_CType || (FieldOptions_CType = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldOptions_CType)
proto2.util.setEnumType(FieldOptions_CType, "google.protobuf.FieldOptions.CType", [
    { no: 0, name: "STRING" },
    { no: 1, name: "CORD" },
    { no: 2, name: "STRING_PIECE" },
]);
/**
 * @generated from enum google.protobuf.FieldOptions.JSType
 */
export var FieldOptions_JSType;
(function (FieldOptions_JSType) {
    /**
     * Use the default type.
     *
     * @generated from enum value: JS_NORMAL = 0;
     */
    FieldOptions_JSType[FieldOptions_JSType["JS_NORMAL"] = 0] = "JS_NORMAL";
    /**
     * Use JavaScript strings.
     *
     * @generated from enum value: JS_STRING = 1;
     */
    FieldOptions_JSType[FieldOptions_JSType["JS_STRING"] = 1] = "JS_STRING";
    /**
     * Use JavaScript numbers.
     *
     * @generated from enum value: JS_NUMBER = 2;
     */
    FieldOptions_JSType[FieldOptions_JSType["JS_NUMBER"] = 2] = "JS_NUMBER";
})(FieldOptions_JSType || (FieldOptions_JSType = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldOptions_JSType)
proto2.util.setEnumType(FieldOptions_JSType, "google.protobuf.FieldOptions.JSType", [
    { no: 0, name: "JS_NORMAL" },
    { no: 1, name: "JS_STRING" },
    { no: 2, name: "JS_NUMBER" },
]);
/**
 * If set to RETENTION_SOURCE, the option will be omitted from the binary.
 * Note: as of January 2023, support for this is in progress and does not yet
 * have an effect (b/264593489).
 *
 * @generated from enum google.protobuf.FieldOptions.OptionRetention
 */
export var FieldOptions_OptionRetention;
(function (FieldOptions_OptionRetention) {
    /**
     * @generated from enum value: RETENTION_UNKNOWN = 0;
     */
    FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_UNKNOWN"] = 0] = "RETENTION_UNKNOWN";
    /**
     * @generated from enum value: RETENTION_RUNTIME = 1;
     */
    FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_RUNTIME"] = 1] = "RETENTION_RUNTIME";
    /**
     * @generated from enum value: RETENTION_SOURCE = 2;
     */
    FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_SOURCE"] = 2] = "RETENTION_SOURCE";
})(FieldOptions_OptionRetention || (FieldOptions_OptionRetention = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldOptions_OptionRetention)
proto2.util.setEnumType(FieldOptions_OptionRetention, "google.protobuf.FieldOptions.OptionRetention", [
    { no: 0, name: "RETENTION_UNKNOWN" },
    { no: 1, name: "RETENTION_RUNTIME" },
    { no: 2, name: "RETENTION_SOURCE" },
]);
/**
 * This indicates the types of entities that the field may apply to when used
 * as an option. If it is unset, then the field may be freely used as an
 * option on any kind of entity. Note: as of January 2023, support for this is
 * in progress and does not yet have an effect (b/264593489).
 *
 * @generated from enum google.protobuf.FieldOptions.OptionTargetType
 */
export var FieldOptions_OptionTargetType;
(function (FieldOptions_OptionTargetType) {
    /**
     * @generated from enum value: TARGET_TYPE_UNKNOWN = 0;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_UNKNOWN"] = 0] = "TARGET_TYPE_UNKNOWN";
    /**
     * @generated from enum value: TARGET_TYPE_FILE = 1;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_FILE"] = 1] = "TARGET_TYPE_FILE";
    /**
     * @generated from enum value: TARGET_TYPE_EXTENSION_RANGE = 2;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_EXTENSION_RANGE"] = 2] = "TARGET_TYPE_EXTENSION_RANGE";
    /**
     * @generated from enum value: TARGET_TYPE_MESSAGE = 3;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_MESSAGE"] = 3] = "TARGET_TYPE_MESSAGE";
    /**
     * @generated from enum value: TARGET_TYPE_FIELD = 4;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_FIELD"] = 4] = "TARGET_TYPE_FIELD";
    /**
     * @generated from enum value: TARGET_TYPE_ONEOF = 5;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ONEOF"] = 5] = "TARGET_TYPE_ONEOF";
    /**
     * @generated from enum value: TARGET_TYPE_ENUM = 6;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ENUM"] = 6] = "TARGET_TYPE_ENUM";
    /**
     * @generated from enum value: TARGET_TYPE_ENUM_ENTRY = 7;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ENUM_ENTRY"] = 7] = "TARGET_TYPE_ENUM_ENTRY";
    /**
     * @generated from enum value: TARGET_TYPE_SERVICE = 8;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_SERVICE"] = 8] = "TARGET_TYPE_SERVICE";
    /**
     * @generated from enum value: TARGET_TYPE_METHOD = 9;
     */
    FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_METHOD"] = 9] = "TARGET_TYPE_METHOD";
})(FieldOptions_OptionTargetType || (FieldOptions_OptionTargetType = {}));
// Retrieve enum metadata with: proto2.getEnumType(FieldOptions_OptionTargetType)
proto2.util.setEnumType(FieldOptions_OptionTargetType, "google.protobuf.FieldOptions.OptionTargetType", [
    { no: 0, name: "TARGET_TYPE_UNKNOWN" },
    { no: 1, name: "TARGET_TYPE_FILE" },
    { no: 2, name: "TARGET_TYPE_EXTENSION_RANGE" },
    { no: 3, name: "TARGET_TYPE_MESSAGE" },
    { no: 4, name: "TARGET_TYPE_FIELD" },
    { no: 5, name: "TARGET_TYPE_ONEOF" },
    { no: 6, name: "TARGET_TYPE_ENUM" },
    { no: 7, name: "TARGET_TYPE_ENUM_ENTRY" },
    { no: 8, name: "TARGET_TYPE_SERVICE" },
    { no: 9, name: "TARGET_TYPE_METHOD" },
]);
/**
 * @generated from message google.protobuf.FieldOptions.EditionDefault
 */
export class FieldOptions_EditionDefault extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FieldOptions_EditionDefault().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FieldOptions_EditionDefault().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FieldOptions_EditionDefault().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FieldOptions_EditionDefault, a, b);
    }
}
FieldOptions_EditionDefault.runtime = proto2;
FieldOptions_EditionDefault.typeName = "google.protobuf.FieldOptions.EditionDefault";
FieldOptions_EditionDefault.fields = proto2.util.newFieldList(() => [
    { no: 3, name: "edition", kind: "enum", T: proto2.getEnumType(Edition), opt: true },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
]);
/**
 * @generated from message google.protobuf.OneofOptions
 */
export class OneofOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new OneofOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OneofOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OneofOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(OneofOptions, a, b);
    }
}
OneofOptions.runtime = proto2;
OneofOptions.typeName = "google.protobuf.OneofOptions";
OneofOptions.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from message google.protobuf.EnumOptions
 */
export class EnumOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(EnumOptions, a, b);
    }
}
EnumOptions.runtime = proto2;
EnumOptions.typeName = "google.protobuf.EnumOptions";
EnumOptions.fields = proto2.util.newFieldList(() => [
    { no: 2, name: "allow_alias", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 6, name: "deprecated_legacy_json_field_conflicts", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from message google.protobuf.EnumValueOptions
 */
export class EnumValueOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new EnumValueOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnumValueOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnumValueOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(EnumValueOptions, a, b);
    }
}
EnumValueOptions.runtime = proto2;
EnumValueOptions.typeName = "google.protobuf.EnumValueOptions";
EnumValueOptions.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 2, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 3, name: "debug_redact", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from message google.protobuf.ServiceOptions
 */
export class ServiceOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ServiceOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ServiceOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ServiceOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(ServiceOptions, a, b);
    }
}
ServiceOptions.runtime = proto2;
ServiceOptions.typeName = "google.protobuf.ServiceOptions";
ServiceOptions.fields = proto2.util.newFieldList(() => [
    { no: 34, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 33, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * @generated from message google.protobuf.MethodOptions
 */
export class MethodOptions extends Message {
    constructor(data) {
        super();
        /**
         * The parser stores options it doesn't recognize here. See above.
         *
         * @generated from field: repeated google.protobuf.UninterpretedOption uninterpreted_option = 999;
         */
        this.uninterpretedOption = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new MethodOptions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new MethodOptions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new MethodOptions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(MethodOptions, a, b);
    }
}
MethodOptions.runtime = proto2;
MethodOptions.typeName = "google.protobuf.MethodOptions";
MethodOptions.fields = proto2.util.newFieldList(() => [
    { no: 33, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true, default: false },
    { no: 34, name: "idempotency_level", kind: "enum", T: proto2.getEnumType(MethodOptions_IdempotencyLevel), opt: true, default: MethodOptions_IdempotencyLevel.IDEMPOTENCY_UNKNOWN },
    { no: 35, name: "features", kind: "message", T: FeatureSet, opt: true },
    { no: 999, name: "uninterpreted_option", kind: "message", T: UninterpretedOption, repeated: true },
]);
/**
 * Is this method side-effect-free (or safe in HTTP parlance), or idempotent,
 * or neither? HTTP based RPC implementation may choose GET verb for safe
 * methods, and PUT verb for idempotent methods instead of the default POST.
 *
 * @generated from enum google.protobuf.MethodOptions.IdempotencyLevel
 */
export var MethodOptions_IdempotencyLevel;
(function (MethodOptions_IdempotencyLevel) {
    /**
     * @generated from enum value: IDEMPOTENCY_UNKNOWN = 0;
     */
    MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["IDEMPOTENCY_UNKNOWN"] = 0] = "IDEMPOTENCY_UNKNOWN";
    /**
     * implies idempotent
     *
     * @generated from enum value: NO_SIDE_EFFECTS = 1;
     */
    MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["NO_SIDE_EFFECTS"] = 1] = "NO_SIDE_EFFECTS";
    /**
     * idempotent, but may have side effects
     *
     * @generated from enum value: IDEMPOTENT = 2;
     */
    MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["IDEMPOTENT"] = 2] = "IDEMPOTENT";
})(MethodOptions_IdempotencyLevel || (MethodOptions_IdempotencyLevel = {}));
// Retrieve enum metadata with: proto2.getEnumType(MethodOptions_IdempotencyLevel)
proto2.util.setEnumType(MethodOptions_IdempotencyLevel, "google.protobuf.MethodOptions.IdempotencyLevel", [
    { no: 0, name: "IDEMPOTENCY_UNKNOWN" },
    { no: 1, name: "NO_SIDE_EFFECTS" },
    { no: 2, name: "IDEMPOTENT" },
]);
/**
 * A message representing a option the parser does not recognize. This only
 * appears in options protos created by the compiler::Parser class.
 * DescriptorPool resolves these when building Descriptor objects. Therefore,
 * options protos in descriptor objects (e.g. returned by Descriptor::options(),
 * or produced by Descriptor::CopyTo()) will never have UninterpretedOptions
 * in them.
 *
 * @generated from message google.protobuf.UninterpretedOption
 */
export class UninterpretedOption extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.UninterpretedOption.NamePart name = 2;
         */
        this.name = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UninterpretedOption().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UninterpretedOption().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UninterpretedOption().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(UninterpretedOption, a, b);
    }
}
UninterpretedOption.runtime = proto2;
UninterpretedOption.typeName = "google.protobuf.UninterpretedOption";
UninterpretedOption.fields = proto2.util.newFieldList(() => [
    { no: 2, name: "name", kind: "message", T: UninterpretedOption_NamePart, repeated: true },
    { no: 3, name: "identifier_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "positive_int_value", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 5, name: "negative_int_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "double_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 7, name: "string_value", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 8, name: "aggregate_value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
]);
/**
 * The name of the uninterpreted option.  Each string represents a segment in
 * a dot-separated name.  is_extension is true iff a segment represents an
 * extension (denoted with parentheses in options specs in .proto files).
 * E.g.,{ ["foo", false], ["bar.baz", true], ["moo", false] } represents
 * "foo.(bar.baz).moo".
 *
 * @generated from message google.protobuf.UninterpretedOption.NamePart
 */
export class UninterpretedOption_NamePart extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UninterpretedOption_NamePart().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UninterpretedOption_NamePart().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UninterpretedOption_NamePart().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(UninterpretedOption_NamePart, a, b);
    }
}
UninterpretedOption_NamePart.runtime = proto2;
UninterpretedOption_NamePart.typeName = "google.protobuf.UninterpretedOption.NamePart";
UninterpretedOption_NamePart.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "name_part", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_extension", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
]);
/**
 * TODO Enums in C++ gencode (and potentially other languages) are
 * not well scoped.  This means that each of the feature enums below can clash
 * with each other.  The short names we've chosen maximize call-site
 * readability, but leave us very open to this scenario.  A future feature will
 * be designed and implemented to handle this, hopefully before we ever hit a
 * conflict here.
 *
 * @generated from message google.protobuf.FeatureSet
 */
export class FeatureSet extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FeatureSet().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FeatureSet().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FeatureSet().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FeatureSet, a, b);
    }
}
FeatureSet.runtime = proto2;
FeatureSet.typeName = "google.protobuf.FeatureSet";
FeatureSet.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "field_presence", kind: "enum", T: proto2.getEnumType(FeatureSet_FieldPresence), opt: true },
    { no: 2, name: "enum_type", kind: "enum", T: proto2.getEnumType(FeatureSet_EnumType), opt: true },
    { no: 3, name: "repeated_field_encoding", kind: "enum", T: proto2.getEnumType(FeatureSet_RepeatedFieldEncoding), opt: true },
    { no: 4, name: "utf8_validation", kind: "enum", T: proto2.getEnumType(FeatureSet_Utf8Validation), opt: true },
    { no: 5, name: "message_encoding", kind: "enum", T: proto2.getEnumType(FeatureSet_MessageEncoding), opt: true },
    { no: 6, name: "json_format", kind: "enum", T: proto2.getEnumType(FeatureSet_JsonFormat), opt: true },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.FieldPresence
 */
export var FeatureSet_FieldPresence;
(function (FeatureSet_FieldPresence) {
    /**
     * @generated from enum value: FIELD_PRESENCE_UNKNOWN = 0;
     */
    FeatureSet_FieldPresence[FeatureSet_FieldPresence["FIELD_PRESENCE_UNKNOWN"] = 0] = "FIELD_PRESENCE_UNKNOWN";
    /**
     * @generated from enum value: EXPLICIT = 1;
     */
    FeatureSet_FieldPresence[FeatureSet_FieldPresence["EXPLICIT"] = 1] = "EXPLICIT";
    /**
     * @generated from enum value: IMPLICIT = 2;
     */
    FeatureSet_FieldPresence[FeatureSet_FieldPresence["IMPLICIT"] = 2] = "IMPLICIT";
    /**
     * @generated from enum value: LEGACY_REQUIRED = 3;
     */
    FeatureSet_FieldPresence[FeatureSet_FieldPresence["LEGACY_REQUIRED"] = 3] = "LEGACY_REQUIRED";
})(FeatureSet_FieldPresence || (FeatureSet_FieldPresence = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_FieldPresence)
proto2.util.setEnumType(FeatureSet_FieldPresence, "google.protobuf.FeatureSet.FieldPresence", [
    { no: 0, name: "FIELD_PRESENCE_UNKNOWN" },
    { no: 1, name: "EXPLICIT" },
    { no: 2, name: "IMPLICIT" },
    { no: 3, name: "LEGACY_REQUIRED" },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.EnumType
 */
export var FeatureSet_EnumType;
(function (FeatureSet_EnumType) {
    /**
     * @generated from enum value: ENUM_TYPE_UNKNOWN = 0;
     */
    FeatureSet_EnumType[FeatureSet_EnumType["ENUM_TYPE_UNKNOWN"] = 0] = "ENUM_TYPE_UNKNOWN";
    /**
     * @generated from enum value: OPEN = 1;
     */
    FeatureSet_EnumType[FeatureSet_EnumType["OPEN"] = 1] = "OPEN";
    /**
     * @generated from enum value: CLOSED = 2;
     */
    FeatureSet_EnumType[FeatureSet_EnumType["CLOSED"] = 2] = "CLOSED";
})(FeatureSet_EnumType || (FeatureSet_EnumType = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_EnumType)
proto2.util.setEnumType(FeatureSet_EnumType, "google.protobuf.FeatureSet.EnumType", [
    { no: 0, name: "ENUM_TYPE_UNKNOWN" },
    { no: 1, name: "OPEN" },
    { no: 2, name: "CLOSED" },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.RepeatedFieldEncoding
 */
export var FeatureSet_RepeatedFieldEncoding;
(function (FeatureSet_RepeatedFieldEncoding) {
    /**
     * @generated from enum value: REPEATED_FIELD_ENCODING_UNKNOWN = 0;
     */
    FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["REPEATED_FIELD_ENCODING_UNKNOWN"] = 0] = "REPEATED_FIELD_ENCODING_UNKNOWN";
    /**
     * @generated from enum value: PACKED = 1;
     */
    FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["PACKED"] = 1] = "PACKED";
    /**
     * @generated from enum value: EXPANDED = 2;
     */
    FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["EXPANDED"] = 2] = "EXPANDED";
})(FeatureSet_RepeatedFieldEncoding || (FeatureSet_RepeatedFieldEncoding = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_RepeatedFieldEncoding)
proto2.util.setEnumType(FeatureSet_RepeatedFieldEncoding, "google.protobuf.FeatureSet.RepeatedFieldEncoding", [
    { no: 0, name: "REPEATED_FIELD_ENCODING_UNKNOWN" },
    { no: 1, name: "PACKED" },
    { no: 2, name: "EXPANDED" },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.Utf8Validation
 */
export var FeatureSet_Utf8Validation;
(function (FeatureSet_Utf8Validation) {
    /**
     * @generated from enum value: UTF8_VALIDATION_UNKNOWN = 0;
     */
    FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["UTF8_VALIDATION_UNKNOWN"] = 0] = "UTF8_VALIDATION_UNKNOWN";
    /**
     * @generated from enum value: NONE = 1;
     */
    FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["NONE"] = 1] = "NONE";
    /**
     * @generated from enum value: VERIFY = 2;
     */
    FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["VERIFY"] = 2] = "VERIFY";
})(FeatureSet_Utf8Validation || (FeatureSet_Utf8Validation = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_Utf8Validation)
proto2.util.setEnumType(FeatureSet_Utf8Validation, "google.protobuf.FeatureSet.Utf8Validation", [
    { no: 0, name: "UTF8_VALIDATION_UNKNOWN" },
    { no: 1, name: "NONE" },
    { no: 2, name: "VERIFY" },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.MessageEncoding
 */
export var FeatureSet_MessageEncoding;
(function (FeatureSet_MessageEncoding) {
    /**
     * @generated from enum value: MESSAGE_ENCODING_UNKNOWN = 0;
     */
    FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["MESSAGE_ENCODING_UNKNOWN"] = 0] = "MESSAGE_ENCODING_UNKNOWN";
    /**
     * @generated from enum value: LENGTH_PREFIXED = 1;
     */
    FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["LENGTH_PREFIXED"] = 1] = "LENGTH_PREFIXED";
    /**
     * @generated from enum value: DELIMITED = 2;
     */
    FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["DELIMITED"] = 2] = "DELIMITED";
})(FeatureSet_MessageEncoding || (FeatureSet_MessageEncoding = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_MessageEncoding)
proto2.util.setEnumType(FeatureSet_MessageEncoding, "google.protobuf.FeatureSet.MessageEncoding", [
    { no: 0, name: "MESSAGE_ENCODING_UNKNOWN" },
    { no: 1, name: "LENGTH_PREFIXED" },
    { no: 2, name: "DELIMITED" },
]);
/**
 * @generated from enum google.protobuf.FeatureSet.JsonFormat
 */
export var FeatureSet_JsonFormat;
(function (FeatureSet_JsonFormat) {
    /**
     * @generated from enum value: JSON_FORMAT_UNKNOWN = 0;
     */
    FeatureSet_JsonFormat[FeatureSet_JsonFormat["JSON_FORMAT_UNKNOWN"] = 0] = "JSON_FORMAT_UNKNOWN";
    /**
     * @generated from enum value: ALLOW = 1;
     */
    FeatureSet_JsonFormat[FeatureSet_JsonFormat["ALLOW"] = 1] = "ALLOW";
    /**
     * @generated from enum value: LEGACY_BEST_EFFORT = 2;
     */
    FeatureSet_JsonFormat[FeatureSet_JsonFormat["LEGACY_BEST_EFFORT"] = 2] = "LEGACY_BEST_EFFORT";
})(FeatureSet_JsonFormat || (FeatureSet_JsonFormat = {}));
// Retrieve enum metadata with: proto2.getEnumType(FeatureSet_JsonFormat)
proto2.util.setEnumType(FeatureSet_JsonFormat, "google.protobuf.FeatureSet.JsonFormat", [
    { no: 0, name: "JSON_FORMAT_UNKNOWN" },
    { no: 1, name: "ALLOW" },
    { no: 2, name: "LEGACY_BEST_EFFORT" },
]);
/**
 * A compiled specification for the defaults of a set of features.  These
 * messages are generated from FeatureSet extensions and can be used to seed
 * feature resolution. The resolution with this object becomes a simple search
 * for the closest matching edition, followed by proto merges.
 *
 * @generated from message google.protobuf.FeatureSetDefaults
 */
export class FeatureSetDefaults extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault defaults = 1;
         */
        this.defaults = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FeatureSetDefaults().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FeatureSetDefaults().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FeatureSetDefaults().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FeatureSetDefaults, a, b);
    }
}
FeatureSetDefaults.runtime = proto2;
FeatureSetDefaults.typeName = "google.protobuf.FeatureSetDefaults";
FeatureSetDefaults.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "defaults", kind: "message", T: FeatureSetDefaults_FeatureSetEditionDefault, repeated: true },
    { no: 4, name: "minimum_edition", kind: "enum", T: proto2.getEnumType(Edition), opt: true },
    { no: 5, name: "maximum_edition", kind: "enum", T: proto2.getEnumType(Edition), opt: true },
]);
/**
 * A map from every known edition with a unique set of defaults to its
 * defaults. Not all editions may be contained here.  For a given edition,
 * the defaults at the closest matching edition ordered at or before it should
 * be used.  This field must be in strict ascending order by edition.
 *
 * @generated from message google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault
 */
export class FeatureSetDefaults_FeatureSetEditionDefault extends Message {
    constructor(data) {
        super();
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new FeatureSetDefaults_FeatureSetEditionDefault().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FeatureSetDefaults_FeatureSetEditionDefault().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FeatureSetDefaults_FeatureSetEditionDefault().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(FeatureSetDefaults_FeatureSetEditionDefault, a, b);
    }
}
FeatureSetDefaults_FeatureSetEditionDefault.runtime = proto2;
FeatureSetDefaults_FeatureSetEditionDefault.typeName = "google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault";
FeatureSetDefaults_FeatureSetEditionDefault.fields = proto2.util.newFieldList(() => [
    { no: 3, name: "edition", kind: "enum", T: proto2.getEnumType(Edition), opt: true },
    { no: 2, name: "features", kind: "message", T: FeatureSet, opt: true },
]);
/**
 * Encapsulates information about the original source file from which a
 * FileDescriptorProto was generated.
 *
 * @generated from message google.protobuf.SourceCodeInfo
 */
export class SourceCodeInfo extends Message {
    constructor(data) {
        super();
        /**
         * A Location identifies a piece of source code in a .proto file which
         * corresponds to a particular definition.  This information is intended
         * to be useful to IDEs, code indexers, documentation generators, and similar
         * tools.
         *
         * For example, say we have a file like:
         *   message Foo {
         *     optional string foo = 1;
         *   }
         * Let's look at just the field definition:
         *   optional string foo = 1;
         *   ^       ^^     ^^  ^  ^^^
         *   a       bc     de  f  ghi
         * We have the following locations:
         *   span   path               represents
         *   [a,i)  [ 4, 0, 2, 0 ]     The whole field definition.
         *   [a,b)  [ 4, 0, 2, 0, 4 ]  The label (optional).
         *   [c,d)  [ 4, 0, 2, 0, 5 ]  The type (string).
         *   [e,f)  [ 4, 0, 2, 0, 1 ]  The name (foo).
         *   [g,h)  [ 4, 0, 2, 0, 3 ]  The number (1).
         *
         * Notes:
         * - A location may refer to a repeated field itself (i.e. not to any
         *   particular index within it).  This is used whenever a set of elements are
         *   logically enclosed in a single code segment.  For example, an entire
         *   extend block (possibly containing multiple extension definitions) will
         *   have an outer location whose path refers to the "extensions" repeated
         *   field without an index.
         * - Multiple locations may have the same path.  This happens when a single
         *   logical declaration is spread out across multiple places.  The most
         *   obvious example is the "extend" block again -- there may be multiple
         *   extend blocks in the same scope, each of which will have the same path.
         * - A location's span is not always a subset of its parent's span.  For
         *   example, the "extendee" of an extension declaration appears at the
         *   beginning of the "extend" block and is shared by all extensions within
         *   the block.
         * - Just because a location's span is a subset of some other location's span
         *   does not mean that it is a descendant.  For example, a "group" defines
         *   both a type and a field in a single declaration.  Thus, the locations
         *   corresponding to the type and field and their components will overlap.
         * - Code which tries to interpret locations should probably be designed to
         *   ignore those that it doesn't understand, as more types of locations could
         *   be recorded in the future.
         *
         * @generated from field: repeated google.protobuf.SourceCodeInfo.Location location = 1;
         */
        this.location = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SourceCodeInfo().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SourceCodeInfo().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SourceCodeInfo().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(SourceCodeInfo, a, b);
    }
}
SourceCodeInfo.runtime = proto2;
SourceCodeInfo.typeName = "google.protobuf.SourceCodeInfo";
SourceCodeInfo.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "location", kind: "message", T: SourceCodeInfo_Location, repeated: true },
]);
/**
 * @generated from message google.protobuf.SourceCodeInfo.Location
 */
export class SourceCodeInfo_Location extends Message {
    constructor(data) {
        super();
        /**
         * Identifies which part of the FileDescriptorProto was defined at this
         * location.
         *
         * Each element is a field number or an index.  They form a path from
         * the root FileDescriptorProto to the place where the definition occurs.
         * For example, this path:
         *   [ 4, 3, 2, 7, 1 ]
         * refers to:
         *   file.message_type(3)  // 4, 3
         *       .field(7)         // 2, 7
         *       .name()           // 1
         * This is because FileDescriptorProto.message_type has field number 4:
         *   repeated DescriptorProto message_type = 4;
         * and DescriptorProto.field has field number 2:
         *   repeated FieldDescriptorProto field = 2;
         * and FieldDescriptorProto.name has field number 1:
         *   optional string name = 1;
         *
         * Thus, the above path gives the location of a field name.  If we removed
         * the last element:
         *   [ 4, 3, 2, 7 ]
         * this path refers to the whole field declaration (from the beginning
         * of the label to the terminating semicolon).
         *
         * @generated from field: repeated int32 path = 1 [packed = true];
         */
        this.path = [];
        /**
         * Always has exactly three or four elements: start line, start column,
         * end line (optional, otherwise assumed same as start line), end column.
         * These are packed into a single field for efficiency.  Note that line
         * and column numbers are zero-based -- typically you will want to add
         * 1 to each before displaying to a user.
         *
         * @generated from field: repeated int32 span = 2 [packed = true];
         */
        this.span = [];
        /**
         * @generated from field: repeated string leading_detached_comments = 6;
         */
        this.leadingDetachedComments = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SourceCodeInfo_Location().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SourceCodeInfo_Location().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SourceCodeInfo_Location().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(SourceCodeInfo_Location, a, b);
    }
}
SourceCodeInfo_Location.runtime = proto2;
SourceCodeInfo_Location.typeName = "google.protobuf.SourceCodeInfo.Location";
SourceCodeInfo_Location.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true, packed: true },
    { no: 2, name: "span", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true, packed: true },
    { no: 3, name: "leading_comments", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "trailing_comments", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "leading_detached_comments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * Describes the relationship between generated code and its original source
 * file. A GeneratedCodeInfo message is associated with only one generated
 * source file, but may contain references to different source .proto files.
 *
 * @generated from message google.protobuf.GeneratedCodeInfo
 */
export class GeneratedCodeInfo extends Message {
    constructor(data) {
        super();
        /**
         * An Annotation connects some span of text in generated code to an element
         * of its generating .proto file.
         *
         * @generated from field: repeated google.protobuf.GeneratedCodeInfo.Annotation annotation = 1;
         */
        this.annotation = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GeneratedCodeInfo().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneratedCodeInfo().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneratedCodeInfo().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(GeneratedCodeInfo, a, b);
    }
}
GeneratedCodeInfo.runtime = proto2;
GeneratedCodeInfo.typeName = "google.protobuf.GeneratedCodeInfo";
GeneratedCodeInfo.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "annotation", kind: "message", T: GeneratedCodeInfo_Annotation, repeated: true },
]);
/**
 * @generated from message google.protobuf.GeneratedCodeInfo.Annotation
 */
export class GeneratedCodeInfo_Annotation extends Message {
    constructor(data) {
        super();
        /**
         * Identifies the element in the original source .proto file. This field
         * is formatted the same as SourceCodeInfo.Location.path.
         *
         * @generated from field: repeated int32 path = 1 [packed = true];
         */
        this.path = [];
        proto2.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GeneratedCodeInfo_Annotation().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GeneratedCodeInfo_Annotation().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GeneratedCodeInfo_Annotation().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto2.util.equals(GeneratedCodeInfo_Annotation, a, b);
    }
}
GeneratedCodeInfo_Annotation.runtime = proto2;
GeneratedCodeInfo_Annotation.typeName = "google.protobuf.GeneratedCodeInfo.Annotation";
GeneratedCodeInfo_Annotation.fields = proto2.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true, packed: true },
    { no: 2, name: "source_file", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "begin", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "semantic", kind: "enum", T: proto2.getEnumType(GeneratedCodeInfo_Annotation_Semantic), opt: true },
]);
/**
 * Represents the identified object's effect on the element in the original
 * .proto file.
 *
 * @generated from enum google.protobuf.GeneratedCodeInfo.Annotation.Semantic
 */
export var GeneratedCodeInfo_Annotation_Semantic;
(function (GeneratedCodeInfo_Annotation_Semantic) {
    /**
     * There is no effect or the effect is indescribable.
     *
     * @generated from enum value: NONE = 0;
     */
    GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["NONE"] = 0] = "NONE";
    /**
     * The element is set or otherwise mutated.
     *
     * @generated from enum value: SET = 1;
     */
    GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["SET"] = 1] = "SET";
    /**
     * An alias to the element is returned.
     *
     * @generated from enum value: ALIAS = 2;
     */
    GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["ALIAS"] = 2] = "ALIAS";
})(GeneratedCodeInfo_Annotation_Semantic || (GeneratedCodeInfo_Annotation_Semantic = {}));
// Retrieve enum metadata with: proto2.getEnumType(GeneratedCodeInfo_Annotation_Semantic)
proto2.util.setEnumType(GeneratedCodeInfo_Annotation_Semantic, "google.protobuf.GeneratedCodeInfo.Annotation.Semantic", [
    { no: 0, name: "NONE" },
    { no: 1, name: "SET" },
    { no: 2, name: "ALIAS" },
]);
