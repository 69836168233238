// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=ts"
// @generated from file polling_stations.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PollingStation, PollingStationPaginationResp, PollingStationsList, PollingStationsServiceCreateRequest, PollingStationsServiceFilterReq, PollingStationsServicePaginationReq, PollingStationsServiceSearchAllReq, PollingStationsServiceUpdateRequest } from "./polling_stations_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { ActiveRequest, CountRequest, CountResponse, CSVImport, ErroredResponse, Identifier, IdentifierWithUserComment, StandardFile } from "./base_pb.js";

/**
 *
 * Describes the methods applicable on each polling station
 *
 * @generated from service nail2poll.PollingStationsService
 */
export const PollingStationsService = {
  typeName: "nail2poll.PollingStationsService",
  methods: {
    /**
     * Create a polling station
     *
     * @generated from rpc nail2poll.PollingStationsService.Create
     */
    create: {
      name: "Create",
      I: PollingStationsServiceCreateRequest,
      O: PollingStation,
      kind: MethodKind.Unary,
    },
    /**
     * Update a polling station
     *
     * @generated from rpc nail2poll.PollingStationsService.Update
     */
    update: {
      name: "Update",
      I: PollingStationsServiceUpdateRequest,
      O: PollingStation,
      kind: MethodKind.Unary,
    },
    /**
     * Archive the polling station
     *
     * @generated from rpc nail2poll.PollingStationsService.Archive
     */
    archive: {
      name: "Archive",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * Restore the polling station
     *
     * @generated from rpc nail2poll.PollingStationsService.Restore
     */
    restore: {
      name: "Restore",
      I: IdentifierWithUserComment,
      O: Identifier,
      kind: MethodKind.Unary,
    },
    /**
     * View by UUID
     *
     * @generated from rpc nail2poll.PollingStationsService.ViewByUUID
     */
    viewByUUID: {
      name: "ViewByUUID",
      I: Identifier,
      O: PollingStation,
      kind: MethodKind.Unary,
    },
    /**
     * View all polling stations
     *
     * @generated from rpc nail2poll.PollingStationsService.ViewAll
     */
    viewAll: {
      name: "ViewAll",
      I: ActiveRequest,
      O: PollingStationsList,
      kind: MethodKind.Unary,
    },
    /**
     * View polling stations with pagination
     *
     * @generated from rpc nail2poll.PollingStationsService.ViewWithPagination
     */
    viewWithPagination: {
      name: "ViewWithPagination",
      I: PollingStationsServicePaginationReq,
      O: PollingStationPaginationResp,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given search key
     *
     * @generated from rpc nail2poll.PollingStationsService.SearchAll
     */
    searchAll: {
      name: "SearchAll",
      I: PollingStationsServiceSearchAllReq,
      O: PollingStationsList,
      kind: MethodKind.Unary,
    },
    /**
     * View all that match the given filter criteria
     *
     * @generated from rpc nail2poll.PollingStationsService.Filter
     */
    filter: {
      name: "Filter",
      I: PollingStationsServiceFilterReq,
      O: PollingStationsList,
      kind: MethodKind.Unary,
    },
    /**
     * Count
     *
     * @generated from rpc nail2poll.PollingStationsService.Count
     */
    count: {
      name: "Count",
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ------------------------------------------------------
     * Import and Export operations are listed below
     * Import from CSV file
     *
     * @generated from rpc nail2poll.PollingStationsService.Import
     */
    import: {
      name: "Import",
      I: CSVImport,
      O: ErroredResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export to CSV file
     *
     * @generated from rpc nail2poll.PollingStationsService.Export
     */
    export: {
      name: "Export",
      I: PollingStationsServiceFilterReq,
      O: StandardFile,
      kind: MethodKind.Unary,
    },
  }
} as const;

