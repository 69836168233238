import * as utilities from "./utilities";
import Choices, { Choice } from 'choices.js';
import { protoInt64 } from "@bufbuild/protobuf";
import { VOTER_GENDER, VOTER_RELATION_TYPE } from "./structs/voters_pb";

const IS_SINGLE_CONSTITUENCY = true;

export type INPUTEL = { inputDiv: HTMLDivElement, inputElID: string };

function renderSearchable(id: string, labelTitle: string): HTMLDivElement {
    let div = document.createElement("div");
    div.innerHTML = `
        <label class="label">${labelTitle}</label>
        <select id="${id}">
        </select>
    `
    return div;
}

export function renderInput(labelTitle: string, type: "text" | "number" | "password"): INPUTEL {
    let id = utilities.returnRandomIDForHTMLElement()
    let div = document.createElement("div");
    div.innerHTML = `
        <label class="label">${labelTitle}</label>
        <input class="input" id="${id}" type="${type}">
    `
    return { inputDiv: div, inputElID: id };
}

export function renderDate(labelTitle: string): INPUTEL {
    let id = utilities.returnRandomIDForHTMLElement()
    let div = document.createElement("div");
    div.innerHTML = `
        <label class="label">${labelTitle}</label>
        <div class="control">
            <input readonly id="${id}" class="input __param __date" type="text" placeholder="">
        </div>
    `
    return { inputDiv: div, inputElID: id };
}

/**Renders the polling station searchable */
export function pollingStationsSearchable(parentDiv: HTMLDivElement): string {
    let pollingStationSelectID = utilities.returnRandomIDForHTMLElement();
    let pollingStationSelect = renderSearchable(pollingStationSelectID, "Select Polling Station");

    parentDiv.appendChild(pollingStationSelect);

    setTimeout(() => {
        const pollingStationSearchable = new Choices(document.getElementById(pollingStationSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Polling Stations", disabled: true, }]
        })

        let pollingStationsClient = utilities.getPollingStationsServiceReadClient();
        pollingStationSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await pollingStationsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            pollingStationSearchable.clearChoices();
            pollingStationSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return pollingStationSelectID;
}

/**Renders the religion searchable */
export function religionsSearchable(parentDiv: HTMLDivElement): string {
    let religionSelectID = utilities.returnRandomIDForHTMLElement();
    let religionSelect = renderSearchable(religionSelectID, "Select Religion");

    parentDiv.appendChild(religionSelect);

    setTimeout(() => {
        const religionSearchable = new Choices(document.getElementById(religionSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Religions", disabled: true, }]
        })

        let religionsClient = utilities.getReligionsServiceReadClient();
        religionSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await religionsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            religionSearchable.clearChoices();
            religionSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return religionSelectID;
}

/**Renders the profession searchable */
export function professionsSearchable(parentDiv: HTMLDivElement): string {
    let professionSelectID = utilities.returnRandomIDForHTMLElement();
    let professionSelect = renderSearchable(professionSelectID, "Select Profession");

    parentDiv.appendChild(professionSelect);

    setTimeout(() => {
        const professionSearchable = new Choices(document.getElementById(professionSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Professions", disabled: true, }]
        })

        let professionsClient = utilities.getProfessionsServiceReadClient();
        professionSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await professionsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            professionSearchable.clearChoices();
            professionSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return professionSelectID;
}

/**Renders the party searchable */
export function partiesSearchable(parentDiv: HTMLDivElement): string {
    let partySelectID = utilities.returnRandomIDForHTMLElement();
    let partySelect = renderSearchable(partySelectID, "Select Party");

    parentDiv.appendChild(partySelect);

    setTimeout(() => {
        const partySearchable = new Choices(document.getElementById(partySelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Parties", disabled: true, }]
        })

        let partiesClient = utilities.getPartiesServiceReadClient();
        partySearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await partiesClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            partySearchable.clearChoices();
            partySearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return partySelectID;
}


/**Renders the caste searchable */
export function castesSearchable(parentDiv: HTMLDivElement): string {
    let casteSelectID = utilities.returnRandomIDForHTMLElement();
    let casteSelect = renderSearchable(casteSelectID, "Select Caste");

    parentDiv.appendChild(casteSelect);

    setTimeout(() => {
        const casteSearchable = new Choices(document.getElementById(casteSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Castes", disabled: true, }]
        })

        let castesClient = utilities.getCastesServiceReadClient();
        casteSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await castesClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            casteSearchable.clearChoices();
            casteSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return casteSelectID;
}

/**Renders the household searchable */
export function householdsSearchable(parentDiv: HTMLDivElement): string {
    let householdSelectID = utilities.returnRandomIDForHTMLElement();
    let householdSelect = renderSearchable(householdSelectID, "Select Household");

    parentDiv.appendChild(householdSelect);

    setTimeout(() => {
        const householdSearchable = new Choices(document.getElementById(householdSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Households", disabled: true, }]
        })

        let householdsClient = utilities.getHouseholdsServiceReadClient();
        householdSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await householdsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            householdSearchable.clearChoices();
            householdSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return householdSelectID;
}

/**Renders the sentiment searchable */
export function sentimentsSearchable(parentDiv: HTMLDivElement): string {
    let sentimentSelectID = utilities.returnRandomIDForHTMLElement();
    let sentimentSelect = renderSearchable(sentimentSelectID, "Select Sentiment");

    parentDiv.appendChild(sentimentSelect);

    setTimeout(() => {
        const sentimentSearchable = new Choices(document.getElementById(sentimentSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Sentiments", disabled: true, }]
        })

        let sentimentsClient = utilities.getSentimentsServiceReadClient();
        sentimentSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await sentimentsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            sentimentSearchable.clearChoices();
            sentimentSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return sentimentSelectID;
}

/**Renders the constituency searchable */
export function constituenciesSearchable(parentDiv: HTMLDivElement): string {
    let constituencySelectID = utilities.returnRandomIDForHTMLElement();
    let constituencySelect = renderSearchable(constituencySelectID, "Select Constituency");

    parentDiv.appendChild(constituencySelect);

    if (IS_SINGLE_CONSTITUENCY) {
        constituencySelect.style.display = "none";
    }

    setTimeout(() => {
        const constituencySearchable = new Choices(document.getElementById(constituencySelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Constituencies", disabled: true, }]
        })

        let constituenciesClient = utilities.getConstituenciesServiceReadClient();
        constituencySearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await constituenciesClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            constituencySearchable.clearChoices();
            constituencySearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return constituencySelectID;
}

/**Renders the district searchable */
export function districtsSearchable(parentDiv: HTMLDivElement): string {
    let districtSelectID = utilities.returnRandomIDForHTMLElement();
    let districtSelect = renderSearchable(districtSelectID, "Select District");

    parentDiv.appendChild(districtSelect);

    if (IS_SINGLE_CONSTITUENCY) {
        districtSelect.style.display = "none";
    }

    setTimeout(() => {
        const districtSearchable = new Choices(document.getElementById(districtSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Districts", disabled: true, }]
        })

        let districtsClient = utilities.getDistrictsServiceReadClient();
        districtSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await districtsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            districtSearchable.clearChoices();
            districtSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return districtSelectID;
}

/**Renders the state searchable */
export function statesSearchable(parentDiv: HTMLDivElement): string {
    let stateSelectID = utilities.returnRandomIDForHTMLElement();
    let stateSelect = renderSearchable(stateSelectID, "Select State");

    parentDiv.appendChild(stateSelect);

    if (IS_SINGLE_CONSTITUENCY) {
        stateSelect.style.display = "none";
    }

    setTimeout(() => {
        const stateSearchable = new Choices(document.getElementById(stateSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for States", disabled: true, }]
        })

        let statesClient = utilities.getStatesServiceReadClient();
        stateSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await statesClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            stateSearchable.clearChoices();
            stateSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return stateSelectID;
}

/**Renders the event searchable */
export function eventsSearchable(parentDiv: HTMLDivElement): string {
    let eventSelectID = utilities.returnRandomIDForHTMLElement();
    let eventSelect = renderSearchable(eventSelectID, "Select Event");

    parentDiv.appendChild(eventSelect);

    setTimeout(() => {
        const eventSearchable = new Choices(document.getElementById(eventSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Events", disabled: true, }]
        })

        let eventsClient = utilities.getEventsServiceReadClient();
        eventSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await eventsClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            eventSearchable.clearChoices();
            eventSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return eventSelectID;
}

/**Renders the event type searchable */
export function eventstypesSearchable(parentDiv: HTMLDivElement): string {
    let eventtypeSelectID = utilities.returnRandomIDForHTMLElement();
    let eventtypeSelect = renderSearchable(eventtypeSelectID, "Select Event Type");

    parentDiv.appendChild(eventtypeSelect);

    setTimeout(() => {
        const eventtypeSearchable = new Choices(document.getElementById(eventtypeSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Event Types", disabled: true, }]
        })

        let eventstypesClient = utilities.getEventsTypesServiceReadClient();
        eventtypeSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await eventstypesClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            eventtypeSearchable.clearChoices();
            eventtypeSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.code}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return eventtypeSelectID;
}

/**Renders the voter searchable */
export function votersSearchable(parentDiv: HTMLDivElement): string {
    let voterSelectID = utilities.returnRandomIDForHTMLElement();
    let voterSelect = renderSearchable(voterSelectID, "Select Voter");

    parentDiv.appendChild(voterSelect);

    setTimeout(() => {
        const voterSearchable = new Choices(document.getElementById(voterSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Voters", disabled: true, }]
        })

        let votersClient = utilities.getVotersServiceReadClient();
        voterSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await votersClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            voterSearchable.clearChoices();
            voterSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `(${c.voterId}) ${c.name}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return voterSelectID;
}

/**Renders the user searchable */
export function usersSearchable(parentDiv: HTMLDivElement): string {
    let userSelectID = utilities.returnRandomIDForHTMLElement();
    let userSelect = renderSearchable(userSelectID, "Select User");

    parentDiv.appendChild(userSelect);

    setTimeout(() => {
        const userSearchable = new Choices(document.getElementById(userSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [{ value: "", label: "Search for Users", disabled: true, }]
        })

        let usersClient = utilities.getUsersServiceReadClient();
        userSearchable.passedElement.element.addEventListener("search", async (e) => {
            const searchKey = (<any>e).detail.value;
            const allChoices = await usersClient.searchAll({ isActive: true, count: protoInt64.parse(10), searchKey: searchKey });

            userSearchable.clearChoices();
            userSearchable.setChoices(allChoices.list.map(c => {
                return <Choice>{
                    label: `${c.firstName} ${c.lastName}`,
                    value: c.metadata.uuid
                }
            }));
        });
    }, 10);
    return userSelectID;
}

/**Renders the gender searchable */
export function gendersSearchable(parentDiv: HTMLDivElement): string {
    let genderSelectID = utilities.returnRandomIDForHTMLElement();
    let genderSelect = renderSearchable(genderSelectID, "Select Gender");

    parentDiv.appendChild(genderSelect);

    setTimeout(() => {
        const genderSearchable = new Choices(document.getElementById(genderSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [
                { value: VOTER_GENDER.VOTER_GENDER_ANY_UNSPECIFIED, label: "Any", },
                { value: VOTER_GENDER.VOTER_GENDER_MALE, label: "Male", },
                { value: VOTER_GENDER.VOTER_GENDER_FEMALE, label: "Female", },
                { value: VOTER_GENDER.VOTER_GENDER_TRANSGENDER, label: "Other", },
            ]
        });
    }, 10);
    return genderSelectID;
}

/**Renders the relation type searchable */
export function relationTypesSearchable(parentDiv: HTMLDivElement): string {
    let relationTypeSelectID = utilities.returnRandomIDForHTMLElement();
    let relationTypeSelect = renderSearchable(relationTypeSelectID, "Select Relation Type");

    parentDiv.appendChild(relationTypeSelect);

    setTimeout(() => {
        const relationTypeSearchable = new Choices(document.getElementById(relationTypeSelectID), {
            // addItems: false,
            duplicateItemsAllowed: false,
            removeItemButton: true,
            choices: [
                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_ANY_UNSPECIFIED, label: "Any", },

                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_FATHER, label: "Father", },
                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_MOTHER, label: "Mother", },
                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_HUSBAND, label: "Husband", },
                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_WIFE, label: "Wife", },
                { value: VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_OTHER, label: "Other", },
            ]
        });
    }, 10);
    return relationTypeSelectID;
}