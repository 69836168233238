import { Router, context } from "./router";
import * as utilities from "./utilities";
import { VOTER_GENDER, VOTER_RELATION_TYPE, VOTER_SORT_KEY, Voter, VotersServiceCountRequest, VotersServiceFilterReq } from "./structs/voters_pb";
import * as searchables from "./searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { SORT_ORDER } from "./structs/base_pb";
import { VOTER_NOTE_SORT_KEY, VoterNote, VoterNotesServiceCreateRequest, VoterNotesServiceUpdateRequest } from "./structs/voters_notes_pb";

import { Grid, html } from "gridjs";
import { PollingStation } from "./structs/polling_stations_pb";
import { Religion } from "./structs/religions_pb";
import { Profession } from "./structs/professions_pb";
import { Party } from "./structs/parties_pb";
import { Caste } from "./structs/castes_pb";
import { renderCasteStatistics } from "./statistics/byCaste";
import { renderProfessionStatistics } from "./statistics/byProfession";
import { renderReligionStatistics } from "./statistics/byReligion";
import { renderPartyStatistics } from "./statistics/byParty";
import { PromiseClient } from "@connectrpc/connect";
import { VotersService } from "./structs/voters_connect";
import { VoterLeaderSentiment } from "./structs/voters_leaders_sentiments_pb";
import { renderLeaderStatistics } from "./statistics/byLeader";
import { PoliticalLeader } from "./structs/political_leaders_pb";
import { Sentiment } from "./structs/sentiments_pb";

import { INPUTEL } from "./searchables";
import { User } from "./structs/users_pb";

const mapDivID = "map";
let allOpenInfoWindows = <google.maps.InfoWindow[]>[];
let allMarkers = <google.maps.Marker[]>[];

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/ui/voters", (ctx) => {
        if (utilities.getAuth() == "") {
            return utilities.logout();
        }
        Voters(ctx);
    });
}

export async function Voters(ctx: context) {
    let primaryBody = utilities.resetPageEssentials();
    primaryBody.innerHTML = "";

    let contentDiv = document.createElement("div");
    contentDiv.classList.add("columns");

    const mapDiv = document.createElement("div");
    mapDiv.id = mapDivID;
    mapDiv.classList.add("is-four-fifths");
    mapDiv.classList.add("column");

    contentDiv.appendChild(mapDiv);

    let title = document.createElement("h1");
    title.classList.add("title");
    title.innerText = "Voters List";
    primaryBody.appendChild(title);
    primaryBody.appendChild(contentDiv);

    let miscellaneousContentDiv = document.createElement("div");
    primaryBody.appendChild(miscellaneousContentDiv);

    utilities.setupGoogleMaps().then(async () => {
        // Coords to Machilipatnam
        let latitude = 16.170000;
        let longitude = 81.129997;

        let mapDiv = document.getElementById(mapDivID);
        while (mapDiv?.firstChild) {
            mapDiv.removeChild(mapDiv.firstChild);
        }

        let googleMap = utilities.initialiseGoogleMaps(latitude, longitude, mapDivID);
        await setupPageFilters(miscellaneousContentDiv, contentDiv, googleMap);
    });
}

async function setupPageFilters(primaryBody: HTMLElement, parentDiv: HTMLDivElement, googleMap: google.maps.Map) {
    let filtersDiv = document.createElement("div");
    filtersDiv.classList.add("column");
    filtersDiv.classList.add("filters");

    let filterTitle = document.createElement("h2");
    filterTitle.classList.add("is-size-3");
    filterTitle.innerText = "Filters";
    filterTitle.style.textAlign = "center";
    filtersDiv.appendChild(filterTitle);

    parentDiv.appendChild(filtersDiv);

    const pollingStationsSearchableID = searchables.pollingStationsSearchable(filtersDiv);

    const serialNoInput = searchables.renderInput("Polling Serial No", "number");
    filtersDiv.appendChild(serialNoInput.inputDiv);

    const sentimentsSearchableID = searchables.sentimentsSearchable(filtersDiv);
    const religionsSearchableID = searchables.religionsSearchable(filtersDiv);
    const castesSearchableID = searchables.castesSearchable(filtersDiv);
    const professionsSearchableID = searchables.professionsSearchable(filtersDiv);

    const partiesSearchableID = searchables.partiesSearchable(filtersDiv);

    const householdsSearchableID = searchables.householdsSearchable(filtersDiv);

    const constituenciesSearchableID = searchables.constituenciesSearchable(filtersDiv);
    const districtsSearchableID = searchables.districtsSearchable(filtersDiv);
    const statesSearchableID = searchables.statesSearchable(filtersDiv);

    const nameInput = searchables.renderInput("Name", "text");
    filtersDiv.appendChild(nameInput.inputDiv);

    const voterIDInput = searchables.renderInput("Voter ID", "text");
    filtersDiv.appendChild(voterIDInput.inputDiv);

    const relationTypesSearchableID = searchables.relationTypesSearchable(filtersDiv);

    const relationNameInput = searchables.renderInput("Relation Name", "text");
    filtersDiv.appendChild(relationNameInput.inputDiv);

    const houseNumberInput = searchables.renderInput("House No", "text");
    filtersDiv.appendChild(houseNumberInput.inputDiv);

    const ageMinInput = searchables.renderInput("Min Age", "number");
    filtersDiv.appendChild(ageMinInput.inputDiv);

    const ageMaxInput = searchables.renderInput("Max Age", "number");
    filtersDiv.appendChild(ageMaxInput.inputDiv);

    const gendersSearchableID = searchables.gendersSearchable(filtersDiv);

    const mobileNumberInput = searchables.renderInput("Mobile", "text");
    filtersDiv.appendChild(mobileNumberInput.inputDiv);

    // const emailInput = searchables.renderInput("Email", "text");
    // filtersDiv.appendChild(emailInput.inputDiv);

    // const birthdayInput = searchables.renderInput("Birthday (Mon DD)", "text");
    // filtersDiv.appendChild(birthdayInput.inputDiv);

    const [pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, politicalLeadersMap, sentimentsMap, usersMap] = await Promise.all([
        utilities.getPollingStationsMap(),
        utilities.getReligionsMap(),
        utilities.getProfessionsMap(),
        utilities.getPartiesMap(),
        utilities.getCastesMap(),
        utilities.getPoliticalLeadersMap(),
        utilities.getSentimentsMap(),
        utilities.getUsersMap()
    ]);

    // Submit button
    let submitButton = utilities.getSubmitButton();
    filtersDiv.appendChild(submitButton);
    submitButton.addEventListener("click", async evt => {
        while (primaryBody.firstChild) {
            primaryBody.removeChild(primaryBody.firstChild);
        }

        evt.preventDefault();

        handleSubmit({
            primaryBody,

            ageMinInput,
            ageMaxInput,
            serialNoInput,
            pollingStationsSearchableID,
            religionsSearchableID,
            professionsSearchableID,
            partiesSearchableID,
            castesSearchableID,
            householdsSearchableID,
            sentimentsSearchableID,
            constituenciesSearchableID,
            districtsSearchableID,
            statesSearchableID,
            nameInput,
            voterIDInput,

            relationNameInput,
            houseNumberInput,
            mobileNumberInput,

            relationTypesSearchableID,
            gendersSearchableID,

            googleMap,

            pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, politicalLeadersMap, sentimentsMap, usersMap,
            renderTable: true,
        });
    });

    let setupDownloadButton = utilities.getDownloadButton();
    setupDownloadButton.classList.add("is-fullwidth");
    setupDownloadButton.innerText = "Setup Download";
    filtersDiv.appendChild(setupDownloadButton);
    setupDownloadButton.addEventListener("click", async evt => {
        while (primaryBody.firstChild) {
            primaryBody.removeChild(primaryBody.firstChild);
        }

        evt.preventDefault();

        handleSubmit({
            primaryBody,

            ageMinInput,
            ageMaxInput,
            serialNoInput,
            pollingStationsSearchableID,
            religionsSearchableID,
            professionsSearchableID,
            partiesSearchableID,
            castesSearchableID,
            householdsSearchableID,
            sentimentsSearchableID,
            constituenciesSearchableID,
            districtsSearchableID,
            statesSearchableID,
            nameInput,
            voterIDInput,

            relationNameInput,
            houseNumberInput,
            mobileNumberInput,

            relationTypesSearchableID,
            gendersSearchableID,

            googleMap,

            pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, politicalLeadersMap, sentimentsMap, usersMap,
            renderTable: false,
        });
    });
}

async function handleSubmit({
    primaryBody,

    ageMinInput,
    ageMaxInput,
    serialNoInput,
    pollingStationsSearchableID,
    religionsSearchableID,
    professionsSearchableID,
    partiesSearchableID,
    castesSearchableID,
    householdsSearchableID,
    sentimentsSearchableID,
    constituenciesSearchableID,
    districtsSearchableID,
    statesSearchableID,
    nameInput,
    voterIDInput,

    relationNameInput,
    houseNumberInput,
    mobileNumberInput,

    relationTypesSearchableID,
    gendersSearchableID,

    googleMap,

    pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, politicalLeadersMap, sentimentsMap, usersMap,

    renderTable,

}: {
    primaryBody: HTMLElement,

    ageMinInput: INPUTEL,
    ageMaxInput: INPUTEL,
    serialNoInput: INPUTEL,

    pollingStationsSearchableID: string,
    religionsSearchableID: string,
    professionsSearchableID: string,
    partiesSearchableID: string,
    castesSearchableID: string,
    householdsSearchableID: string,
    sentimentsSearchableID: string,
    constituenciesSearchableID: string,
    districtsSearchableID: string,
    statesSearchableID: string,

    nameInput: INPUTEL,
    voterIDInput: INPUTEL,

    relationNameInput: INPUTEL,
    houseNumberInput: INPUTEL,

    mobileNumberInput: INPUTEL,

    relationTypesSearchableID: string,
    gendersSearchableID: string,

    googleMap: google.maps.Map,

    pollingStationsMap: Map<string, PollingStation>,
    religionsMap: Map<string, Religion>,
    professionsMap: Map<string, Profession>,
    partiesMap: Map<string, Party>,
    castesMap: Map<string, Caste>,
    politicalLeadersMap: Map<string, PoliticalLeader>,
    sentimentsMap: Map<string, Sentiment>,
    usersMap: Map<string, User>,

    renderTable: boolean,
}) {
    let ageMin = protoInt64.zero;
    let ageMax = protoInt64.zero;
    let serialNo = protoInt64.zero;
    try {
        ageMin = protoInt64.parse((<HTMLInputElement>document.getElementById(ageMinInput.inputElID)).value);
    } catch (e) { }
    try {
        ageMax = protoInt64.parse((<HTMLInputElement>document.getElementById(ageMaxInput.inputElID)).value);
    } catch (e) { }
    try {
        serialNo = protoInt64.parse((<HTMLInputElement>document.getElementById(serialNoInput.inputElID)).value);
    } catch (e) { }

    let filterReq = new VotersServiceFilterReq({
        isActive: true,
        count: protoInt64.parse(-1),
        offset: protoInt64.zero,
        sortOrder: SORT_ORDER.ASCENDING_UNSPECIFIED,
        sortKey: VOTER_SORT_KEY.VOTER_SORT_KEY_ID_UNSPECIFIED,
        creationTimestampStart: protoInt64.zero,
        creationTimestampEnd: protoInt64.zero,
        pollingStationUuid: (<HTMLSelectElement>document.getElementById(pollingStationsSearchableID)).value,
        religionUuid: (<HTMLSelectElement>document.getElementById(religionsSearchableID)).value,
        professionUuid: (<HTMLSelectElement>document.getElementById(professionsSearchableID)).value,
        partyAffiliationUuid: (<HTMLSelectElement>document.getElementById(partiesSearchableID)).value,
        casteUuid: (<HTMLSelectElement>document.getElementById(castesSearchableID)).value,
        householdUuid: (<HTMLSelectElement>document.getElementById(householdsSearchableID)).value,
        sentimentUuid: (<HTMLSelectElement>document.getElementById(sentimentsSearchableID)).value,
        constituencyUuid: (<HTMLSelectElement>document.getElementById(constituenciesSearchableID)).value,
        districtUuid: (<HTMLSelectElement>document.getElementById(districtsSearchableID)).value,
        stateUuid: (<HTMLSelectElement>document.getElementById(statesSearchableID)).value,
        name: (<HTMLInputElement>document.getElementById(nameInput.inputElID)).value,
        voterId: (<HTMLInputElement>document.getElementById(voterIDInput.inputElID)).value,

        serialNo: serialNo,
        relationType: <any>(<HTMLSelectElement>document.getElementById(relationTypesSearchableID)).value,
        relationName: (<HTMLInputElement>document.getElementById(relationNameInput.inputElID)).value,
        houseNumber: (<HTMLInputElement>document.getElementById(houseNumberInput.inputElID)).value,
        ageMin: ageMin,
        ageMax: ageMax,
        gender: <any>(<HTMLSelectElement>document.getElementById(gendersSearchableID)).value,
        mobileNumber: (<HTMLInputElement>document.getElementById(mobileNumberInput.inputElID)).value,
        // email: (<HTMLInputElement>document.getElementById(emailInput.inputElID)).value,
        // dateOfBirth: (<HTMLInputElement>document.getElementById(birthdayInput.inputElID)).value, 
    });

    let countReq = new VotersServiceCountRequest({
        isActive: true,
        creationTimestampStart: protoInt64.zero,
        creationTimestampEnd: protoInt64.zero,
        pollingStationUuid: (<HTMLSelectElement>document.getElementById(pollingStationsSearchableID)).value,
        religionUuid: (<HTMLSelectElement>document.getElementById(religionsSearchableID)).value,
        professionUuid: (<HTMLSelectElement>document.getElementById(professionsSearchableID)).value,
        partyAffiliationUuid: (<HTMLSelectElement>document.getElementById(partiesSearchableID)).value,
        casteUuid: (<HTMLSelectElement>document.getElementById(castesSearchableID)).value,
        householdUuid: (<HTMLSelectElement>document.getElementById(householdsSearchableID)).value,
        sentimentUuid: (<HTMLSelectElement>document.getElementById(sentimentsSearchableID)).value,
        constituencyUuid: (<HTMLSelectElement>document.getElementById(constituenciesSearchableID)).value,
        districtUuid: (<HTMLSelectElement>document.getElementById(districtsSearchableID)).value,
        stateUuid: (<HTMLSelectElement>document.getElementById(statesSearchableID)).value,
        serialNo: serialNo,
        relationType: <any>(<HTMLSelectElement>document.getElementById(relationTypesSearchableID)).value,
        relationName: (<HTMLInputElement>document.getElementById(relationNameInput.inputElID)).value,
        ageMin: ageMin,
        ageMax: ageMax,
        // gender: <any>(<HTMLSelectElement>document.getElementById(gendersSearchableID)).value,
        // dateOfBirth: (<HTMLInputElement>document.getElementById(birthdayInput.inputElID)).value, 
    });

    await displayVoters(primaryBody, googleMap, filterReq, countReq, pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, usersMap, renderTable);
    if (renderTable) {
        renderStatistics(primaryBody, countReq, pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, politicalLeadersMap, sentimentsMap)
    }
}

async function displayVoters(primaryBody: HTMLElement, googleMap: google.maps.Map, filterReq: VotersServiceFilterReq, countReq: VotersServiceCountRequest,
    pollingStationsMap: Map<string, PollingStation>, religionsMap: Map<string, Religion>, professionsMap: Map<string, Profession>,
    partiesMap: Map<string, Party>, castesMap: Map<string, Caste>,
    usersMap: Map<string, User>,

    renderTable: boolean,
) {
    allMarkers.forEach(m => {
        m.setMap(null);
    });

    const votersClient = utilities.getVotersServiceReadClient();
    const [allVotersList] = await Promise.all([
        votersClient.filter(filterReq),
    ]);
    const allVoters = allVotersList.list;
    for (let i = 0; i < allVoters.length; i++) {
        let voter = allVoters[i];
        createVoterMarker(googleMap, {
            voter,
            markerColor: "red"
        });
    }
    if (allVoters.length > 0) {
        renderVotersInTable(primaryBody, votersClient, allVoters, pollingStationsMap, religionsMap, professionsMap, partiesMap, castesMap, usersMap, renderTable);
    }

    // Render a notification about the number of records retrieved
    utilities.renderNotification(`${allVoters.length} record(s) found`);
}

export function renderVotersInTable(
    primaryBody: HTMLElement, votersClient: PromiseClient<typeof VotersService>, voters: Voter[],
    pollingStationsMap: Map<string, PollingStation>, religionsMap: Map<string, Religion>, professionsMap: Map<string, Profession>,
    partiesMap: Map<string, Party>, castesMap: Map<string, Caste>,
    usersMap: Map<string, User>,

    renderTable: boolean
) {
    let container = document.createElement("div");
    container.classList.add("voters-section");
    let title = document.createElement("h3");
    title.classList.add("title");
    title.innerText = `Voters Table (${voters.length == 1 ? '1 Voter' : voters.length + " Voters"})`;
    container.appendChild(title);

    let downloadVotersBtn = utilities.getDownloadButton();
    container.appendChild(downloadVotersBtn);

    let tableBody = document.createElement("div");
    container.appendChild(tableBody);

    primaryBody.appendChild(container);

    const columns = [
        "S.No.", "Name", "Voter ID", "PS Serial No", "Age", "Gender", "View",
        "Relation Type", "Relation Name", "ECI House No", "Physical Door No",
        "Mobile", "Email", "Birthday",
        "ECI Polling Station", "Physical Polling Station", "Sentiment", "Religion", "Profession", "Party", "Caste", "Lat", "Lng", 
        "Added By", "Modified At",
        "Directions",
    ];

    const rows = voters.map((voter, i) => {
        const user = usersMap.get(voter.metadata.addedByUserUuid);

        if (voter.sentiment == null || voter.sentiment == undefined) {
            voter.sentiment = new Sentiment();
        }

        return [
            i + 1, voter.name, voter.voterId, voter.serialNo, voter.age, decodeVoterGender(voter.gender), html(`<a class="voter-button button is-link" data-uuid='${voter.metadata.uuid}'>View</a>`),
            decodeVoterRelationType(voter.relationType), voter.relationName, voter.houseNumber, `#${voter.household.name}`,
            voter.mobileNumber, voter.email, voter.dateOfBirth,
            `${pollingStationsMap.get(voter.pollingStationUuid).name}`, `${voter.household.address}`,
            `${voter.sentiment.name}`,
            `(${religionsMap.get(voter.religionUuid).code}) ${religionsMap.get(voter.religionUuid).name}`,
            `(${professionsMap.get(voter.professionUuid).code}) ${professionsMap.get(voter.professionUuid).name}`,
            `(${partiesMap.get(voter.partyAffiliationUuid).code}) ${partiesMap.get(voter.partyAffiliationUuid).name}`,
            `(${castesMap.get(voter.casteUuid).code}) ${castesMap.get(voter.casteUuid).name}`,

            voter.household.latitude != 270 ? voter.household.latitude : "-",
            voter.household.longitude != 270 ? voter.household.longitude : "-",
            `${user.firstName} ${user.lastName} (${user.username})`, utilities.convertBigIntTimestampToDate(voter.metadata.modifiedAt),
            html(`<a href="${utilities.renderGoogleMapsDrivingDirectionsLink(voter.household.latitude.toString(), voter.household.longitude.toString())}" target="_blank" class="button is-success">Directions</a>`),
        ]
    });

    downloadVotersBtn.addEventListener("click", async evt => {
        evt.preventDefault();

        let localColumns = [...columns];

        const VIEW_COLUMN_INDEX = 6;

        // Remove the VIEW column
        localColumns.splice(VIEW_COLUMN_INDEX, 1);

        const directionsRow = localColumns.length - 1;

        let transformedRows = <string[][]>[];
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let localArr = <string[]>[];
            for (let j = 0; j < row.length; j++) {
                localArr.push(row[j].toString());
            }

            localArr.splice(VIEW_COLUMN_INDEX, 1);

            localArr.splice(directionsRow, 1)
            localArr.push(utilities.renderGoogleMapsDrivingDirectionsLink(localArr[directionsRow - 2], localArr[directionsRow - 1]));

            transformedRows.push(localArr);
        }

        utilities.downloadAsCSV(localColumns, transformedRows, `Voters`);
    });

    if (renderTable) {
        const grid = new Grid({
            columns: columns,
            sort: true,
            fixedHeader: true,
            resizable: true,
            search: true,
            height: '50vh',
            data: rows
        });
        grid.render(tableBody);
        grid.on("cellClick", async evt => {
            const target = <HTMLElement>evt.target;
            if (target.nodeName.toLowerCase() == "a" && target.classList.contains("voter-button")) {
                let button = <HTMLAnchorElement>evt.target;
                const voter = await votersClient.viewByUUID({ uuid: button.getAttribute("data-uuid") });
                closeAndOpenVoterInfoSidebar(voter);
            }
        });
    }
}

export async function renderStatistics(
    primaryBody: HTMLElement, countReq: VotersServiceCountRequest,
    pollingStationsMap: Map<string, PollingStation>, religionsMap: Map<string, Religion>, professionsMap: Map<string, Profession>,
    partiesMap: Map<string, Party>, castesMap: Map<string, Caste>,
    politicalLeadersMap: Map<string, PoliticalLeader>, sentimentsMap: Map<string, Sentiment>
) {

    renderPartyStatistics(primaryBody, countReq, partiesMap);
    renderCasteStatistics(primaryBody, countReq, castesMap);
    renderProfessionStatistics(primaryBody, countReq, professionsMap);
    renderReligionStatistics(primaryBody, countReq, religionsMap);
    renderLeaderStatistics(primaryBody, countReq, politicalLeadersMap, sentimentsMap);
}

export function createVoterMarker(googleMap: google.maps.Map, params: {
    voter: Voter,
    markerColor: "green" | "red" | "blue"
}) {
    if (params.voter.household.latitude == 270 || params.voter.household.longitude == 270) {
        return
    }

    const marker = new google.maps.Marker({
        position: { lat: params.voter.household.latitude, lng: params.voter.household.longitude },
        map: googleMap,
        // title: params.title,
        // label: params.title

    });
    allMarkers.push(marker);
    marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${params.markerColor}-dot.png`)

    marker.addListener("click", () => {
        for (let i = 0; i < allOpenInfoWindows.length; i++) {
            try {
                allOpenInfoWindows[i].close();
            } catch (e) { }
        }
        const sidebar = closeAndOpenVoterInfoSidebar(params.voter);

        // map.setZoom(15);
        // googleMap.setCenter(marker.getPosition() as google.maps.LatLng);

        let infowindow = new google.maps.InfoWindow({
            content: `<div class='marker-content'>${params.voter.name}</div>`,
        });

        infowindow.open({
            anchor: marker,
            map: googleMap,
            shouldFocus: false,
        });
        allOpenInfoWindows.push(infowindow);

        infowindow.addListener('closeclick', () => {
            // Handle focus manually.
            infowindow.close();
            // map.setZoom(12);
            utilities.closeSpecificSidebar(sidebar);
        });
    });
}

function closeAndOpenVoterInfoSidebar(voter: Voter) {
    utilities.closeAllOpenSidebars();
    const { sidebar, contentID } = utilities.displaySidebar("Voter Information");
    displayVoterContentInSidebar(sidebar, contentID, voter);
    return sidebar
}

/**Displays the voter information within the sidebar */
async function displayVoterContentInSidebar(sidebar: HTMLDivElement, contentID: string, voter: Voter) {
    let primaryDiv = document.createElement("div");
    primaryDiv.classList.add("column");
    let [
        pollingstation, religion, profession, party, caste, household, notes, sentiments
    ] = await Promise.all([
        utilities.getPollingStationsServiceReadClient().viewByUUID({ uuid: voter.pollingStationUuid }),
        utilities.getReligionsServiceReadClient().viewByUUID({ uuid: voter.religionUuid }),
        utilities.getProfessionsServiceReadClient().viewByUUID({ uuid: voter.professionUuid }),
        utilities.getPartiesServiceReadClient().viewByUUID({ uuid: voter.partyAffiliationUuid }),
        utilities.getCastesServiceReadClient().viewByUUID({ uuid: voter.casteUuid }),
        utilities.getHouseholdsServiceReadClient().viewByUUID({ uuid: voter.householdUuid }),
        utilities.getVotersNotesServiceReadClient().filter({ isActive: true, count: protoInt64.parse(-1), sortOrder: SORT_ORDER.DESCENDING, sortKey: VOTER_NOTE_SORT_KEY.VOTER_NOTE_SORT_KEY_MODIFIED_AT, voterUuid: voter.metadata.uuid }),
        utilities.getVotersLeadersSentimentsServiceReadClient().filter({ isActive: true, count: protoInt64.parse(-1), voterUuid: voter.metadata.uuid })
    ]);

    let addNoteButtonID = utilities.returnRandomIDForHTMLElement();

    primaryDiv.innerHTML = `
    <div style='padding: 5px;' class='content'>
        <blockquote>
            <h4 class="is-size-6">Name: ${voter.name}</h4>
            <h4 class="is-size-6">Age: ${voter.age}</h4>
            <h4 class="is-size-6">Gender: ${decodeVoterGender(voter.gender)}</h4>
            <h4 class="is-size-6">Mobile: ${utilities.renderWhatsAppLink(voter.mobileNumber)}</h4>
            <h4 class="is-size-6">Email: ${voter.email}</h4>
            <h4 class="is-size-6">Birthday: ${voter.dateOfBirth}</h4>

            <h4 class="is-size-6">Relation Type: ${decodeVoterRelationType(voter.relationType)}</h4>
            <h4 class="is-size-6">Relation Name: ${voter.relationName}</h4>
        </blockquote>
    </div>
    <div style='padding: 5px; margin-top: 20px;' class='content'>
        <blockquote>
            <h4 class="is-size-6">House No: ${voter.houseNumber}</h4>
            <h4 class="is-size-6">Voter ID: ${voter.voterId}</h4>
            <h4 class="is-size-6">S. No.: ${voter.serialNo}</h4>
        </blockquote>
    </div>
    <div style='padding: 5px; margin-top: 20px;' class='content'>
        <blockquote>
            <h4 class="is-size-6">Polling Station: (${pollingstation.code}) ${pollingstation.name}</h4>
            <h4 class="is-size-6">Religion: (${religion.code}) ${religion.name} (${religion.subReligion})</h4>
            <h4 class="is-size-6">Profession: (${profession.code}) ${profession.name} (${profession.subProfession})</h4>
            <h4 class="is-size-6">Party: (${party.code}) ${party.name}</h4>
            <h4 class="is-size-6">Caste: (${caste.code}) ${caste.name} (${caste.subCaste})</h4>
            <h4 class="is-size-6">Household: (${household.code}) ${household.name}</h4>
        </blockquote>
    </div>
    <div style='padding: 5px; margin-top: 20px;' class='content'>
        <h4 class="is-size-4" style="text-align: center;"><span>Notes</span><button class="button is-primary" id="${addNoteButtonID}" style="float: right">Add</button></h4>
        ${await Promise.all(notes.list.map(async (note, index) => {
        return (await renderVoterNote(voter, note, index + 1)).outerHTML;
    }))}
    </div>
    <div style='padding: 5px; margin-top: 20px;' class='content'>
        <h4 class="is-size-4" style="text-align: center;"><span>Sentiments</span></h4>
        ${await Promise.all(sentiments.list.map(async (sentiment, index) => {
        return (await renderVoterLeaderSentiment(voter, sentiment, index + 1)).outerHTML;
    }))}
    </div>
    `;
    document.getElementById(contentID).innerHTML = primaryDiv.outerHTML;

    document.getElementById(addNoteButtonID).addEventListener("click", async evt => {
        evt.preventDefault();
        utilities.renderVoterNoteModal(`Add Note to ${voter.name}`, "", "", async (title, content) => {
            const newVoterNote = new VoterNotesServiceCreateRequest({
                voterUuid: voter.metadata.uuid,
                title: title,
                content: content
            });

            utilities.getVotersNotesServiceWriteClient().create(newVoterNote).then(() => {
                closeAndOpenVoterInfoSidebar(voter);
            });
        });
    });

    let editNoteButtons = sidebar.getElementsByClassName("edit-voter-note");
    for (let i = 0; i < editNoteButtons.length; i++) {
        let editButton = editNoteButtons[i];
        editButton.addEventListener("click", async evt => {
            evt.preventDefault();
            let note = await utilities.getVotersNotesServiceReadClient().viewByUUID({ uuid: editButton.getAttribute("data-uuid") });
            utilities.renderVoterNoteModal(`Modify Note for ${voter.name}`, note.title, note.content, async (title, content) => {
                const updatedVoterNote = new VoterNotesServiceUpdateRequest({
                    uuid: note.metadata.uuid,
                    title: title,
                    content: content
                });

                utilities.getVotersNotesServiceWriteClient().update(updatedVoterNote).then(() => {
                    closeAndOpenVoterInfoSidebar(voter);
                });
            });
        });
    }
}

export function decodeVoterGender(gender: VOTER_GENDER): string {
    if (gender == VOTER_GENDER.VOTER_GENDER_MALE) {
        return "Male"
    } else if (gender == VOTER_GENDER.VOTER_GENDER_FEMALE) {
        return "Female"
    } else if (gender == VOTER_GENDER.VOTER_GENDER_TRANSGENDER) {
        return "Other"
    }
    return "-"
}

export function decodeVoterRelationType(relationType: VOTER_RELATION_TYPE): string {
    if (relationType == VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_FATHER) {
        return "Father"
    } else if (relationType == VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_MOTHER) {
        return "Mother"
    } else if (relationType == VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_HUSBAND) {
        return "Husband"
    } else if (relationType == VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_WIFE) {
        return "Wife"
    } else if (relationType == VOTER_RELATION_TYPE.VOTER_RELATION_TYPE_OTHER) {
        return "Other"
    }
    return "-"
}

async function renderVoterNote(voter: Voter, note: VoterNote, index: number): Promise<HTMLDivElement> {
    let div = document.createElement("div");
    div.classList.add("content");
    let title = document.createElement("h5");
    title.innerText = `${index}. ` + note.title;
    let content = document.createElement("h5");
    content.innerText = note.content;

    let blockquote = document.createElement("blockquote");

    blockquote.appendChild(title);
    blockquote.appendChild(content);


    // Display created at, modified at, and added by
    let metadata = document.createElement("h6");
    metadata.innerText = `Created At: ` + utilities.convertBigIntTimestampToDateTime(note.metadata.createdAt);
    metadata.innerText += `, Last Modified At: ` + utilities.convertBigIntTimestampToDateTime(note.metadata.modifiedAt);

    let addedByUser = await utilities.getUsersServiceReadClient().viewByUUID({ uuid: note.metadata.addedByUserUuid });
    metadata.innerText += `, By: ${addedByUser.firstName} ${addedByUser.lastName}, Ph: ${addedByUser.phone}`

    blockquote.appendChild(metadata);

    let editButton = document.createElement("button");
    editButton.className = "button is-warning edit-voter-note";
    editButton.setAttribute("data-uuid", note.metadata.uuid);
    editButton.innerText = "Edit";
    blockquote.appendChild(editButton);

    div.appendChild(blockquote)
    return div;
}

async function renderVoterLeaderSentiment(voter: Voter, leaderSentiment: VoterLeaderSentiment, index: number): Promise<HTMLDivElement> {
    const [
        sentiment, leader
    ] = await Promise.all([
        utilities.getSentimentsServiceReadClient().viewByUUID({ uuid: leaderSentiment.sentimentUuid }),
        utilities.getPoliticalLeadersServiceReadClient().viewByUUID({ uuid: leaderSentiment.leaderUuid })
    ]);

    let div = document.createElement("div");
    div.classList.add("content");
    let title = document.createElement("h5");
    title.innerText = `${index}. ${leader.name} (${leader.code})`;
    let sentimentText = document.createElement("p");
    sentimentText.innerText = `Sentiment: (${sentiment.code}) ${sentiment.name}`;

    let blockquote = document.createElement("blockquote");

    blockquote.appendChild(title);
    blockquote.appendChild(sentimentText);
    if (leaderSentiment.description.length > 0) {
        let descriptionText = document.createElement("p");
        descriptionText.innerText = leaderSentiment.description;
        blockquote.appendChild(descriptionText);
    }

    // Display created at, modified at, and added by
    // let metadata = document.createElement("h6"); 
    // metadata.innerText = `Created At: ` + utilities.convertBigIntTimestampToDateTime(note.metadata.createdAt);
    // metadata.innerText += `, Last Modified At: `+ utilities.convertBigIntTimestampToDateTime(note.metadata.modifiedAt);

    // let addedByUser = await utilities.getUsersServiceReadClient().viewByUUID({ uuid: note.metadata.addedByUserUuid });
    // metadata.innerText += `, By: ${addedByUser.firstName} ${addedByUser.lastName}, Ph: ${addedByUser.phone}`

    // blockquote.appendChild(metadata);

    // let editButton = document.createElement("button");
    // editButton.className = "button is-warning edit-voter-note";
    // editButton.setAttribute("data-uuid", note.metadata.uuid);
    // editButton.innerText = "Edit";
    // blockquote.appendChild(editButton);

    div.appendChild(blockquote)
    return div;
}