// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file sentiments.proto (package nail2poll, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Metadata, SORT_ORDER } from "./base_pb.js";

/**
 *
 * Describes the available sort keys for retrieving sentiments
 *
 * @generated from enum nail2poll.SENTIMENT_SORT_KEY
 */
export enum SENTIMENT_SORT_KEY {
  /**
   * Fetch ordered results by id
   *
   * @generated from enum value: SENTIMENT_SORT_KEY_ID_UNSPECIFIED = 0;
   */
  SENTIMENT_SORT_KEY_ID_UNSPECIFIED = 0,

  /**
   * Fetch ordered results by the creation timestamp
   *
   * @generated from enum value: SENTIMENT_SORT_KEY_CREATED_AT = 1;
   */
  SENTIMENT_SORT_KEY_CREATED_AT = 1,

  /**
   * Fetch ordered results by the modified timestamp
   *
   * @generated from enum value: SENTIMENT_SORT_KEY_MODIFIED_AT = 2;
   */
  SENTIMENT_SORT_KEY_MODIFIED_AT = 2,

  /**
   * Fetch ordered results by name
   *
   * @generated from enum value: SENTIMENT_SORT_KEY_NAME = 10;
   */
  SENTIMENT_SORT_KEY_NAME = 10,

  /**
   * Fetch ordered results by code
   *
   * @generated from enum value: SENTIMENT_SORT_KEY_CODE = 11;
   */
  SENTIMENT_SORT_KEY_CODE = 11,
}
// Retrieve enum metadata with: proto3.getEnumType(SENTIMENT_SORT_KEY)
proto3.util.setEnumType(SENTIMENT_SORT_KEY, "nail2poll.SENTIMENT_SORT_KEY", [
  { no: 0, name: "SENTIMENT_SORT_KEY_ID_UNSPECIFIED" },
  { no: 1, name: "SENTIMENT_SORT_KEY_CREATED_AT" },
  { no: 2, name: "SENTIMENT_SORT_KEY_MODIFIED_AT" },
  { no: 10, name: "SENTIMENT_SORT_KEY_NAME" },
  { no: 11, name: "SENTIMENT_SORT_KEY_CODE" },
]);

/**
 *
 * Describes the data structure of each sentiment on the platform
 *
 * @generated from message nail2poll.Sentiment
 */
export class Sentiment extends Message<Sentiment> {
  /**
   * Stores the metadata of this resource
   *
   * @generated from field: nail2poll.Metadata metadata = 1;
   */
  metadata?: Metadata;

  /**
   * The name of the sentiment
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the sentiment
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<Sentiment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.Sentiment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: Metadata },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Sentiment {
    return new Sentiment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Sentiment {
    return new Sentiment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Sentiment {
    return new Sentiment().fromJsonString(jsonString, options);
  }

  static equals(a: Sentiment | PlainMessage<Sentiment> | undefined, b: Sentiment | PlainMessage<Sentiment> | undefined): boolean {
    return proto3.util.equals(Sentiment, a, b);
  }
}

/**
 *
 * Describes the data structure that stores a list of sentiments
 *
 * @generated from message nail2poll.SentimentsList
 */
export class SentimentsList extends Message<SentimentsList> {
  /**
   * List of sentiments
   *
   * @generated from field: repeated nail2poll.Sentiment list = 1;
   */
  list: Sentiment[] = [];

  constructor(data?: PartialMessage<SentimentsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list", kind: "message", T: Sentiment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsList {
    return new SentimentsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsList {
    return new SentimentsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsList {
    return new SentimentsList().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsList | PlainMessage<SentimentsList> | undefined, b: SentimentsList | PlainMessage<SentimentsList> | undefined): boolean {
    return proto3.util.equals(SentimentsList, a, b);
  }
}

/**
 *
 * Describes the data structure that responds to a pagination request
 *
 * @generated from message nail2poll.SentimentPaginationResp
 */
export class SentimentPaginationResp extends Message<SentimentPaginationResp> {
  /**
   * The number of records in this payload
   *
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * The total number of records that are available
   *
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  /**
   * The list of records
   *
   * @generated from field: repeated nail2poll.Sentiment payload = 3;
   */
  payload: Sentiment[] = [];

  constructor(data?: PartialMessage<SentimentPaginationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentPaginationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "payload", kind: "message", T: Sentiment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentPaginationResp {
    return new SentimentPaginationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentPaginationResp {
    return new SentimentPaginationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentPaginationResp {
    return new SentimentPaginationResp().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentPaginationResp | PlainMessage<SentimentPaginationResp> | undefined, b: SentimentPaginationResp | PlainMessage<SentimentPaginationResp> | undefined): boolean {
    return proto3.util.equals(SentimentPaginationResp, a, b);
  }
}

/**
 *
 * Describes the necessary data structure during creation of a sentiment
 *
 * @generated from message nail2poll.SentimentsServiceCreateRequest
 */
export class SentimentsServiceCreateRequest extends Message<SentimentsServiceCreateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The name of the sentiment
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the sentiment
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<SentimentsServiceCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsServiceCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsServiceCreateRequest {
    return new SentimentsServiceCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsServiceCreateRequest {
    return new SentimentsServiceCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsServiceCreateRequest {
    return new SentimentsServiceCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsServiceCreateRequest | PlainMessage<SentimentsServiceCreateRequest> | undefined, b: SentimentsServiceCreateRequest | PlainMessage<SentimentsServiceCreateRequest> | undefined): boolean {
    return proto3.util.equals(SentimentsServiceCreateRequest, a, b);
  }
}

/**
 *
 * Describes the data structure to perform the update (draft/revision) operation on a sentiment
 *
 * @generated from message nail2poll.SentimentsServiceUpdateRequest
 */
export class SentimentsServiceUpdateRequest extends Message<SentimentsServiceUpdateRequest> {
  /**
   * Stores any comment that the user might add during this operation
   *
   * @generated from field: string user_comment = 1;
   */
  userComment = "";

  /**
   * The UUID of the resource that needs to be updated
   *
   * @generated from field: string uuid = 2;
   */
  uuid = "";

  /**
   * The name of the sentiment
   *
   * @generated from field: string name = 10;
   */
  name = "";

  /**
   * Code of the sentiment
   *
   * @generated from field: string code = 11;
   */
  code = "";

  constructor(data?: PartialMessage<SentimentsServiceUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsServiceUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsServiceUpdateRequest {
    return new SentimentsServiceUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsServiceUpdateRequest {
    return new SentimentsServiceUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsServiceUpdateRequest {
    return new SentimentsServiceUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsServiceUpdateRequest | PlainMessage<SentimentsServiceUpdateRequest> | undefined, b: SentimentsServiceUpdateRequest | PlainMessage<SentimentsServiceUpdateRequest> | undefined): boolean {
    return proto3.util.equals(SentimentsServiceUpdateRequest, a, b);
  }
}

/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message nail2poll.SentimentsServicePaginationReq
 */
export class SentimentsServicePaginationReq extends Message<SentimentsServicePaginationReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = SENTIMENT_SORT_KEY.SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  constructor(data?: PartialMessage<SentimentsServicePaginationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsServicePaginationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(SENTIMENT_SORT_KEY) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsServicePaginationReq {
    return new SentimentsServicePaginationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsServicePaginationReq {
    return new SentimentsServicePaginationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsServicePaginationReq {
    return new SentimentsServicePaginationReq().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsServicePaginationReq | PlainMessage<SentimentsServicePaginationReq> | undefined, b: SentimentsServicePaginationReq | PlainMessage<SentimentsServicePaginationReq> | undefined): boolean {
    return proto3.util.equals(SentimentsServicePaginationReq, a, b);
  }
}

/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message nail2poll.SentimentsServiceFilterReq
 */
export class SentimentsServiceFilterReq extends Message<SentimentsServiceFilterReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = SENTIMENT_SORT_KEY.SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * The minimum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_start = 6;
   */
  creationTimestampStart = protoInt64.zero;

  /**
   * The maximum timestamp that needs to be considered to filter by creation
   *
   * @generated from field: int64 creation_timestamp_end = 7;
   */
  creationTimestampEnd = protoInt64.zero;

  /**
   * The name of the sentiment
   *
   * @generated from field: string name = 20;
   */
  name = "";

  /**
   * Code of the sentiment
   *
   * @generated from field: string code = 21;
   */
  code = "";

  constructor(data?: PartialMessage<SentimentsServiceFilterReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsServiceFilterReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(SENTIMENT_SORT_KEY) },
    { no: 6, name: "creation_timestamp_start", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "creation_timestamp_end", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsServiceFilterReq {
    return new SentimentsServiceFilterReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsServiceFilterReq {
    return new SentimentsServiceFilterReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsServiceFilterReq {
    return new SentimentsServiceFilterReq().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsServiceFilterReq | PlainMessage<SentimentsServiceFilterReq> | undefined, b: SentimentsServiceFilterReq | PlainMessage<SentimentsServiceFilterReq> | undefined): boolean {
    return proto3.util.equals(SentimentsServiceFilterReq, a, b);
  }
}

/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message nail2poll.SentimentsServiceSearchAllReq
 */
export class SentimentsServiceSearchAllReq extends Message<SentimentsServiceSearchAllReq> {
  /**
   * If true, then returns only active records. If false, then returns only inactive records
   *
   * @generated from field: bool is_active = 1;
   */
  isActive = false;

  /**
   * The number of records that need to be sent in the response. Returns all records if it is set to -1
   *
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  /**
   * The number that need to be offset by before fetching the records
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * The sort order that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SORT_ORDER sort_order = 4;
   */
  sortOrder = SORT_ORDER.ASCENDING_UNSPECIFIED;

  /**
   * The sort key that is to be used to fetch the pagination response
   *
   * @generated from field: nail2poll.SENTIMENT_SORT_KEY sort_key = 5;
   */
  sortKey = SENTIMENT_SORT_KEY.SENTIMENT_SORT_KEY_ID_UNSPECIFIED;

  /**
   * Describes the key with which the search operation needs to be performed
   *
   * @generated from field: string search_key = 20;
   */
  searchKey = "";

  constructor(data?: PartialMessage<SentimentsServiceSearchAllReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nail2poll.SentimentsServiceSearchAllReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sort_order", kind: "enum", T: proto3.getEnumType(SORT_ORDER) },
    { no: 5, name: "sort_key", kind: "enum", T: proto3.getEnumType(SENTIMENT_SORT_KEY) },
    { no: 20, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SentimentsServiceSearchAllReq {
    return new SentimentsServiceSearchAllReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SentimentsServiceSearchAllReq {
    return new SentimentsServiceSearchAllReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SentimentsServiceSearchAllReq {
    return new SentimentsServiceSearchAllReq().fromJsonString(jsonString, options);
  }

  static equals(a: SentimentsServiceSearchAllReq | PlainMessage<SentimentsServiceSearchAllReq> | undefined, b: SentimentsServiceSearchAllReq | PlainMessage<SentimentsServiceSearchAllReq> | undefined): boolean {
    return proto3.util.equals(SentimentsServiceSearchAllReq, a, b);
  }
}

